import axios from 'axios';
import { configs } from '../../assets/Config';

// verify otp on customer sign up
export function verifySignupOtp(otp, token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "otp": otp,
                "token": token
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/registration/verifyRegistrationOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// resend otp on customer sign up
export function resendSignUpOtp(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "token": token
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/registration/resendRegistrationOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// verify otp on customer forgot password
export function verifyForgotPasswordOtp(otp, token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "otp": otp,
                "token": token
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/forgotPassword/verifyOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// resend otp on customer forgot password
export function resendForgotPasswordOtp(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "token": token
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/forgotPassword/resendOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}