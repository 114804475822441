import React from 'react';
import { createContext, useContext, useState } from 'react';
import { useLogin } from './CustomerLoginContext';

// create global signup context
export const ForgotPasswordContext = createContext();

export function ForgotPasswordProvider({ children }) {

    const {saveLoginDetails} = useLogin()
    const [signUpError, setSignUpError] = useState("");
    const [fgtPswdToken, setFgtPswdToken] = useState("");

    // export global context
    return (
        <ForgotPasswordContext.Provider
            value={{
                fgtPswdToken,
                setFgtPswdToken
            }}
        >
            {children}
        </ForgotPasswordContext.Provider>
    );
}


export function useForgotPasswordContext() {
    return useContext(ForgotPasswordContext)
}