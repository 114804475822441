import axios from 'axios';
import { configs } from '../../assets/Config';

// customer forgot password send otp
export function forgotPasswordSendOtp(email) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "email": email
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/forgotPassword`,
                headers: {
                    'Authorization': `Bearer null`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}