import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { configs } from "../../assets/Config";
import '../../assets/styles/CustomerLogin.css';
import { verifySignupOtp, resendSignUpOtp, verifyForgotPasswordOtp, resendForgotPasswordOtp } from "../../services/apis/VerifyOTPApi";
import { useLogin } from "../../services/contexts/CustomerLoginContext";
import { useCustomerSignUpContext } from "../../services/contexts/CustomerSignUpContext";
import { useForgotPasswordContext } from "../../services/contexts/ForgotPasswordContext";
import imgdn from '../../assets/images/leftBG.png'


export default function VerifyOTPPage() {

    const { signUpToken, setSignUpToken } = useCustomerSignUpContext();
    const { fgtPswdToken, setFgtPswdToken } = useForgotPasswordContext();
    const { verifyEmail } = useLogin();
    const history = useHistory();
    const match = useRouteMatch();

    // hook declaration to process otp verification
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [otpTimer, setOtpTimer] = useState(configs.otpTimer);

    // otp timer
    useEffect(() => {
        try {
            const timer = otpTimer > 0 && setInterval(() => setOtpTimer(otpTimer - 1), 1000);
            return () => clearInterval(timer);
        } catch (error) {

        }
    }, [otpTimer]);

    // resend otp
    async function resendOtp(e) {
        try {
            e.preventDefault();
            if (signUpToken) {
                let response = await resendSignUpOtp(signUpToken);
                if (response && response.status === 200) {
                    setOtpError(response.data.message)
                    setOtpTimer(configs.otpTimer);
                } else if (response && response.status === 401) {
                    history.push('/signup')
                } else {
                    setOtpError(response.data.error)
                }
            } else if (fgtPswdToken) {
                let response = await resendForgotPasswordOtp(fgtPswdToken);
                if (response && response.status === 200) {
                    setOtpError(response.data.message)
                    setOtpTimer(configs.otpTimer);
                } else if (response && response.status === 401) {
                    history.push('/forgotPassword')
                } else {
                    setOtpError(response.data.error)
                }
            }
        } catch (error) {

        }
    }

    // validate otp
    function validateOtp() {
        const re = /[0-9]{6}/g;
        let res = re.test(otp);

        return res;
    }

    // verify otp
    async function verifyOtp(e) {
        try {
            e.preventDefault();

            let otpVerify = validateOtp()
            if (!otpVerify) {
                setOtpError(configs.errorMessages.verifyOtp)
            } else {
                if (signUpToken) {
                    let response = await verifySignupOtp(otp, signUpToken);
                    if (response && response.status === 200) {
                        setSignUpToken(response.data.token)
                        setOtpError("");
                        setOtp("")
                        let url = match.url.split('/');
                        url[(url.length - 1)] = "createPassword"
                        history.push(`${url.join('/')}`)
                    } else if (response && response.status === 401) {
                        history.push('/signup')
                    } else {
                        setOtpError(response.data.error)
                    }
                } else if (fgtPswdToken) {
                    let response = await verifyForgotPasswordOtp(otp, fgtPswdToken);
                    if (response && response.status === 200) {
                        setFgtPswdToken(fgtPswdToken)
                        setOtpError("");
                        setOtp("")
                        let url = match.url.split('/');
                        url[(url.length - 1)] = "createPassword"
                        history.push(`${url.join('/')}`)
                    } else if (response && response.status === 401) {
                        history.push('/forgotPassword')
                    } else {
                        setOtpError(response.data.error)
                    }
                }
            }
        } catch (error) {

        }
    }

    // verify OTP ui code
    return (
        <div >
            <div>
                <p className="p-0 m-0 welcome-heading">Verify OTP</p>
                <p className="p-0 m-0 welcome-sub-heading f-14">OTP has been sent to <b>{verifyEmail}</b></p>
            </div>
            <div className="loginForm">
                <div className="form-group mt-4 mb-2">
                    <input type="number" maxLength={6} className="form-control login-screen-input f-14" name="otp" placeholder="Enter your OTP" onChange={(e) => {
                        setOtpError("")
                        setOtp(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-2 mb-2 text-right">
                    <label className="m-0 ml-2 p-0 f-12 font-weight-bold"><span style={{ color: "#009FE3" }} className={otpTimer === 0 ? "curPoint" : ""} onClick={otpTimer === 0 ? resendOtp : (e) => {
                        e.preventDefault()
                    }}>{otpTimer === 0 ? configs.hardCodeTexts.verifyOTP.resendBtn : configs.hardCodeTexts.verifyOTP.timerText.replace("0", otpTimer)}</span></label>
                </div>

                <div className="ml-2">
                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{otpError}</p>
                </div>
                <div className="form-group mt-2 mb-0">
                    <button className="signIn-button curPoint" onClick={verifyOtp}>Verify OTP</button>
                </div>
            </div>


        </div>
        // <section>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-6 d-none d-sm-block p-0 m-0">
        //                 <div className="login-screen-wallpaper">
        //                     <img src={imgdn} className="w-100 h-100" alt="building" />
        //                 </div>
        //             </div>
        //             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
        //                 <div className="w-100">
        //                     <div className="login-screen-logo">
        //                         <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="logo" />
        //                     </div>
        //                     <form>
        //                         <div className="form-group">
        //                             <h4 className="mb-0 text-custom-greyDark">{configs.hardCodeTexts.verifyOTP.heading}</h4>
        //                             <p className="mt-0 text-custom-grey">{configs.hardCodeTexts.verifyOTP.subHeading}<b><span className="text-dark">{verifyEmail}</span></b></p>
        //                         </div>
        //                         <div className="form-group mt-5">
        //                             <input type="text" maxLength={6} className="form-control login-screen-input f-14" name="user_OTP" placeholder="Enter your OTP" required onChange={(e) => {
        //                                 setOtp(e.target.value)
        //                             }} />
        //                         </div>
        //                         <div className="form-row mt-4 mb-4">
        //                             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        //                                 <p className="text-danger" id="loginFormError">{otpError}</p>
        //                             </div>
        //                             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-sm-right text-center">
        // { otpTimer === 0 ? <a className="text-primary curPoint" onClick={resendOtp}>{configs.hardCodeTexts.verifyOTP.resendBtn}</a> : <a className="text-primary">{configs.hardCodeTexts.verifyOTP.timerText.replace("0", otpTimer)}</a> }
        //                             </div>
        //                         </div>
        //                         <div className="form-group text-center">
        //                             <button type="submit" className="btn btn-sm btn-custom btn-primary rounded px-3 login-screen-sign-in-button" disabled={otp.length === 6 ? false : true} onClick={verifyOtp}>{configs.hardCodeTexts.verifyOTP.verifyBtn}</button>
        //                         </div>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}
