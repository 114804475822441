import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import { useHistory, } from "react-router";
import '../../assets/styles/TopBar.css'
import { useLogin } from "../../services/contexts/CustomerLoginContext";


export default function NotificationModalPage() {

    const { showNotModal, setShowNotModal, notifications, getAllNotifications, markReadAllNotification, markReadNotificationById, notiPage, setNotiPage, hideShowMore } = useLogin()

    const history = useHistory()

    const [update, setUpdate] = useState(0)

    const handleClose = () => {
        setShowNotModal(false)
    };

    async function showMoreNotification() {
        try {
            let page = notiPage;
            setNotiPage(page += 1)
            await getAllNotifications(page, true)
            let up = update
            setUpdate(up += 1)

        } catch (error) {
        }
    }

    return (<Modal open={showNotModal} onClose={handleClose}>

        <div className="notify-modal-container">
            <div className="notiParent">
                <div style={{ display: "flex" }}>
                    <div className="col-6 bg-white border-bottom py-1">
                        <span className="f-18 font-weight-bold">Notifications</span>
                    </div>
                    <div className="col-6 bg-white border-bottom py-1" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a href="#"><span className="f-12" onClick={(e) => {
                            e.preventDefault()
                            markReadAllNotification()
                        }}>Mark All as Read</span></a>
                    </div>
                </div>
                <div style={{ overflowY: "auto", height: "500px" }} className="bg-white notificationPanel">
                    {notifications.length > 0 ? notifications.map((element, key) => {
                        return (element.is_seen === 0 ?
                            <div className="col-12 unread-not-bg border-bottom py-1 curPoint" key={`noti` + key} onClick={(e) => {
                                e.preventDefault()
                                markReadNotificationById(element.id)
                            }}>
                                <div className="text-custom-greyDark font-weight-normal f-12 pb-0 mb-0">
                                    <span className="text-custom-grey font-weight-normal f-12">{element.date}</span> <br />
                                    <span className="f-14 text-primary font-weight-bold">{element.heading}</span> <br />
                                    <div dangerouslySetInnerHTML={{ __html: element.description }}></div>
                                </div>
                            </div>
                            :
                            <div className="col-12 bg-white border-bottom py-1" key={`noti` + key}>
                                <div className="text-custom-greyDark font-weight-normal f-12 pb-0 mb-0">
                                    <span className="text-custom-grey font-weight-normal f-12">{element.date}</span> <br />
                                    <span className="f-14 text-primary font-weight-bold">{element.heading}</span> <br />
                                    <div dangerouslySetInnerHTML={{ __html: element.description }}></div>
                                </div>
                            </div>
                        )
                    }) : <div className="col-12 bg-white border-bottom py-1">
                        <p className="text-custom-greyDark font-weight-normal f-12 pb-0 mb-0">
                            <span className="f-14 text-primary font-weight-bold">No New Notifications found</span> <br />
                        </p>
                    </div>}
                </div>
                {!hideShowMore ? <div className="col-12 bg-white border-top py-1">
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        showMoreNotification()
                    }}><span className="f-12">Show More</span></a>
                </div> : null}
            </div>
        </div>
    </Modal>)
}