import Modal from "@material-ui/core/Modal";
import { useHistory, } from "react-router";
import '../../assets/styles/TopBar.css'
import { useCustomerKYCContext } from "../../services/contexts/CustomerKYCContext";


export default function SuccessModalPage() {
    const { successModal, setsuccessModal } = useCustomerKYCContext()

    const history = useHistory()

    const handleClose = () => {
        setsuccessModal(false);
        if (history.location.state) {
            history.goBack()
        } else {
            history.push('/dashboard')
        }
    };
    return (<Modal open={successModal} onClose={handleClose}>
        <div className="modal-fade modalPosition" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center py-3">
                        <p className="text-center h1 font-weight-normal text-success">
                            <i className="far fa-check-circle mr-1"></i>
                            Success
                        </p>
                        <p className="mt-3 px-3">
                            Our executive will respond to your KYC as soon as possible.
                        </p>
                        <button type="button" data-dismiss="modal" className="btn btn-primary text-center mt-3" onClick={handleClose}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}