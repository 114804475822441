import axios from 'axios';
import { configs } from '../../assets/Config';

export function savePersonalDetail(name, email, mobile, country_code, token, referralCode, utmReferrer) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "name": name,
                "email": email,
                "mobile": mobile,
                "country_code": country_code,
                "referral_id": referralCode,
                "utm_referrer": utmReferrer
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/registration/saveBasicDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// generate google auth url for customer oAuth registration
export function getGoogleAuthUrl() {
    return new Promise(async (resolve, reject) => {
        try {

            let res = await axios.get(
                `${process.env.REACT_APP_BASEURL}/distributor/registration/googleAuthURL`
            );
            resolve(res?.data?.authURL);
        } catch (err) {
            reject(err?.response)
        }
    })
}

// backend api for customer oAuth registration 
export function signUpCustomerWithGoogleCred(googleCode) {
    return new Promise(async (resolve, reject) => {
        const bodyParameters = {
            code: googleCode,
        };

        try {
            let res = await axios.post(
                `${process.env.REACT_APP_BASEURL}/distributor/registration/google`,
                bodyParameters
            );
            resolve(res)

        } catch (err) {
            resolve(err?.response)

        }
    })
}

// customer oAuth registration save mobile
export function signUpCustomerGoogleCredSaveMobile(token, mobile, country_code, referralCode, utmReferrer) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "token": token,
                "mobile": mobile,
                "country_code": country_code,
                "referral_id": referralCode,
                "utm_referrer": utmReferrer
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/google/saveMobile`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let res = await axios(config)
            resolve(res)

        } catch (err) {
            resolve(err?.response)

        }
    })
}