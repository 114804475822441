import React, { useEffect, useState } from 'react';
import '../../../assets/styles/TopBar.css'
import imgn from '../../../assets/images/default-user-profile.svg';
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import ProfileModalPage from '../ProfileModalPage';
import { configs, showLeftBar } from '../../../assets/Config';
import { Link, useHistory } from 'react-router-dom';
import { useAUAssetMgmtContext } from '../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';

export default function BottomBarPage() {

    const { showDefaultMobBtn, setShowDefaultMobBtn, setCurrModule, activeList, setActiveList, defaultDisplay, setDefaultDisplay, currModule, setProfileModal, showMobBS, setShowMobBS, currMod, setcurrMod } = useLogin()
    const { assetDetail, setOrderType, setShareSub } = useAUAssetMgmtContext();

    const history = useHistory()

    // change state of button bottom
    function updateBottomBtn(setActive) {
        setShareSub(setActive)
        setOrderType(setActive)
        if (showDefaultMobBtn) {
            setShowDefaultMobBtn(false)
            document.getElementsByClassName("shares-buy")[0].style.display = "block";
            document.getElementsByClassName("shares-detail")[0].style.display = "none";
            document.getElementsByClassName("shares-header")[0].style.display = "none";
        } else {
            setShowDefaultMobBtn(true)
            document.getElementsByClassName("shares-buy")[0].style.display = "none";
            document.getElementsByClassName("shares-detail")[0].style.display = "block";
            document.getElementsByClassName("shares-header")[0].style.display = "block";
        }
    }

    // show all companies
    function leftBarDisplay(show, current) {
        let response = showLeftBar(show, current, defaultDisplay, activeList, currModule)
        if (!response) {
            setcurrMod(currModule)
            setDefaultDisplay(response)
        } else {
            setcurrMod(configs.modules.mobCom);
            setShowMobBS(false)
        }
    }

    useEffect(() => {
        if (showMobBS) {
            document.getElementsByClassName("btm-bar")[0].style.height = "110px";
        } else {
            document.getElementsByClassName("btm-bar")[0].style.height = "60px";
        }
    }, [showMobBS])

    return (
        <div id="bottomBtns" className="d-md-none d-block btm-bar">
            {/* <div className="row">
                <div > */}
            <div className="d-flex align-items-center bg-light py-2 btm-bar-space fixed-bottom f-14">
                <div className={`p-0 f-12 text-center ${currMod !== configs.modules.mobCom && currModule == configs.modules.dashboard ? "text-primary font-weight-bold" : "text-custom-greyDark"}`} onClick={(e) => {
                    e.preventDefault()
                    setCurrModule(configs.modules.dashboard)
                    leftBarDisplay(false)
                    history.push('/dashboard')
                }}>
                    <i className="f-16 fa fa-bar-chart"></i> <br />
                    Dashboard
                </div>
                <div className='btm-bar-hr'></div>
                <div className={`p-0 f-12 text-center ${currMod !== configs.modules.mobCom && currModule == configs.modules.clients ? "text-primary font-weight-bold" : "text-custom-greyDark"}`} onClick={(e) => {
                    e.preventDefault()
                    setCurrModule(configs.modules.clients)
                    leftBarDisplay(false)
                    history.push('/clients')
                }}>
                    <i className='f-16 fas fa-chalkboard-teacher'></i> <br />
                    Client Details
                </div>
                <div className='btm-bar-hr'></div>
                <div className={`p-0 f-12 text-center ${currMod == configs.modules.mobCom ? "text-primary font-weight-bold" : "text-custom-greyDark"}`} onClick={(e) => {
                    e.preventDefault()
                    setDefaultDisplay(true)
                    leftBarDisplay(true, configs.list.all)
                }}>
                    <i className='f-16 fas fa-user-plus'></i> <br />
                    Invite Clients
                </div>
                <div className='btm-bar-hr'></div>
                <div className={`p-0 f-12 text-center ${currMod !== configs.modules.mobCom && currModule == configs.modules.commission ? "text-primary font-weight-bold" : "text-custom-greyDark"}`} onClick={(e) => {
                    e.preventDefault()
                    setCurrModule(configs.modules.commission)
                    leftBarDisplay(false)
                    history.push('/commission')
                }}>
                    <i className="f-16 fas fa-hand-holding-usd"></i><br />
                    Commission
                </div>
                <div className='btm-bar-hr'></div>
                <div className={`p-0 f-12 text-center ${currMod !== configs.modules.mobCom && currModule == configs.modules.howItWorks ? "text-primary font-weight-bold" : "text-custom-greyDark"}`} onClick={(e) => {
                    e.preventDefault()
                    setCurrModule(configs.modules.howItWorks)
                    leftBarDisplay(false)
                    window.open('/distDetail#howitworks', "_self")
                }}>
                    <i className="f-16 fa fa-info-circle"></i><br />
                    How it Works
                </div>
            </div>
            {showMobBS ?
                <div id="mobBuySellBtn" className="col-12">
                    {(showDefaultMobBtn && (assetDetail.basic_data && assetDetail.basic_data.disabletrading !== 1)) ?
                        <div className="row d-flex align-items-center buy-sell-mob-btn">
                            <div className="col-6 text-center bg-success text-custom-greyDark" onClick={(e) => {
                                updateBottomBtn(configs.shareSub.buy)
                                setOrderType(configs.shareSub.buy)
                            }}>
                                <label className="custom-button text-light font-weight-bold">
                                    Buy
                                </label>
                            </div>
                            <div className="col-6 text-center bg-danger border-right text-custom-greyDark" onClick={(e) => {
                                updateBottomBtn(configs.shareSub.sell)
                                setOrderType(configs.shareSub.sell)
                            }}>
                                <label className="custom-button text-light font-weight-bold">
                                    Sell
                                </label>
                            </div>
                        </div> : (showDefaultMobBtn && (assetDetail.basic_data && assetDetail.basic_data.disabletrading === 1)) ?
                            <div className="row d-flex align-items-center buy-sell-mob-btn">
                                <div className="col-12 text-center bg-primary text-custom-greyDark" onClick={(e) => {
                                    updateBottomBtn(configs.shareSub.buy)
                                    setOrderType(configs.shareSub.buy)
                                }}>
                                    <label className="custom-button text-light font-weight-bold">
                                        Enquiry
                                    </label>
                                </div>
                            </div>
                            :
                            <div className="row d-flex align-items-center buy-sell-mob-btn">
                                <div className="col-12 text-center bg-success text-custom-greyDark" onClick={(e) => {
                                    updateBottomBtn(configs.shareSub.buy)
                                    setOrderType(configs.shareSub.buy)
                                }}>
                                    <label className="custom-button text-light font-weight-bold">
                                        Home
                                    </label>
                                </div>
                            </div>
                    }
                </div>
                : null}
        </div >
    )
}