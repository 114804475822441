import Modal from "@material-ui/core/Modal";
import { useEffect, useState } from "react";
import { useAURegister_KYCContext } from "../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext";
import { useLogin } from "../../../../services/contexts/CustomerLoginContext";
import { assistedUserToken, configs } from "../../../../assets/Config";
import { resendKYCModifyAccessToken, resendMobileOtp, verifyKYCModifyAccessToken, verifyMobileOtp } from "../../../../services/apis/assistedUser/registration_kyc/AUKYCApi";

export default function AUVerifyOTPMobPage(requestPage) {
    const { otpModal, setOtpModal, mobOtpToken, setMobOtpToken, kycDetails, setKycDetails, getKYCSavedDetailContext } = useAURegister_KYCContext();
    const { token, logout, setLoginError, proxyEmail } = useLogin()

    // hook declaration to process otp verification
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [otpTimer, setOtpTimer] = useState(configs.otpTimer);

    // otp timer
    useEffect(() => {
        if (otpModal) {
            try {
                const timer = otpTimer > 0 && setInterval(() => setOtpTimer(otpTimer - 1), 1000);
                return () => clearInterval(timer);
            } catch (error) {

            }
        }
    }, [otpModal, otpTimer]);

    // resend otp
    async function resendMobOtp(e) {
        try {
            e.preventDefault();
            let response = requestPage.verifyAccessToken ? await resendKYCModifyAccessToken(assistedUserToken(token, proxyEmail), mobOtpToken) : await resendMobileOtp(assistedUserToken(token, proxyEmail), mobOtpToken);
            if (response && response.status === 200) {
                setOtpError(response.data.message)
                setOtpTimer(configs.otpTimer);
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setOtpError(response.data.error)
            }
        } catch (error) {

        }
    }

    // validate otp
    function validateOtp() {
        const re = /[0-9]{6}/g;
        let res = re.test(otp);

        return res;
    }

    // verify otp
    async function verifyMobOtp(e) {
        try {
            e.preventDefault();

            let otpVerify = validateOtp()
            if (!otpVerify) {
                setOtpError(configs.errorMessages.verifyOtp)
            } else {
                let response = requestPage.verifyAccessToken ? await verifyKYCModifyAccessToken(assistedUserToken(token, proxyEmail), mobOtpToken, otp) : await verifyMobileOtp(assistedUserToken(token, proxyEmail), mobOtpToken, otp);
                if (response && response.status === 200) {
                    getKYCSavedDetailContext()
                    setMobOtpToken("")
                    setOtpError("")
                    setOtp("")
                    setOtpTimer(configs.otpTimer)
                    setOtpModal(false)
                } else if (response && response.status === 401) {
                    logout()
                    //setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setOtpError(response.data.error)
                }
            }
        } catch (error) {

        }
    }

    const handleClose = () => {
        setOtpModal(false);
        setMobOtpToken("")
        setOtpError("")
        setOtp("")
        setOtpTimer(configs.otpTimer)
        setOtpModal(false)
    };

    return (<Modal open={otpModal} onClose={handleClose}>
        <div className="modal-fade modalPosition" id="VerifyOTPModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body py-5 text-center">
                        <p className="h5 text-weight-normal text-theme mb-3">Verification Code</p>
                        <form action="">
                            <input type="text" maxLength={6} className="otp-textField mt-3 f-14" placeholder="--- ---" required onChange={(e) => {
                                setOtp(e.target.value)
                            }} /> <br />
                            <div className="form-row mt-4 mb-4">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <p className="text-danger" id="otpMobError">{otpError ? otpError : requestPage.verifyAccessToken ? `OTP has been sent to client email ${proxyEmail}` : ""}</p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-sm-right text-center">
                                    {otpTimer === 0 ? <a className="text-primary curPoint" onClick={resendMobOtp}>{configs.hardCodeTexts.verifyOTP.resendBtn}</a> : <a className="text-primary">{configs.hardCodeTexts.verifyOTP.timerText.replace("0", otpTimer)}</a>}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-sm btn-primary rounded px-3 mt-4 login-screen-sign-in-button" onClick={verifyMobOtp}>Verify</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}