import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { animation, configs, formatCurrency, showLeftBar } from "../../../assets/Config";
import '../../../assets/styles/CustomerLogin.css';
import '../../../assets/styles/animation.css';

import { getGoogleAuthUrl, saveDistributorQuery } from "../../../services/apis/CustomerLoginApi";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";

import logo from '../../../assets/images/logo.png'
import headerImg from '../../../assets/images/headerImg.svg'
import googleIcon from '../../../assets/images/google.png'
import economicTime from '../../../assets/images/economicTime.svg'
import financialExpress from '../../../assets/images/financialExpress.svg'
import inc42 from '../../../assets/images/inc42.svg'
import moneyControl from '../../../assets/images/moneyControl.svg'
import kyc from '../../../assets/images/kyc.svg'
import gift from '../../../assets/images/gift.svg'
import trade from '../../../assets/images/trade.svg'
import pay from '../../../assets/images/pay.svg'
import Phone from '../../../assets/images/Phone.svg'
import Mail from '../../../assets/images/Mail.svg'
import WhatsApp from '../../../assets/images/WhatsApp.svg'
import arr1 from '../../../assets/images/arr1.svg'
import arr2 from '../../../assets/images/arr2.svg'
import distDetail from '../../../assets/images/distDetail.svg'
import partnerTop from '../../../assets/images/partnerTop.svg'
import CustomerLoginPage from "./CustomerLoginPage";
import SignUpPersonalDetailPage from "../signup/SignUpPersonalDetailPage";
import SignUpGoogleMobilePage from "../signup/SignUpGoogleMobilePage";
import VerifyOTPPage from "../../includes/VerifyOTPPage";
import CreatePasswordPage from "../../includes/CreatePassPage";
import ForgotPasswordPage from "../forgotPassword/ForgotPasswordPage";
import { getLoginCompanies } from "../../../services/apis/CustomerLoginApi";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import BottomBarPage from "../../includes/dashboard/BottomBarPage";
import imgn from '../../../assets/images/default-user-profile.svg';
import partner1 from '../../../assets/images/partner1.svg';
import partner2 from '../../../assets/images/partner2.svg';
import partner3 from '../../../assets/images/partner3.svg';
import about1 from '../../../assets/images/about1.svg';
import about2 from '../../../assets/images/about2.svg';
import about3 from '../../../assets/images/about3.svg';
import orowealth from '../../../assets/images/orowealth.svg';
import sbnri from '../../../assets/images/sbnri.svg';
import tradebrain from '../../../assets/images/tradebrain.svg';
import programWork from '../../../assets/images/programWork.svg';
import faq1 from '../../../assets/images/faq1.png';
import faq2 from '../../../assets/images/faq2.png';
import '../../../assets/styles/TopBar.css'
import { Accordion, AccordionDetails, AccordionSummary, Table, Typography, TextField } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';

import ToastPage from "../../includes/ToastPage";


export default function HowItWorksPage() {

    const { token, setShowSuccess, setCurrModule } = useLogin()

    const [compLogo, setCompLogo] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()

    const scrollToContactRef = useRef(null)

    const scrollToContact = () =>
        window.scrollTo({
            top: scrollToContactRef.current.offsetTop,
            behavior: "smooth",
            // You can also assign value "auto"
            // to the behavior parameter.
        });

    useEffect(() => {
        try {
            (async () => {
                let result = await getLoginCompanies()
                if (result && result.status === 200) {
                    setCompLogo(result.data)
                }
            })()
            setCurrModule(configs.modules.howItWorks)
        } catch (error) {
            console.error(error)
        }
    }, [])
    setTimeout(() => {
        animation()
    }, 1000)

    // save form details
    async function saveFormDetail() {
        try {
            if (!name.trim()) {
                setError("Name is required.")
            } else if (!mobile.trim()) {
                setError("Mobile Number is required.")
            } else if (!email.trim() || !configs.emailRegex.test(String(email).toLowerCase())) {
                setError("Please enter a valid email.")
            } else if (!message.trim()) {
                setError("Message is required.")
            } else {
                let response = await saveDistributorQuery(name, email, mobile, message)
                if (response && response.status === 200) {
                    setName("")
                    setEmail("")
                    setMobile("")
                    setMessage("")
                    setShowSuccess(true)
                } else {
                    setError(response.data.error)
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    // login page ui code
    return (
        <div id="distDetail" className="d-flex flex-column">
            <TopBarPage />
            <div>
                <div className="row mt-5 m-0 p-0 align-items-center">
                    <div className="col-md-8 col-12 col m-0 p-0">
                        <img style={{ objectFit: "contain", width: "100%" }} src={distDetail} alt="altius investech login" />
                    </div>
                    <div className="col-md-4 col-12 order-md-first m-0 p-0">
                        <div className="distDetailBannerBg">
                            <p className="p-0 m-0 distDetailBannerTitle">Private equity market has crossed $200 billion in India</p>
                            <p className="p-0 m-0 distDetailBannerBgP">Are you catering to this new wave of investors?</p>
                            <ul className="distDetailBannerul">
                                <li className="distDetailBannerBgP">Become an Altius Partner and give your clients an exposure to the private markets</li>
                                <li className="distDetailBannerBgP">Earn commissions for every transaction that your clients make on our platform</li>
                            </ul>
                            <div className="justify-content-between d-flex">
                                <div className="col-6 m-0 p-0 d-flex justify-content-center">
                                    <div className="partnerUsBtn curPoint" onClick={(e) => {
                                        e.preventDefault()
                                        { token ? window.open('/dashboard', "_self") : window.open('/signup', "_self") }
                                    }}>{token ? "Dashboard" : "Partner with Us"}</div>
                                </div>
                                <div className="col-6 m-0 p-0 d-flex justify-content-center">
                                    <div className="getTouchBtn curPoint" onClick={scrollToContact}>Get in Touch</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mainDiv">
                    <div>
                        <div className="arrowDivLeft">
                            <img src={arr1} />
                        </div>
                        {/* partner with Altius div-----------------------------------------------------------------------------------*/}
                        <div className="investDiv">
                            <div className="redBack"></div>
                            <div style={{ zIndex: 999 }}>
                                <p className="p-0 m-0 welcome-heading">Partner with Altius</p>
                                <div className="row m-0 p-0 mt-3">
                                    <div className="col-md-4 col-6 m-0 p-0">
                                        <div className="partnerWithCard">
                                            <img className="partnerWithCardIcon" src={partner1} alt="partner with altius investech" />
                                            <div>
                                                <p className="m-0 p-0 pt-2 text-left partnerWithCardTitle">Leverage our technology</p>
                                                <p className="m-0 p-0 pt-2 text-left">Our platform allows you to <b>manage</b> your clients’ investments <b>seamlessly</b> and hassle free.  Clients get access to <b>150+ Pre-IPO</b> companies </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 m-0 p-0 mt-md-5">
                                        <div className="partnerWithCard">
                                            <img className="partnerWithCardIcon" src={partner2} alt="partner with altius investech" />
                                            <div>
                                                <p className="m-0 p-0 pt-2 text-left partnerWithCardTitle">Build your business</p>
                                                <p className="m-0 p-0 pt-2 text-left">Help your clients grow their wealth through investments in <b>private markets</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 m-0 p-0 d-md-block d-flex justify-content-center">
                                        <div className="partnerWithCard cardWidth">
                                            <img className="partnerWithCardIcon" src={partner3} alt="partner with altius investech" />
                                            <div>
                                                <p className="m-0 p-0 pt-2 text-left partnerWithCardTitle">Transparency</p>
                                                <p className="m-0 p-0 pt-2 text-left">We Appreciate the effort it takes to build <b>relationships</b>, hence our platform gives <b>100% transparency</b> in terms of client data and commissions shared on each transaction</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="arrowDivRight">
                            <img src={arr2} />
                        </div>
                        {/* how does partner program work div-----------------------------------------------------------------------------------*/}
                        <div id="howitworks" className="investDiv">
                            <div style={{ zIndex: 999 }}>
                                <p className="p-0 m-0 welcome-heading d-md-none">How does the partner program work?</p>
                                <div className="row m-0 p-0 mt-3 align-items-center">
                                    <div className="col-md-6 col-12 m-0 p-0 pr-lg-5 pr-md-3">
                                        <img className="partnerWithImage" src={programWork} alt="partner with altius investech" />
                                    </div>
                                    <div className="col-md-6 col-12 m-0 p-0 pl-lg-5 pl-md-3">
                                        <p className="p-0 m-0 welcome-heading text-md-left d-md-block d-none">How does the partner program work?</p>
                                        <div className="p-2">
                                            <div className="row m-0 p-0 align-items-center distDetailBannerBgP">
                                                <div className="col-2 m-0 p-0 d-flex justify-content-center"><p className="m-0 p-0 howDoesNumber">1</p></div>
                                                <div className="col-10 m-0 p-0 text-left"><p className="m-0 p-0">Signup as Partner</p></div>
                                            </div>
                                            <div className="row m-0 p-0 align-items-center distDetailBannerBgP">
                                                <div className="col-2 m-0 p-0 d-flex justify-content-center"><p className="m-0 p-0 howDoesNumber">2</p></div>
                                                <div className="col-10 m-0 p-0 text-left"><p className="m-0 p-0">Invite your clients to the Altius’ client portal using your invite link</p></div>
                                            </div>
                                            <div className="row m-0 p-0 align-items-center distDetailBannerBgP">
                                                <div className="col-2 m-0 p-0 d-flex justify-content-center"><p className="m-0 p-0 howDoesNumber">3</p></div>
                                                <div className="col-10 m-0 p-0 text-left"><p className="m-0 p-0">Your clients invest in Pre-IPO and unlisted shares</p></div>
                                            </div>
                                            <div className="row m-0 p-0 align-items-center distDetailBannerBgP">
                                                <div className="col-2 m-0 p-0 d-flex justify-content-center"><p className="m-0 p-0 howDoesNumber">4</p></div>
                                                <div className="col-10 m-0 p-0 text-left"><p className="m-0 p-0">Track your clients and commissions through our partner portal</p></div>
                                            </div>
                                        </div>
                                        <div className="justify-content-between d-flex distDetailBannerBgP">
                                            <div className="col-6 m-0 p-0 d-flex justify-content-center">
                                                <div className="partnerUsBtn curPoint" onClick={(e) => {
                                                    e.preventDefault()
                                                    { token ? window.open('/dashboard', "_self") : window.open('/signup', "_self") }
                                                }}>{token ? "Dashboard" : "Partner with Us"}</div>
                                            </div>
                                            <div className="col-6 m-0 p-0 d-flex justify-content-center">
                                                <div className="getTouchBtn curPoint" onClick={scrollToContact}>Get in Touch</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* our partners div-----------------------------------------------------------------------------------*/}
                <div className="ourPartner investDiv">
                    <p className="p-0 m-0 welcome-heading">Our Partners</p>
                    <div className="row m-0 p-0 mt-4 align-items-center">
                        <div className="col-4 p-0 m-0">
                            <img className="ourPartnerIcon" src={orowealth} alt="altius investech partner" />
                            <p className="m-0 lightColorText ourPartnerTitle">Orowealth</p>
                        </div>
                        <div className="col-4 p-0 m-0">
                            <img className="ourPartnerIcon" src={sbnri} alt="altius investech partner" />
                            <p className="m-0 lightColorText ourPartnerTitle">Sbnri</p>
                        </div>
                        <div className="col-4 p-0 m-0">
                            <img className="ourPartnerIcon" src={tradebrain} alt="altius investech partner" />
                            <p className="m-0 lightColorText ourPartnerTitle">Tradebrains</p>
                        </div>
                    </div>
                </div>

                <div className="mainDiv">
                    <div>
                        <div className="arrowDivRight">
                            <img src={arr2} />
                        </div>
                        {/* about Altius div-----------------------------------------------------------------------------------*/}
                        <div className="investDiv">
                            <div className="redBack"></div>
                            <div style={{ zIndex: 999 }}>
                                <p className="p-0 m-0 welcome-heading">About Altius</p>
                                <div className="row m-0 p-0 mt-3">
                                    <div className="col-md-4 col-6 m-0 p-0">
                                        <div className="aboutCard">
                                            <img className="aboutCardIcon" src={about1} alt="about altius investech" />
                                            <p className="m-0 p-0 aboutCardTitle">25+ years of expertise in financial services</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 m-0 p-0">
                                        <div className="aboutCard">
                                            <img className="aboutCardIcon" src={about2} alt="about altius investech" />
                                            <p className="m-0 p-0 aboutCardTitle">8000+ investors served in 15+ countries</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 m-0 p-0 d-md-block d-flex justify-content-center">
                                        <div className="aboutCard cardWidth">
                                            <img className="aboutCardIcon" src={about3} alt="about altius investech" />
                                            <p className="m-0 p-0 aboutCardTitle">300 Crores + transaction value in pre-IPO space</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="arrowDivLeft">
                            <img src={arr1} />
                        </div>

                        {/* invest in companies div-----------------------------------------------------------------------------------*/}
                        <div className="investDiv">
                            <div className="blueBack"></div>
                            <div style={{ zIndex: 999 }}>
                                <p className="p-0 m-0 welcome-heading">Pre-IPO Opportunities</p>
                                <div className="row" id='test'>

                                    {compLogo && compLogo.length > 0 ?
                                        <div className="marquee">
                                            <ul className="marquee-content">
                                                {compLogo.map((element, key) => {
                                                    let names = element.name.split(" ")
                                                    return (
                                                        <li key={`logImg` + key}>
                                                            <div className="animCard">
                                                                <div className="animImgCard">
                                                                    <img className="animImg" src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${element.logo}`} alt={element.name} />
                                                                </div>
                                                                <p className="m-0 p-0 f-12">{`${names[0]} ${!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(names[1]) ? names[1] : ""}`}</p>
                                                            </div>
                                                        </li>
                                                    )
                                                })}

                                            </ul>

                                        </div>
                                        : null}

                                </div>
                                {/* <p className="p-0 m-0 mt-4 investDetail">
                        Altius is India’s leading platform which facilitates buying and selling of Unlisted, Pre-IPO and privately held shares. We ensure liquidity for our clients by providing a two-way (buy &amp; sell) quote on all investments on our platform, fostering trust and transparency.
                    </p> */}
                            </div>
                        </div>

                        <div className="arrowDivRight">
                            <img src={arr2} />
                        </div>

                        {/* featued in div----------------------------------------------------------------------------------- */}
                        <div className="investDiv">
                            {/* <div className="blueBack"></div> */}
                            <div style={{ zIndex: 999 }}>
                                <div className="my-4">
                                    <p className="p-0 m-0 welcome-heading">Featured In</p>
                                </div>
                                <div className="row mt-4 align-items-center">
                                    <div className="col-3">
                                        <img className="featuredInImage curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            window.open("https://altiusinvestech.com/press", "_blank")
                                        }} src={economicTime} alt="altius investech economic times" />
                                    </div>
                                    <div className="col-3">
                                        <img className="featuredInImage curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            window.open("https://altiusinvestech.com/press", "_blank")
                                        }} src={inc42} alt="altius investech inc42" />
                                    </div>
                                    <div className="col-3">
                                        <img className="featuredInImage curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            window.open("https://altiusinvestech.com/press", "_blank")
                                        }} src={moneyControl} alt="altius investech money control" />
                                    </div>
                                    <div className="col-3">
                                        <img className="featuredInImage curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            window.open("https://altiusinvestech.com/press", "_blank")
                                        }} src={financialExpress} alt="altius investech financial express" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="arrowDivLeft">
                            <img src={arr1} />
                        </div>
                        {/* get in touch div-----------------------------------------------------------------------------------*/}
                        <div id="getInTouch" ref={scrollToContactRef} className="investDiv">
                            <div className="redBack"></div>
                            <div style={{ zIndex: 999 }}>
                                <div className="my-4">
                                    <p className="p-0 m-0 welcome-heading">Get in Touch</p>
                                </div>
                                <div className="getTouchPad">
                                    {/* <p className="m-0 p-0 font-weight-bold" style={{ color: "#69CDF9" }}><u>Contact Us</u></p> */}
                                    <div className="row align-items-center m-0 p-0">
                                        <div className="col-md-6 col-12 m-0 p-0 text-left touchPadding">

                                            <label className="m-0 p-0 f-14">Leave us a message</label>

                                            <form className="f-12">
                                                <div>
                                                    <TextField className="w-100 mt-3" size="small" id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(e) => {
                                                        setName(e.target.value)
                                                        setError("")
                                                    }} />
                                                </div>
                                                <div>
                                                    <TextField className="w-100 mt-3" size="small" id="outlined-basic" label="Mobile Number" variant="outlined" value={mobile} onChange={(e) => {
                                                        setMobile(e.target.value)
                                                        setError("")
                                                    }} />
                                                </div>
                                                <div>
                                                    <TextField className="w-100 mt-3" size="small" id="outlined-basic" type="email" label="Email Address" variant="outlined" value={email} onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        setError("")
                                                    }} />
                                                </div>
                                                <div>
                                                    <TextField className="w-100 mt-3" size="small" rows={4} id="outlined-basic" label="Your message" variant="outlined" multiline value={message} onChange={(e) => {
                                                        setMessage(e.target.value)
                                                        setError("")
                                                    }} />
                                                </div>
                                                <div className="ml-2 mt-3">
                                                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{error}</p>
                                                </div>
                                                <div className="sendBtn mt-3 curPoint" onClick={(e) => {
                                                    e.preventDefault()
                                                    saveFormDetail()
                                                }}>Send</div>
                                            </form>
                                        </div>
                                        <div className="col-md-6 col-12 m-0 p-0 text-left touchPadding">
                                            <p className="f-12 p-0 mb-3 font-weight-bold">2nd Floor, Room 201, 73A, Ganesh Chandra Ave, Chandni Chawk, Bowbazar, Kolkata, West Bengal 700013</p>
                                            <p className="m-0 p-0 f-12 p-0 mb-3">
                                                <a className="text-dark  font-weight-bold" target={"_self"} href="tel:+91-9038517269">+91-9038517269</a>
                                            </p>
                                            <p className="m-0 p-0 f-12 p-0 mb-3">
                                                <a className="text-dark f-12 p-0 mb-3 font-weight-bold" target={"_self"} href="mailto:info@altiusinvestech.com">info@altiusinvestech.com</a>
                                            </p>
                                            <p className="f-18 p-0 mb-3 font-weight-bold">
                                                <a className="text-dark" target="_blank" href="https://www.instagram.com/altius.investech/"><i className="fab fa-instagram mr-3"></i></a>
                                                <a className="text-dark" target="_blank" href="https://www.facebook.com/altiusinv"><i className="fa fa-facebook-square mr-3" aria-hidden="true"></i></a>
                                                <a className="text-dark" target="_blank" href="https://twitter.com/unlistedshares1"><i className="fa fa-twitter mr-3" aria-hidden="true"></i></a>
                                            </p>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.354160141669!2d88.35634775068922!3d22.565853638832056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277aa3b34a2a9%3A0xdf549f07136fbd45!2sAltius%20Investech%20(Pre%20IPO%2C%20Unlisted%20Shares%2C%20Private%20Equity)!5e0!3m2!1sen!2sin!4v1657218380236!5m2!1sen!2sin" width="100%" height="200" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="hr" />

                        {/* footer--------------------------------------------------------------------- */}
                        <div className="footer">
                            <div className="row m-1 mb-5">
                                <div className="col-md-4 p-0 m-0">
                                    <p className="f-12 p-0 m-0 mb-1 footerHeading">Altius Investech</p>
                                    <p className="f-12 p-0 mt-3 mb-1 font-weight-bold">Social</p>
                                    <p className="f-18 p-0 mb-3 font-weight-bold">
                                        <a className="text-dark" target="_blank" href="https://www.facebook.com/altiusinv"><i className="fa fa-facebook-square mr-2" aria-hidden="true"></i></a>
                                        <a className="text-dark" target="_blank" href="https://twitter.com/unlistedshares1"><i className="fa fa-twitter mr-2" aria-hidden="true"></i></a>
                                        <a className="text-dark" target="_blank" href="https://www.linkedin.com/company/altius-investech/"><i className="fa fa-linkedin-square mr-2" aria-hidden="true"></i></a>
                                        <a className="text-dark" target="_blank" href="https://www.instagram.com/altius.investech/"><i className="fab fa-instagram"></i></a>
                                    </p>
                                    <p className="f-12 p-0 mt-5 mb-1 font-weight-bold">Registered Office</p>
                                    <p className="f-12 p-0 mb-1 lightColorText">2nd Floor, Room 201, 73A, Ganesh Chandra Ave, Chandni Chawk, Bowbazar, Kolkata, West Bengal 700013</p>

                                </div>
                                <div className="col-md-5 col-6 p-0 m-0 footerConv">
                                    <p className="f-12 p-0 m-0 mb-1 footerHeading">Company</p>
                                    <div className="row m-0 p-0">
                                        <div className="col-md-6 col-12 pl-0 lightColorText">
                                            <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/" className="lightColorText">Home</a></p>
                                            <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/about" className="lightColorText">About us</a></p>
                                            <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/works" className="lightColorText">How it works</a></p>
                                            <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/press" className="lightColorText">Press</a></p>
                                        </div>
                                        <div className="col-md-6 p-0 col-12 lightColorText">
                                            <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/blog" className="lightColorText">Blog</a></p>
                                            <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/contact" className="lightColorText">Contact us</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 p-0 m-0 footerConv">
                                    <p className="f-12 p-0 m-0 mb-1 footerHeading">Quick links</p>
                                    <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/a-complete-walkthrough-of-our-trading-portal/" className="lightColorText">A Complete Walkthrough Of Our Trading Portal</a></p>
                                    <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/capital-gain-on-sale-of-unlisted-shares/" className="lightColorText">Capital Gain on Sale of Unlisted Shares</a></p>
                                    <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/how-to-transfer-unlisted-shares/" className="lightColorText">How to transfer unlisted shares?</a></p>
                                    <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/income-tax-on-unlisted-shares/" className="lightColorText">Income Tax on Unlisted Shares</a></p>

                                </div>
                            </div>
                            <p className="f-10 m-0 pb-1 text-center lightColorText">All rights reserved. Copyright © {new Date().getFullYear()} Altius Investech</p>
                        </div>
                    </div>
                </div>
                {token ? <BottomBarPage /> : null}
                <ToastPage />

                
            </div>
        </div >
    )
}
