import React, { useEffect, useState } from "react";
import BottomBarPage from "../../includes/dashboard/BottomBarPage";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import { useCustomerKYCContext } from "../../../services/contexts/CustomerKYCContext";
import { Link, useHistory } from "react-router-dom";
import { computeNet, configs, formatCurrency, formatNumber, showLeftBar } from "../../../assets/Config";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import '../../../assets/styles/dashboard.css';
import ToastPage from '../../includes/ToastPage'
import ClientOrderPage from "../clientData/ClientOrderPage";
import { useClientDataContext } from "../../../services/contexts/ClientDataContext";
import faq1 from '../../../assets/images/faq1.png';
import faq2 from '../../../assets/images/faq2.png';
import WhatsApp from '../../../assets/images/WhatsApp.svg'
import '../../../assets/styles/TopBar.css'
import { Accordion, AccordionDetails, AccordionSummary, Table, Typography, TextField } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons';

export default function DashboardPage() {

    const { kycDetails } = useCustomerKYCContext();
    const { setShowMobBS, setCurrModule, setcurrMod, setShowSuccess, getDashboardData, setDashboardData, dashboardData, activeList, setActiveList, defaultDisplay, setDefaultDisplay, currModule } = useLogin()
    const { setStart, setEnd, setDupTableData, tableData, setRowPerPage, downloadCSV, setActiveDetail } = useClientDataContext()

    const history = useHistory()
    const [faqOpen, setFaqOpen] = useState(false)

    const [expanded, setExpanded] = useState('false');
    const [slideIndex, setSlideIndex] = useState(1);

    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    useEffect(() => {
        setShowMobBS(false)
        setCurrModule(configs.modules.dashboard)
        setcurrMod(configs.modules.dashboard)
        try {
            (async () => {
                setDupTableData([])
                let response = await getDashboardData()
                slideChange(1)
            })()
        } catch (error) {

        }

    }, [])

    function leftBarDisplay(show, current) {
        let response = showLeftBar(show, current, defaultDisplay, activeList, currModule)
        if (!response) {
            setcurrMod(currModule)
            setDefaultDisplay(response)
            // if ((currModule === configs.modules.shares) || (currModule === configs.modules.screenerMobFilter)) {
            //     setShowMobBS(true)
            // }
        } else {
            setcurrMod(configs.modules.mobCom);
            setShowMobBS(false)
        }
    }

    const slideChange = (n) => {
        try {
            var i;
            var x = document.getElementsByClassName("videoDisplay");
            if (n > x.length) { n = 1 }
            if (n < 1) { n = x.length }
            for (i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }

            setSlideIndex(n)
            x[n - 1].style.display = "block";
        } catch (error) {

        }
    }

    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                <div id='dashContainer' className="dash-main" >
                    <div className="row dashTitle">
                        {configs.isWealthManager.toLowerCase() != configs.distributorTypes.wealth_manager.toLowerCase() ? <div className="col-12">
                            <div className="alert alert-warning f-12" role="alert">In order to get access to complete client details please reach out to us on support@altiusinvestech.com</div>
                        </div>
                            : null}
                        {/* <div className="col-12"> <div className="alert alert-warning f-12" role="alert">Download content to share with your clients. <a href="https://drive.google.com/drive/u/1/folders/1c-71X5Ly0fCEKC15pcbB4dstUqQN_4KU" target={"_blank"}>View more</a></div></div> */}
                        <div className="col-12">
                            Dashboard
                        </div>
                    </div>
                    {/* dashboard steppers--------------------------------------*/}
                    {dashboardData && dashboardData.stepper && (dashboardData.stepper.signup !== 1 || dashboardData.stepper.create_invite_client_link !== 1 || dashboardData.stepper.invite_client !== 1 || dashboardData.stepper.start_earning !== 1) ?
                        <div className="row mx-3 mt-1 mb-4">
                            <div className="col-lg-3 col-12 m-0 p-0">
                                <div>
                                    <div className="row m-0 p-0 align-items-center">
                                        <div className="row col-lg-12 col-2 m-0 p-0 align-items-md-center">
                                            <div className="col-lg-2 col-12 m-0 p-0">
                                                <div className={`stepPoint ${dashboardData && dashboardData.stepper && dashboardData.stepper.signup === 1 ? "stepPointDone" : ""}`}>
                                                    {dashboardData && dashboardData.stepper && dashboardData.stepper.signup === 1 ? <i className="fa fa-check" aria-hidden="true"></i> : ""}
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12 m-0 p-0 stepPointParent">
                                                <div className={`stepPointLine ${dashboardData && dashboardData.stepper && dashboardData.stepper.signup === 1 ? "stepPointLineActive" : ""}`}></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-10 order-lg-last m-0 p-0">
                                            <div className="stepData">
                                                <p className="m-0 p-0 font-weight-bold mb-3">Sign up</p>
                                                <p className="m-0 p-0">You are now a part of Altius’ Partner program</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3 col-12 m-0 p-0">
                                <div>
                                    <div className="row m-0 p-0 align-items-center">
                                        <div className="row col-lg-12 col-2 m-0 p-0 align-items-md-center">
                                            <div className="col-lg-2 col-12 m-0 p-0">
                                                <div className={`stepPoint ${dashboardData && dashboardData.stepper && dashboardData.stepper.create_invite_client_link === 1 ? "stepPointDone" : dashboardData.stepper.signup === 1 ? "stepPointActive" : ""}`}>
                                                    {dashboardData && dashboardData.stepper && dashboardData.stepper.create_invite_client_link === 1 ? <i className="fa fa-check" aria-hidden="true"></i> : dashboardData.stepper.signup === 1 ? <i className="fa fa-circle text-primary" aria-hidden="true"></i> : <i className="fa fa-lock" aria-hidden="true"></i>}
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12 m-0 p-0 stepPointParent">
                                                <div className={`stepPointLine ${dashboardData && dashboardData.stepper && dashboardData.stepper.create_invite_client_link === 1 ? "stepPointLineActive" : ""}`}></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-10 order-lg-last m-0 p-0">
                                            <div className="stepData">
                                                <p className="m-0 p-0 font-weight-bold mb-3">Create Client Invite Link</p>
                                                <p className="m-0 p-0">Create your unique invite link to invite clients to Altius’ client Portal</p>
                                            </div>
                                            {dashboardData && dashboardData.stepper && dashboardData.stepper.signup === 1 && dashboardData.stepper.create_invite_client_link !== 1 ?
                                                <div className="ml-2 mr-4">
                                                    <div className="leftBar-CopyLink curPoint mx-2 f-12 d-md-none d-flex" onClick={(e) => {
                                                        e.preventDefault()
                                                        setDefaultDisplay(true)
                                                        leftBarDisplay(true, configs.list.all)
                                                    }}>
                                                        Create Link
                                                    </div>

                                                    <div className="leftBar-CopyLink curPoint mx-2 f-12 d-md-flex d-none" onClick={(e) => {
                                                        e.preventDefault()
                                                        try {
                                                            let test = 0;
                                                            let interval = setInterval(() => {
                                                                test++;
                                                                document.getElementById("createLink").classList.toggle("highLight")
                                                                if (test === 6) {
                                                                    clearInterval(interval)
                                                                }
                                                            }, 1000)
                                                        } catch (error) {

                                                        }
                                                    }}>
                                                        Create Link
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3 col-12 m-0 p-0">
                                <div>
                                    <div className="row m-0 p-0 align-items-center">
                                        <div className="row col-lg-12 col-2 m-0 p-0 align-items-md-center">
                                            <div className="col-lg-2 col-12 m-0 p-0">
                                                <div className={`stepPoint ${dashboardData && dashboardData.stepper && dashboardData.stepper.invite_client === 1 ? "stepPointDone" : dashboardData.stepper.create_invite_client_link === 1 ? "stepPointActive" : ""}`}>
                                                    {dashboardData && dashboardData.stepper && dashboardData.stepper.invite_client === 1 ? <i className="fa fa-check" aria-hidden="true"></i> : dashboardData.stepper.create_invite_client_link === 1 ? <i className="fa fa-circle text-primary" aria-hidden="true"></i> : <i className="fa fa-lock" aria-hidden="true"></i>}
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12 m-0 p-0 stepPointParent">
                                                <div className={`stepPointLine ${dashboardData && dashboardData.stepper && dashboardData.stepper.invite_client === 1 ? "stepPointLineActive" : ""}`}></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-10 order-lg-last m-0 p-0">
                                            <div className="stepData">
                                                <p className="m-0 p-0 font-weight-bold mb-3">Invite Clients</p>
                                                <p className="m-0 p-0">Share your invite link with your clients and onboard clients on Altius’ client Portal</p>
                                            </div>
                                            {dashboardData && dashboardData.stepper && dashboardData.stepper.create_invite_client_link === 1 && dashboardData.stepper.invite_client !== 1 ?
                                                <div className="ml-2 mr-4">
                                                    <div className="leftBar-CopyLink curPoint mx-2 f-12 d-md-none d-flex" onClick={(e) => {
                                                        e.preventDefault()
                                                        setDefaultDisplay(true)
                                                        leftBarDisplay(true, configs.list.all)
                                                    }}>
                                                        Invite Clients
                                                    </div>

                                                    <div className="leftBar-CopyLink curPoint mx-2 f-12 d-md-flex d-none" onClick={(e) => {
                                                        e.preventDefault()
                                                        try {
                                                            let test = 0;
                                                            let interval = setInterval(() => {
                                                                test++;
                                                                document.getElementById("createLink").classList.toggle("highLight")
                                                                if (test === 6) {
                                                                    clearInterval(interval)
                                                                }
                                                            }, 1000)
                                                        } catch (error) {

                                                        }
                                                    }}>
                                                        Invite Clients
                                                    </div>
                                                </div>
                                                : null}
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3 col-12 m-0 p-0">
                                <div>
                                    <div className="row m-0 p-0 align-items-lg-center">
                                        <div className="row col-lg-12 col-2 m-0 p-0 align-items-lg-center">
                                            <div className="col-lg-2 col-12 m-0 p-0">
                                                <div className={`stepPoint ${dashboardData && dashboardData.stepper && dashboardData.stepper.start_earning === 1 ? "stepPointDone" : dashboardData.stepper.invite_client === 1 ? "stepPointActive" : ""}`}>
                                                    {dashboardData && dashboardData.stepper && dashboardData.stepper.start_earning === 1 ? <i className="fa fa-check" aria-hidden="true"></i> : dashboardData.stepper.invite_client === 1 ? <i className="fa fa-circle text-primary" aria-hidden="true"></i> : <i className="fa fa-lock" aria-hidden="true"></i>}
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12 m-0 p-0 stepPointParent">
                                                <div className=""></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-10 order-lg-last m-0 p-0">
                                            <div className="stepData">
                                                <p className="m-0 p-0 font-weight-bold mb-3">Start Earning</p>
                                                <p className="m-0 p-0">Track your commissions once at least one of your clients completes an investment with Altius</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        : null}
                    {/* dashboard high level numbers--------------------------------------*/}
                    <div className="row mx-2 my-1">
                        <div className="col-md-4 col-6 m-0 p-0">
                            <div className="dashCard">
                                <div>
                                    <div className="row m-0 align-items-top">
                                        <div className="col-10 p-0 pb-5">
                                            <p className="m-0">Clients Signed up | transacted</p>
                                        </div>
                                        <div className="col-2 p-0" style={{ textAlign: "right" }}>
                                            <i className='fas fa-eye curPoint' onClick={(e) => {
                                                e.preventDefault();
                                                setActiveDetail("All Clients")
                                                history.push('/clients')
                                            }}></i>
                                        </div>
                                    </div>
                                    <div className="dashCardNumber">
                                        <p className="m-0 mb-2">{dashboardData.all_clients} | {dashboardData.client_with_atleast_one_order_transacted}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 m-0 p-0">
                            <div className="dashCard">
                                <div>
                                    <div className="row m-0 align-items-top">
                                        <div className="col-10 p-0 pb-5">
                                            <p className="m-0">Commissions earned | Wallet balance</p>
                                        </div>
                                        <div className="col-2 p-0" style={{ textAlign: "right" }}>
                                            <i className='fas fa-eye curPoint' onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/commission')
                                            }}></i>
                                        </div>
                                    </div>
                                    <div className="dashCardNumber text-wrap">
                                        {configs.commissionModel.toLowerCase() != "custom" ? <p className="m-0 mb-2">{formatCurrency(parseFloat(dashboardData && dashboardData.commission ? dashboardData.commission.total_commission : 0))} | {formatCurrency(parseFloat(dashboardData && dashboardData.commission ? dashboardData.commission.available_balance : 0))} </p> : <a href="#" className="f-12" onClick={(e) => {
                                            e.preventDefault()
                                            downloadCSV("Commission Ledger")
                                        }}><p className="m-0 mb-2">
                                                Download Commission Ledger
                                            </p></a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 m-0 p-0 margin">
                            <div className="dashCard">
                                <div>
                                    <div className="row m-0 align-items-top">
                                        <div className="col-10 p-0 pb-5">
                                            <p className="m-0">Altius Pro Subscriptions</p>
                                        </div>
                                        <div className="col-2 p-0" style={{ textAlign: "right" }}>
                                            <i className='fas fa-eye curPoint' onClick={(e) => {
                                                e.preventDefault();
                                                setActiveDetail("Pro Orders")
                                                history.push('/clients')
                                            }}></i>
                                        </div>
                                    </div>
                                    <div className="dashCardNumber">
                                        <p className="m-0 mb-2">{dashboardData.pro_clients}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* dashboard customer help data --------------------------------------*/}
                    <div className="row mx-2 my-4">
                        {dashboardData && dashboardData.youtube && dashboardData.youtube.length > 0 ?
                            <div className="col-md-6 col-12 m-0 p-0">
                                <div className="dashCard">
                                    <p className="m-0 p-0 font-weight-bold f-18">For You</p>
                                    <div className="mt-2">
                                        {dashboardData.youtube.map((element, key) => {
                                            return (
                                                <iframe key={`video${key}`} className="videoDisplay"
                                                    src={element.value} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                                </iframe>
                                            )
                                        })
                                        }

                                        <div className="row m-0 p-0">
                                            <div className="curPoint videoSliderBtn" onClick={(e) => {
                                                e.preventDefault()
                                                slideChange(slideIndex - 1)
                                            }}>&#10094;</div>
                                            <div className="curPoint videoSliderBtnRt" onClick={(e) => {
                                                e.preventDefault()
                                                slideChange(slideIndex + 1)
                                            }}>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        {dashboardData && dashboardData.clients && dashboardData.clients.length > 0 ?
                            <div className="col-md-6 col-12 m-0 p-0">
                                <div className="dashCard">
                                    <div>
                                        <p className="m-0 p-0 font-weight-bold f-18">For Your Clients</p>
                                    </div>
                                    <div className="d-flex flex-column mt-3 ml-3">

                                        {dashboardData.clients.map((element, key) => {
                                            return (
                                                <div className="m-2 test" key={`clientData` + key} >
                                                    <a href={element.value} target="_blank"> <p className="m-0 textWrap f-14" > {element.description}</p></a>
                                                </div>

                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>

                    {/* dashboard table selector--------------------------------------*/}

                    {/* dashboard table--------------------------------------*/}
                    <div className="row mx-2 my-4">
                        <div className="col-12 m-0 p-0">
                            <div className="dashCard">
                                <div className="col-8 col-md-6 m-0 p-0 recentActivity" >
                                    <p className="m-0 p-0 f-14 font-weight-bold">Recent Activity</p>
                                </div>
                                <div className="row m-0">
                                    <div className="analyitcsSelector-active">
                                        <p className="m-0 p-0">Client Orders</p>
                                    </div>
                                </div>
                                <ClientOrderPage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomBarPage />
            <ToastPage />
            {/* //     </div> */}
            {/* ---------------------------------------------FAQ DIV------------------------------------- */}
            <div className="faqOpen curPoint" onClick={(e) => {
                e.preventDefault()
                document.getElementById("faq").classList.toggle("d-none")
                document.getElementById("arr").classList.toggle("d-none")
                setFaqOpen(!faqOpen)
            }}>
                {faqOpen ? <i className="fa fa-times" aria-hidden="true"></i>
                    : <i className="fas fa-question"></i>}
            </div>
            <div id="arr" className="arrow-down d-none"></div>
            <div id="faq" className="faqMain d-none">
                <div className="faqWA">
                    <img src={WhatsApp} className="faqWAImg" />
                    <p className="m-0 p-0 distDetailBannerTitle text-center">WhatsApp us to get your queries resolved</p>
                    <a href="https://api.whatsapp.com/send/?phone=+918240614850&amp;text=hey!" target="_blank"> <p className="m-0 p-0 text-center font-weight-bold text-dark"><u>Let's Chat <i className="fa fa-arrow-up" aria-hidden="true" style={{ transform: "rotate(45deg)" }}></i></u></p></a>
                    <p className="m-0 p-0 f-14 text-muted">Will reply in 2hrs. Mon-Sat 10AM to 7PM</p>
                </div>
                <div className="faqSet">
                    <div>
                        <p className="m-0 mb-3 p-0 distDetailBannerTitle text-center">Most Frequently Asked Questions</p>
                    </div>
                    <div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How to onboard a client?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    You can create your custom invite link for your clients.<br />
                                    Your invite link looks like this:<br />
                                    <span className="text-muted">https://trade.altiusinvestech.com/signup?utm=</span><span className="font-weight-bold">your_unique_name<br />
                                        Note:</span> Once the link is created, it cannot be altered.
                                    <br /><br />
                                    <img src={faq1} className="w-50" /><br /><br />
                                    You can use this link to invite your clients to Altius’ trade portal, where your clients can buy and sell Unlisted shares without any hassle.<br /><br />
                                    When a client signs up using your unique invite link, <u>the client is tagged to your account</u>. A list of your clients will be visible under Clients &gt; All Clients tab.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How do I track my earnings?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>You can monitor your total commissions and wallet balance on your partner dashboard
                                    <br /><br />
                                    <img src={faq2} className="w-100" /><br /><br />
                                    You can navigate to the Commissions tab to track your earnings and Payouts for a detailed ledger.</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How much can I earn?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Your earning potential depends on the business generated by the clients onboarded by you on Altius</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Can I do this alongside my job in any of the sectors?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>This is your own business, you can start it along with your job. If you work in the financial sector then it is easier for you to cross-sell pre-IPO products to your current clients.</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How do I start with Altius?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>It’s simple, just complete your KYC. Pitch Pre-IPO to your clients and get them to sign up on our client portal. Once they signup, pitch them options to invest in.<br /><br />(On every investment your client makes, you will be rewarded a commission)</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>What all products are available to sell?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Currently, we have 100+ Pre-IPO companies on our portal. </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>I am new to this, will you provide me with any training?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>We would be organising regular online sessions to educate our partners and to introduce new products. You can also contact us directly if you need any help or some additional support.</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How will I receive my earnings?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Payouts will be made on a request basis. When you put forward a request for a payout, our team will get in touch to confirm the request and process your payout. Once the payout is completed, it will be updated in your commissions ledger.<br /><br /><b>Note:</b> You can withdraw your first payout only if at least three of your clients have completed a transaction, and the Payout should be greater than {formatCurrency(parseFloat(5000))}.</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How do I get leads?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Lead generation is your responsibility. If needed our team will help you with the material required to pitch products to your leads.</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div >

    )
}