import axios from 'axios';
import { configs } from '../../assets/Config';

// create password api
export function createpassword(password, cnfPass, token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "token": token,
                "password": password,
                "confirm_password": cnfPass
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/registration/saveUserPassword`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// reset password api
export function resetpassword(password, cnfPass, token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "token": token,
                "password": password,
                "confirm_password": cnfPass
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/forgotPassword/setNewPassoword`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}