import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, formatCurrency, showLeftBar } from '../../../assets/Config';
import '../../../assets/styles/LeftBar.css'
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import SuccessModalPage from '../../includes/SuccessModalPage';

import referralLink from '../../../assets/images/referralLink.svg';
import MailNew from '../../../assets/images/MailNew.svg';
import Transacted from '../../../assets/images/Transacted.svg';
import Twitter from '../../../assets/images/Twitter.svg';
import WhatsApp from '../../../assets/images/WhatsApp.svg';
import createUTM from '../../../assets/images/createUTM.svg';


export default function LeftBarPage() {

    const { setDashboardData, dashboardData, getDashboardData, generateUTM, setShowSuccess } = useLogin();

    const [linkCopied, setLinkCopied] = useState(false)
    const [genUTM, setGenUTM] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        try {
            (async () => {
                let response = await getDashboardData()
            })()
        } catch (error) {

        }

    }, [])


    async function generateUTMLink() {
        try {
            if (!/^[a-zA-Z0-9]{3,20}$/.test(genUTM)) {
                setError("Please enter a valid UTM value. ")
            } else {
                let response = await generateUTM(genUTM)
                if (response && response.success) {
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <div id="leftBar" className="leftbar-container d-md-flex d-none">
            <div className='leftBar-cont'>
                <div className='row m-0 p-0 leftBar-header align-items-center'>
                    <div className='col-10 m-0 p-0'>
                        <p className='m-0 p-0 leftBar-heading'>Client Invite Link</p>
                    </div>
                    <div className='col-2 m-0 p-0 text-center'>
                        <img className='leftBar-header-icon' src={referralLink} />
                    </div>
                </div>

                <div id="createLink" className='leftbar-content'>
                    {dashboardData && dashboardData.utm ?
                        <>
                            <div className='row m-0 p-0 text-align-center font-weight-bold'>
                                <div className='col-6 m-0 p-0 leftBar-values'>
                                    <div className='leftBar-icon-bg'>
                                        <img className='leftBar-icon' src={MailNew} alt="altius investech signup" />
                                    </div>
                                    <p className='m-0 p-0 f-12'>Total Signups</p>
                                    {dashboardData ? <p className='m-0 p-0 f-12'>{dashboardData.all_clients}</p> : ""}
                                </div>
                                <div className='col-6 m-0 p-0 leftBar-values'>
                                    <div className='leftBar-icon-bg'>
                                        <img className='leftBar-icon' src={Transacted} alt="altius investech transacted" />
                                    </div>
                                    <p className='m-0 p-0 f-12'>Transacted</p>
                                    {dashboardData ? <p className='m-0 p-0 f-12'>{dashboardData.client_with_atleast_one_order_transacted}</p> : ""}
                                </div>
                            </div>
                            <div className='leftBar-text'>
                                <p className='m-0 p-0 leftBar-heading'>CLIENT ONBOARDING STATUS</p>
                            </div>

                            <div className='leftBar-link'>
                                <p className='m-0 p-0 f-10'>https://trade.altiusinvestech.com/signup?utm={dashboardData.utm}</p>
                            </div>


                            <div className={`${linkCopied ? "leftBar-CopiedLink" : "leftBar-CopyLink curPoint"}`} onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(`https://trade.altiusinvestech.com/signup?utm=${dashboardData.utm}`);
                                setLinkCopied(true)
                                setTimeout(() => {
                                    setLinkCopied(false)
                                }, 2000)
                            }}>
                                {linkCopied ? <p className='m-0 p-0 f-12'>Invite Link Copied</p> : <p className='m-0 p-0 f-12'>Copy Invite Link</p>}
                            </div>
                            <div className='leftBar-share'>
                                <p className='f-12 p-0'>Share On</p>
                                <div className='row m-0 p-0 align-items-center'>
                                    <div className='col-4 m-0 p-0 curPoint' onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`mailto:?subject=Pre-IPO investment invite&body=${configs.distributorMessage}${dashboardData.utm}`, "_blank");
                                    }}>
                                        <img className='leftBar-icon' src={MailNew} alt="altius investech mail" />
                                    </div>
                                    <div className='col-4 m-0 p-0 curPoint' onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`https://twitter.com/intent/tweet?text=${configs.distributorMessage}${dashboardData.utm}`, "_blank");
                                    }}>
                                        <img className='leftBar-icon' src={Twitter} alt="altius investech twitter" />
                                    </div>
                                    <div className='col-4 m-0 p-0 curPoint' onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`https://wa.me?text=${configs.distributorMessage}${dashboardData.utm}`, "_blank");
                                    }}>
                                        <img className='leftBar-icon' src={WhatsApp} alt="altius investech WhatsApp" />
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <img className='w-100' src={createUTM} alt="altius investech" />

                            <div className='leftBar-text'>
                                <p className='m-0 p-0 leftBar-heading text-left'>Create Client Invite Link:</p>
                            </div>
                            <div className="form-group mb-2">
                                <p className="m-0 my-3 p-0 f-10 text-left">Your invite link will look like:<br />
                                    <b>https://trade.altiusinvestech.com/signup?utm=unique_name<br />
                                        Note:</b> Once the link is created it cannot be altered.</p>
                                <input type="text" className="form-control login-screen-input f-14" name="utm" placeholder="Enter Unique Name" onChange={(e) => {
                                    setError("")
                                    setGenUTM(e.target.value)
                                }} />
                            </div>
                            <div className="ml-2 text-left">
                                <p className="f-12 m-0 p-0 text-danger font-weight-bold">{error}</p>
                            </div>
                            <div className={`leftBar-CopyLink curPoint`} onClick={(e) => {
                                e.preventDefault();
                                generateUTMLink()
                            }}>
                                Generate Link
                            </div>
                        </>
                    }
                </div>
            </div>
            {dashboardData && dashboardData.latest_update && dashboardData.latest_update.length > 0 ?
                <div className='leftBar-cont mt-3'>
                    <p className="m-0 p-0 ml-2 mt-2 font-weight-bold leftBar-heading">Latest Updates</p>
                    <ul className='f-12 pl-4 mt-2'>
                        {dashboardData.latest_update.map((element, key) => {
                            return (
                                <li key={`update${key}`}>
                                    <a href={element.value} target="_blank"> <p className="m-0 f-14" > {element.description}</p></a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                : null}
            <SuccessModalPage />
        </div >
    )
}