import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { animation, assistedUserToken, configs, formatCurrency, formatNumber } from "../../../assets/Config";
import '../../../assets/styles/Analytics.css';
import { useAUAssetMgmtContext } from "../../../services/contexts/assistedUser/assets/AUAssetMgmtContext";
import { useAURegister_KYCContext } from "../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext";
import { useClientDataContext } from "../../../services/contexts/ClientDataContext";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import AUVerifyOTPMobPage from '../assistedUser/kyc/AUVerifyOTPMobPage';
import { kycModifyAccessToken } from "../../../services/apis/assistedUser/registration_kyc/AUKYCApi";

export default function AllClientsPage() {

    const { tableData, dupTableData, setDupTableData, clientData, column, setColumn, order, setOrder, start, end } = useClientDataContext();
    const { impersonating, setImpersonating, proxyEmail, setProxyEmail, token, logout } = useLogin()
    const { payKYCDetail, setPayKYCDetail } = useAUAssetMgmtContext()
    const { getKYCSavedDetailContext, kycDetails, setOtpModal, setMobOtpToken, setKycRequesterUserDetail } = useAURegister_KYCContext()

    const history = useHistory()

    useEffect(() => {
        try {
            (async () => {
                await clientData()
            })()
        } catch (error) {
            console.error(error)
        }
    }, [])

    // sort data
    useEffect(() => {
        try {
            let data = dupTableData
            if (order === "desc") {
                data = typeof dupTableData[0][column] === "string" ? [...dupTableData].sort((a, b) => a[column] > b[column] ? -1 : 1) : [...dupTableData].sort((a, b) => b[column] - a[column]);
            } else if (order === "asc") {
                data = typeof dupTableData[0][column] === "string" ? [...dupTableData].sort((a, b) => a[column] > b[column] ? 1 : -1) : [...dupTableData].sort((a, b) => a[column] - b[column]);
            }
            setDupTableData(data)
        } catch (error) {
            console.error(error)
        }

    }, [order, column])

    async function modifyKYC(email) {
        try {
            let response = await getKYCSavedDetailContext(email)
            if (response && response.success && response.data && response.data.kyc_modification_access) {
                history.push("/user/kyc")
                setImpersonating(true)
                setProxyEmail(email)
            } else if (response && response.success) {
                response = await kycModifyAccessToken(assistedUserToken(token, email))
                if (response && response.status === 200) {
                    setOtpModal(true)
                    setMobOtpToken(response.data.token)
                } else if (response && response.status === 401) {
                    logout();
                } else {
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div id="analytics">
            {dupTableData && dupTableData.length > 0 ?
                <div>
                    <div className="d-md-block d-none">
                        <div className="table-scroll">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '30%' }}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Name</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("name")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("name")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Investment</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "total_investment" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("total_investment")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "total_investment" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("total_investment")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                            : null}
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <th scope="col">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>Profit/Loss</div>
                                                    <div className="d-flex flex-column mx-2">
                                                        <i className={`fa fa-caret-up curPoint ${(column === "profit_and_loss" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("asc")
                                                            setColumn("profit_and_loss")
                                                        }}></i>
                                                        <i className={`fa fa-caret-down curPoint ${(column === "profit_and_loss" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("desc")
                                                            setColumn("profit_and_loss")
                                                        }}></i>
                                                    </div>
                                                </div>
                                            </th>
                                            : null}
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <th scope="col">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>Portfolio</div>
                                                    <div className="d-flex flex-column mx-2">
                                                        <i className={`fa fa-caret-up curPoint ${(column === "current_value" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("asc")
                                                            setColumn("current_value")
                                                        }}></i>
                                                        <i className={`fa fa-caret-down curPoint ${(column === "current_value" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("desc")
                                                            setColumn("current_value")
                                                        }}></i>
                                                    </div>
                                                </div>
                                            </th>
                                            : null}
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <th scope="col">
                                                <div className='d-flex justify-content-between align-items-center text-capitalize'>
                                                    <div>KYC Status</div>
                                                    <div className="d-flex flex-column mx-2">
                                                        <i className={`fa fa-caret-up curPoint ${(column === "status" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("asc")
                                                            setColumn("status")
                                                        }}></i>
                                                        <i className={`fa fa-caret-down curPoint ${(column === "status" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("desc")
                                                            setColumn("status")
                                                        }}></i>
                                                    </div>
                                                </div>
                                            </th>
                                            :
                                            <th scope="col">
                                                <div className='d-flex justify-content-between align-items-center text-capitalize'>
                                                    <div>Signup Status</div>
                                                    <div className="d-flex flex-column mx-2">
                                                        <i className={`fa fa-caret-up curPoint ${(column === "transaction_status" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("asc")
                                                            setColumn("transaction_status")
                                                        }}></i>
                                                        <i className={`fa fa-caret-down curPoint ${(column === "transaction_status" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("desc")
                                                            setColumn("transaction_status")
                                                        }}></i>
                                                    </div>
                                                </div>
                                            </th>
                                        }
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <th scope="col" className="clientRemarkWidth">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>KYC Remark</div>
                                                </div>
                                            </th>
                                            : null}
                                        {configs.isWealthManager.toLowerCase() !== configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <th scope="col" className="emptyColWidth"></th>
                                            : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dupTableData.map((element, key) => {
                                        if (key >= start && key < end) {
                                            return (
                                                <tr key={`clientDetail` + key}>
                                                    <td className="rightBorder">
                                                        <div className="tableBreakWord">
                                                            <p className="m-0 p-0 f-12 font-weight-bold">{element.name}</p>
                                                            <p className="m-0 p-0 f-12 curPoint text-primary" onClick={(e) => {
                                                                e.preventDefault();
                                                                setProxyEmail(element.email);
                                                                setImpersonating(true);
                                                                setKycRequesterUserDetail({
                                                                    name: element.name,
                                                                    email: element.email,
                                                                    id: element.id
                                                                })
                                                                history.push(`/user/profile`)
                                                            }}>{element.email}</p>
                                                            <p className="m-0 p-0 f-12" >+{element.mobile}</p>
                                                            {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                                                <p className="m-0 p-0 f-12 text-primary curPoint" onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setProxyEmail(element.email)
                                                                    setImpersonating(true)
                                                                    history.push("/user/assets")
                                                                    setPayKYCDetail(element)
                                                                }}>Manage Assets</p>
                                                                : null}
                                                        </div>
                                                    </td>
                                                    {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? <td className="rightBorder text-right">{formatCurrency(parseFloat(element.total_investment ? element.total_investment : 0))}</td> : null}
                                                    {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? <td className={`rightBorder text-right ${element.profit_and_loss > 0 ? "text-success" : "text-danger"}`}>{formatCurrency(parseFloat(element.profit_and_loss ? element.profit_and_loss : 0))}</td> : null}
                                                    {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? <td className="rightBorder text-right">{formatCurrency(parseFloat(element.current_value ? element.current_value : 0))}</td> : null}
                                                    {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? <td className="rightBorder text-capitalize">{element.status} {element.status && element.status.toLowerCase() !== "verified" ?
                                                        <i className="far fa-edit ml-2 curPoint text-primary" aria-hidden="true" onClick={(e) => {
                                                            e.preventDefault();
                                                            setProxyEmail(element.email)
                                                            modifyKYC(element.email)
                                                        }}></i> : null}</td> : <td className="rightBorder text-capitalize">
                                                        <div className={`${element.transaction_status && element.transaction_status.toLowerCase().includes("trans") ? "refDataRefActive" : "refDataRefPending"}`}>{element.transaction_status} </div></td>}
                                                    {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? <td>{element.remark}</td> : null}
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-md-none">
                        {dupTableData.map((element, key) => {
                            if (key >= start && key < end) {
                                return (
                                    <div className="orderDetailCard" key={`clientDetail` + key}>
                                        <div className="row m-0 mb-2 p-0">
                                            <div className="col-9 m-0 p-0 pr-3">
                                                <p className="m-0 p-0 f-12 font-weight-bold" ><b>{element.name}</b></p>
                                                <p className="m-0 p-0 f-12 curPoint text-primary" onClick={(e) => {
                                                    e.preventDefault();
                                                    setProxyEmail(element.email);
                                                    setImpersonating(true);
                                                    setKycRequesterUserDetail({
                                                        name: element.name,
                                                        email: element.email,
                                                        id: element.id
                                                    })
                                                    history.push(`/user/profile`)
                                                }} >{element.email}</p>
                                                <p className="m-0 p-0 f-12" >+{element.mobile}</p>
                                                {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                                    <p className="m-0 p-0 f-12 text-primary curPoint" onClick={(e) => {
                                                        e.preventDefault()
                                                        setProxyEmail(element.email)
                                                        setImpersonating(true)
                                                        history.push("/user/assets")
                                                        setPayKYCDetail(element)
                                                    }}>Manage Assets</p>
                                                    : null}
                                            </div>
                                            {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                                <div className="col-3 m-0 p-0 text-right">
                                                    <p className={`m-0 p-0`}>Profit/loss: <b><span className={`${element.profit_and_loss > 0 ? "text-success" : "text-danger"}`}>{formatCurrency(parseFloat(element.profit_and_loss ? element.profit_and_loss : 0))}</span></b></p>
                                                </div>
                                                : null}
                                        </div>
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <div className="row m-0 mt-3 p-0 align-items-center">
                                                <div className="col-6 m-0 p-0">
                                                    <p className="m-0 p-0">Investment: <b>{formatCurrency(parseFloat(element.total_investment ? element.total_investment : 0))}</b></p>
                                                </div>
                                                <div className="col-6 m-0 p-0 text-right">
                                                    <p className="m-0 p-0">Portfolio: <b>{formatCurrency(parseFloat(element.current_value ? element.current_value : 0))}</b></p>
                                                </div>
                                            </div>
                                            : null}
                                        <div className="row m-0 mt-3 p-0 align-items-center">
                                            <div className="text-capitalize">
                                                {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                                    <p className="m-0 p-0">KYC Status: <b>{element.status}</b> {element.status && element.status.toLowerCase() !== "verified" ?
                                                        <i className="far fa-edit ml-2 defaultFont curPoint text-primary" aria-hidden="true" onClick={(e) => {
                                                            e.preventDefault();
                                                            setProxyEmail(element.email)
                                                            modifyKYC(element.email)
                                                        }}></i> : null} </p>
                                                    :
                                                    <p className="m-0 p-0">Signup Status: <span className={`ml-2 ${element.transaction_status && element.transaction_status.toLowerCase().includes("trans") ? "refDataRefActive" : "refDataRefPending"}`}>{element.transaction_status}</span><b></b></p>
                                                }
                                            </div>
                                        </div>
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                                            <div className="row m-0 mt-3 p-0 align-items-center">
                                                <div>
                                                    <p className="m-0 p-0">KYC Remark: <b>{element.remark}</b></p>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                : <p className="m-0 mt-2 p-0">No data found</p>}
            <AUVerifyOTPMobPage {...{ verifyAccessToken: true }} />
        </div>
    )
}