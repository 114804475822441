import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { assistedUserToken, configs } from '../../../../assets/Config';
import { getCustomerDocuments, getKYCSavedDetail } from '../../../apis/assistedUser/registration_kyc/AUKYCApi';
import { saveBasicDetail, saveBasicDetailResendOTP, saveBasicDetailVerifyOTP } from '../../../apis/assistedUser/registration_kyc/AURegistrationApi';
import { useLogin } from '../../CustomerLoginContext';

// create global context
export const AURegister_KYCContext = createContext();

export function AURegister_KYCProvider({ children }) {

    const { token, proxyEmail, logout } = useLogin()

    const [showRegisterModal, setShowRegisterModal] = useState(false)
    const [otpModal, setOtpModal] = useState(false)
    const [mobOtpToken, setMobOtpToken] = useState("")
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(2);
    const [editPage, setEditPage] = useState(false);
    const [kycDetails, setKycDetails] = useState(null);
    const [successModal, setsuccessModal] = useState(false);
    const [kycRequesterUserDetail, setKycRequesterUserDetail] = useState("")

    // register assisted user
    async function registerUser(name, email, country_code, mobile) {
        try {
            let response = await saveBasicDetail(assistedUserToken(token), name, email, country_code, mobile)
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success,
                    data: response.data.token
                }
            } else if (response && response.status === 401) {
                logout();
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error);
        }
    }

    // verify register assisted user otp
    async function registerUserOTPVerification(verifyToken, otp) {
        try {
            let response = await saveBasicDetailVerifyOTP(assistedUserToken(token, proxyEmail), verifyToken, otp)
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success
                }
            } else if (response && response.status === 401) {
                logout();
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error);
        }
    }

    // verify register assisted user otp
    async function registerUserResendOTP(verifyToken) {
        try {
            let response = await saveBasicDetailResendOTP(assistedUserToken(token, proxyEmail), verifyToken)
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success,
                    data: response.data.message
                }
            } else if (response && response.status === 401) {
                logout();
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error);
        }
    }

    // get customer kyc saved detail
    async function getKYCSavedDetailContext(email) {

        try {
            let response = await getKYCSavedDetail(assistedUserToken(token, email ? email : proxyEmail));
            if (response && response.status === 200) {
                setKycDetails(response.data);
                if (document.getElementById("sidebarCompanyTableContainer")) {
                    document.getElementById("sidebarCompanyTableContainer").style.height = document.getElementById("bottomContainer").offsetTop - (document.getElementById("sidebarCompanyTableContainer").offsetTop + document.getElementById("bottomContainer").offsetHeight) + "px";
                }
                // if (!change && response.data.status !== configs.statusValues.initial) {
                setCurrPage(5);
                setNextPage(5);
                // }
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
        } catch (error) {

        }
    }

    // get customer uploaded documents
    async function getPanProof() {
        try {
            let response = await getCustomerDocuments(assistedUserToken(token, proxyEmail), 'pan_card');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "PanProof.pdf"
                    : response.data.includes("image/png") ? "PanProof.png"
                        : "PanProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getAddressProof() {
        try {
            let response = await getCustomerDocuments(assistedUserToken(token, proxyEmail), 'address_proof');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "AddressProof.pdf"
                    : response.data.includes("image/png") ? "AddressProof.png"
                        : "AddressProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getBankProof() {
        try {
            let response = await getCustomerDocuments(assistedUserToken(token, proxyEmail), 'bank_proof');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "BankProof.pdf"
                    : response.data.includes("image/png") ? "BankProof.png"
                        : "BankProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getDematProof() {
        try {
            let response = await getCustomerDocuments(assistedUserToken(token, proxyEmail), 'demat_proof');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "DematProof.pdf"
                    : response.data.includes("image/png") ? "DematProof.png"
                        : "DematProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }

    async function getOtherProof() {
        try {
            let response = await getCustomerDocuments(assistedUserToken(token, proxyEmail), 'other_document');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "OtherDocument.pdf"
                    : response.data.includes("image/png") ? "OtherDocument.png"
                        : "OtherDocument.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }

    // customer kyc switch page
    async function custKYCSwitchPage(currPage) {
        setCurrPage(currPage);
        currPage < 5 ? setNextPage(currPage + 1) : setNextPage(5);
    }

    return (
        <AURegister_KYCContext.Provider
            value={{
                showRegisterModal,
                setShowRegisterModal,
                registerUser,
                registerUserOTPVerification,
                registerUserResendOTP,
                custKYCSwitchPage,
                currPage,
                nextPage,
                kycDetails,
                setKycDetails,
                getKYCSavedDetailContext,
                editPage,
                setEditPage,
                otpModal,
                setOtpModal,
                successModal,
                setsuccessModal,
                getPanProof,
                getAddressProof,
                getBankProof,
                getDematProof,
                getOtherProof,
                mobOtpToken,
                setMobOtpToken,
                kycRequesterUserDetail,
                setKycRequesterUserDetail
            }}>
            {children}
        </AURegister_KYCContext.Provider>
    )
}

export function useAURegister_KYCContext() {
    return useContext(AURegister_KYCContext)
}