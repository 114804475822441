import Modal from "@material-ui/core/Modal";
import { useHistory } from "react-router";
import { configs, showLeftBar } from "../../assets/Config";
import '../../assets/styles/TopBar.css'
import { useLogin } from "../../services/contexts/CustomerLoginContext";


export default function ProfileModalPage() {
    const { profileModal, setProfileModal, logout, activeList, setActiveList, defaultDisplay, setDefaultDisplay, currModule, setShowMobBS, setcurrMod } = useLogin()

    const history = useHistory()


    // show all companies
    function leftBarDisplay(show, current) {
        let response = showLeftBar(show, current, defaultDisplay, activeList, currModule)
        if (!response) {
            setcurrMod(currModule)
            setDefaultDisplay(response)
            if ((currModule === configs.modules.shares) || (currModule === configs.modules.screenerMobFilter)) {
                setShowMobBS(true)
            }
        } else {
            setcurrMod(configs.modules.mobCom);
            setShowMobBS(false)
        }
    }

    const handleClose = () => {
        setProfileModal(false);
    };
    return (<Modal open={profileModal} onClose={handleClose}>
        <div className="navbar-profile-modal-container shadow bg-white rounded">
            <div className="d-flex justify-content-between my-2  curPoint" onClick={(e) => {
                e.preventDefault()
                setProfileModal(false)
                leftBarDisplay(false)
                history.push("/dashboard")
            }}>
                <div className="navbar-profile-modal-heading">Dashboard</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2 curPoint" onClick={(e) => {
                e.preventDefault()
                setProfileModal(false)
                leftBarDisplay(false)
                history.push("/referral")
            }}>
                <div className="navbar-profile-modal-heading" >Refer &amp; Earn</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-gift" aria-hidden="true"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2 curPoint" onClick={(e) => {
                e.preventDefault()
                setProfileModal(false)
                leftBarDisplay(false)
                history.push("/kyc")
            }}>
                <div className="navbar-profile-modal-heading" >My Account</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between my-1 curPoint" onClick={(e) => {
                e.preventDefault()
                setProfileModal(false)
                window.open("https://partners.altiusinvestech.com/TermsofUse.pdf", "_blank")
            }}>
                <div className="navbar-profile-modal-heading">Terms and conditions</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2 curPoint" onClick={(e) => {
                e.preventDefault()
                setProfileModal(false)
                window.open("https://partners.altiusinvestech.com/PrivacyPolicy.pdf", "_blank")
            }}>
                <div className="navbar-profile-modal-heading">Privacy Policy</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2 curPoint" onClick={(e) => {
                e.preventDefault()
                setProfileModal(false)
                window.open("https://partners.altiusinvestech.com/Disclosures.pdf", "_blank")

            }}>
                <div className="navbar-profile-modal-heading">Disclosures</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                </div>
            </div>
            <hr />
            {/* <div className="d-flex justify-content-between my-2">
                <div className="navbar-profile-modal-heading">Raise a ticker</div>
            </div> */}
            <div className="d-flex justify-content-between my-2 text-danger curPoint" onClick={(e) => {
                setProfileModal(false);
                leftBarDisplay(false)
                logout();
                window.location.reload()
            }}>
                <div className="navbar-profile-modal-heading" >
                    Logout
                </div>
                <div className="navbar-profile-modal-icon">
                    <i className="fas fa-sign-out-alt"></i>
                </div>
            </div>
        </div>
    </Modal>)
}