import axios from 'axios';
import { configs } from '../../assets/Config';
import FormData from 'form-data';

// get customer kyc saved details
export function getKYCSavedDetail(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// save customer basic details
export function saveCustomerKYCBasicDetail(token, name, address, pan_number, nationality) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "name": name,
                "address": address,
                "pan_number": pan_number,
                "nationality": nationality
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/updateBasicDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// save customer demat details
export function saveCustomerKYCDematDetail(token, dp_id, client_id) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "dp_id": dp_id,
                "client_id": client_id
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/updateDematDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// save customer bank details
export function saveCustomerKYCBankDetail(token, bank_name, account_number, ifsc_code) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "bank_name": bank_name,
                "account_number": account_number,
                "ifsc_code": ifsc_code
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/updateBankDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// upload customer documents
export function saveCustomerUploadDocument(token, pan_card, address_proof, bank_proof, demat_proof, other_document, pan_card_password, address_proof_password, bank_proof_password, demat_proof_password, other_document_password) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = new FormData();
            data.append("pan_card", pan_card)
            data.append("address_proof", address_proof)
            data.append("bank_proof", bank_proof)
            data.append("demat_proof", demat_proof)
            data.append("other_document", other_document)
            data.append("pan_card_password", pan_card_password)
            data.append("address_proof_password", address_proof_password)
            data.append("bank_proof_password", bank_proof_password)
            data.append("demat_proof_password", demat_proof_password)
            data.append("other_document_password", other_document_password)

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/uploadDocuments`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// customer kyc review and submit
export function saveCustomerReviewSubmit(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/reviewAndSubmit`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// customer kyc get document
export function getCustomerDocuments(token, docType) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/document/${docType}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// customer kyc final submit
export function saveCustomerKYCFinal(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/reviewAndSubmit`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// customer kyc save mobile number
export function saveCustomerMobile(token, mobile, country_code) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "mobile": mobile,
                "country_code": country_code
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/updateMobileNumber`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// resend otp to update mobile number
export function resendMobileOtp(token, tokenOtp) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "token": tokenOtp
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/resendUpdateMobileNumberOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// verify otp to update mobile number
export function verifyMobileOtp(token, tokenOtp, otp) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "token": tokenOtp,
                "otp": otp
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/kyc/verifyUpdateMobileNumberOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}