import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { getCustomerDocuments, getKYCSavedDetail } from '../apis/CustomerKYCApi';
import { useLogin } from './CustomerLoginContext';

// create global KYC context
export const CustomerKYCContext = createContext();

export function CustomerKYCProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [otpModal, setOtpModal] = useState(false)
    const [mobOtpToken, setMobOtpToken] = useState("")
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(2);
    const [editPage, setEditPage] = useState(false);
    const [kycDetails, setKycDetails] = useState(null);
    const [successModal, setsuccessModal] = useState(false);

    // get customer kyc saved detail
    async function getKYCSavedDetailContext(change) {

        try {
            let response = await getKYCSavedDetail(token);
            if (response && response.status === 200) {
                setKycDetails(response.data);
                if (document.getElementById("sidebarCompanyTableContainer")) {
                    document.getElementById("sidebarCompanyTableContainer").style.height = document.getElementById("bottomContainer").offsetTop - (document.getElementById("sidebarCompanyTableContainer").offsetTop + document.getElementById("bottomContainer").offsetHeight) + "px";
                }
                if (!change && response.data.status !== configs.statusValues.initial) {
                    setCurrPage(5);
                    setNextPage(5);
                }
            } else {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
        } catch (error) {

        }
    }

    // get customer uploaded documents
    async function getPanProof() {
        try {
            let response = await getCustomerDocuments(token, 'pan_card');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "PanProof.pdf"
                    : response.data.includes("image/png") ? "PanProof.png"
                        : "PanProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getAddressProof() {
        try {
            let response = await getCustomerDocuments(token, 'address_proof');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "AddressProof.pdf"
                    : response.data.includes("image/png") ? "AddressProof.png"
                        : "AddressProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getBankProof() {
        try {
            let response = await getCustomerDocuments(token, 'bank_proof');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "BankProof.pdf"
                    : response.data.includes("image/png") ? "BankProof.png"
                        : "BankProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getDematProof() {
        try {
            let response = await getCustomerDocuments(token, 'demat_proof');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "DematProof.pdf"
                    : response.data.includes("image/png") ? "DematProof.png"
                        : "DematProof.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }

    async function getOtherProof() {
        try {
            let response = await getCustomerDocuments(token, 'other_document');
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data.includes("application/pdf") ? "OtherDocument.pdf"
                    : response.data.includes("image/png") ? "OtherDocument.png"
                        : "OtherDocument.jpg"; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }

    // customer kyc switch page
    async function custKYCSwitchPage(currPage) {
        setCurrPage(currPage);
        currPage < 5 ? setNextPage(currPage + 1) : setNextPage(5);
    }

    // export global context
    return (
        <CustomerKYCContext.Provider
            value={{
                custKYCSwitchPage,
                currPage,
                nextPage,
                kycDetails,
                setKycDetails,
                getKYCSavedDetailContext,
                editPage,
                setEditPage,
                otpModal,
                setOtpModal,
                successModal,
                setsuccessModal,
                getPanProof,
                getAddressProof,
                getBankProof,
                getDematProof,
                getOtherProof,
                mobOtpToken,
                setMobOtpToken
            }}
        >
            {children}
        </CustomerKYCContext.Provider>
    );
}

export function useCustomerKYCContext() {
    return useContext(CustomerKYCContext)
}