import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useLogin } from "../contexts/CustomerLoginContext";


export default function LoginPrivateRoute({ children, ...rest }) {
    const { token, setImpersonating, setProxyEmail, proxyEmail } = useLogin();
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (!location.pathname.includes("/user") && !location.pathname.includes("/clients")) {
                    setImpersonating(false)
                    setProxyEmail("")
                }
                return location.pathname !== "/distDetail" ?
                    token ? (
                        children
                    ) : (
                        <Redirect to={{ pathname: "/login", state: { from: location } }} />
                    )
                    : <Redirect to={{ pathname: "/distDetail", state: { from: location } }} />
            }
            }
        />
    );
}
