import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CustomerLoginPage from './components/pages/login/CustomerLoginPage';
import { LoginProvider } from './services/contexts/CustomerLoginContext';
import LoginSuccess from './components/pages/login/loginSuccess';
import SignUpPersonalDetailPage from './components/pages/signup/SignUpPersonalDetailPage';
import { CustomerSignUpProvider } from './services/contexts/CustomerSignUpContext';
import SignUpPrivateRoute from './services/privateRoutes/CustomerSignUpPrivateRoute';
import VerifyOTPPage from './components/includes/VerifyOTPPage';
import CreatePasswordPage from './components/includes/CreatePassPage';
import { ForgotPasswordProvider } from './services/contexts/ForgotPasswordContext';
import ForgotPasswordPrivateRoute from './services/privateRoutes/ForgotPasswordPrivateRoute';
import ForgotPasswordPage from './components/pages/forgotPassword/ForgotPasswordPage';
import { CustomerKYCProvider } from './services/contexts/CustomerKYCContext';
import LoginPrivateRoute from './services/privateRoutes/LoginPrivateRoute';
import KYCMainPage from './components/pages/KYC/KYCMainpage';
import DashboardPage from './components/pages/Dashboard/DashboardPage';
import { getDistributorType, loadAllNotifications, loginCustomerWithGoogleOneTap } from './services/apis/CustomerLoginApi';
import { configs, getCookie } from './assets/Config';
import OneSignal from 'react-onesignal';
import SignUpGoogleMobilePage from './components/pages/signup/SignUpGoogleMobilePage';
import googleOneTap from 'google-one-tap';
import LandingPage from './components/pages/login/LandingPage';
import ClientsMainPage from './components/pages/clientData/ClientsMainPage';
import { ClientDataProvider } from './services/contexts/ClientDataContext';
import CommissionPage from './components/pages/commission/CommissionPage';
import HowItWorksPage from './components/pages/login/HowItWorksPage';
import ReferralPage from './components/pages/referral/ReferralPage';
import { AURegister_KYCProvider } from './services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import AUKYCMainPage from './components/pages/assistedUser/kyc/AUKYCMainpage';
import { AUAssetMgmtProvider } from './services/contexts/assistedUser/assets/AUAssetMgmtContext';
import AUAssetMainPage from './components/pages/assistedUser/assets/AUAssetMainPage';
import AUBondsPage from './components/pages/assistedUser/assets/AUBondsPage';
import AUMLDPage from './components/pages/assistedUser/assets/AUMLDPage';
import AUGSecPage from './components/pages/assistedUser/assets/AUGSecPage';
import AUStartupPage from './components/pages/assistedUser/assets/AUStartupPage';
import AUPreIPOBucketPage from './components/pages/assistedUser/assets/AUPreIPOBucketPage';
import AUAssetDetailPage from './components/pages/assistedUser/assets/AUAssetDetailPage';
import AUUnlistedCompanyPage from './components/pages/assistedUser/assets/AUUnlistedCompanyPage';
import AUUnlistedCompanyDetailPage from './components/pages/assistedUser/assets/AUUnlistedCompanyDetailPage';
import AUAssetPaymentPage from './components/pages/assistedUser/assets/AUAssetPaymentPage';
import AUCustomerProfilePage from './components/pages/assistedUser/kyc/AUCustomerProfilePage';


(async () => {
  try {
    try {
      configs.referrer = window.localStorage.getItem("referrer") ? window.localStorage.getItem("referrer") : ""
      configs.referralCode = window.localStorage.getItem("referralCode") ? window.localStorage.getItem("referralCode") : ""
      window.localStorage.removeItem("login")
    } catch (err) { }

    document.cookie = "g_state=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";

    const savedToken = getCookie("DISTRIBUTOR_AUTH_TOKEN");
    if (!savedToken) {
      const options = {
        client_id: "275109807487-vs6vve0lfsci2bpt33n79bjfgji7v88n.apps.googleusercontent.com", // required
        auto_select: false, // optional
        cancel_on_tap_outside: true, // optional
        context: 'signin', // optional,
      };

      googleOneTap(options, (response) => {
        // Send response to server
        loginCustomerWithGoogleOneTap(response.credential)
      });
    }

    if (savedToken) {
      try {
        var result = await getDistributorType(savedToken)
        configs.isWealthManager = result.data && result.data.type ? result.data.type : "false"
        configs.commissionModel = result.data && result.data.commission_modal ? result.data.commission_modal : configs.commissionModel
      } catch (error) {
        console.error(error)
      }

      OneSignal.init({
        appId: "368a1458-c282-4a50-8edb-23ea3077d77e",
        safari_web_id: "web.onesignal.auto.175a5781-a0a7-4966-97e0-0bfa01fcdb1f",
        notifyButton: {
          enable: true,
        },
        subdomainName: "altius",
        persistNotification: false
      }).then(() => {
        OneSignal.setExternalUserId(savedToken).then(async () => {
        }).catch(error => {
          console.error("--", error)
        })
      }).catch((error) => {
        console.error(error)
      })
      OneSignal.on('notificationDisplay', function (event) {
        try {
          configs.notiSeen = false
        } catch (error) {
        }
      });

      let unread = 0;
      let response = await loadAllNotifications(savedToken, 1);
      if (response && response.status === 200) {
        if (response.data && response.data.length > 0) {
          response.data.map((element) => {
            if (element.is_seen === 0) {
              unread += 1;
            }
          })
        }
        if (unread > 0) {
          configs.notiSeen = false
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

})();


ReactDOM.render(
  <LoginProvider>
    <CustomerSignUpProvider>
      <ForgotPasswordProvider>
        <CustomerKYCProvider>
          <ClientDataProvider>
            <AURegister_KYCProvider>
              <AUAssetMgmtProvider>
                <Router>
                  <Switch>

                    <Route exact path={["/login", "/"]}>
                      <LandingPage></LandingPage>
                    </Route>

                    <Route exact path="/distDetail">
                      <HowItWorksPage />
                    </Route>

                    <LoginPrivateRoute exact path="/dashboard">
                      <DashboardPage></DashboardPage>
                    </LoginPrivateRoute>

                    <LoginPrivateRoute exact path="/referral">
                      <ReferralPage></ReferralPage>
                    </LoginPrivateRoute>

                    <LoginPrivateRoute exact path="/clients">
                      <ClientsMainPage></ClientsMainPage>
                    </LoginPrivateRoute>

                    <LoginPrivateRoute exact path="/commission">
                      <CommissionPage></CommissionPage>
                    </LoginPrivateRoute>

                    {/* sign up routes start */}
                    <Route exact path="/signup/google/saveMobile">
                      <LandingPage></LandingPage>
                    </Route>
                    <SignUpPrivateRoute exact path="/signup/verifyOtp">
                      <LandingPage></LandingPage>
                    </SignUpPrivateRoute>
                    <SignUpPrivateRoute exact path="/signup/createPassword">
                      <LandingPage></LandingPage>
                    </SignUpPrivateRoute>
                    <Route exact path="/signup/:utm?">
                      <LandingPage></LandingPage>
                    </Route>
                    {/* sign up routes end */}

                    {/* forgot password routes start */}
                    <Route exact path="/forgotPassword">
                      <LandingPage></LandingPage>
                    </Route>
                    <ForgotPasswordPrivateRoute exact path="/forgotPassword/verifyOtp">
                      <LandingPage></LandingPage>
                    </ForgotPasswordPrivateRoute>
                    <ForgotPasswordPrivateRoute exact path="/forgotPassword/createPassword">
                      <LandingPage></LandingPage>
                    </ForgotPasswordPrivateRoute>
                    {/* forgot password routes end */}

                    {/* kyc routes start */}
                    <LoginPrivateRoute exact path="/kyc">
                      <KYCMainPage></KYCMainPage>
                    </LoginPrivateRoute>
                    {/* kyc routes end */}

                    {/* assisted user routes start */}
                    <LoginPrivateRoute exact path="/user/kyc">
                      <AUKYCMainPage></AUKYCMainPage>
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets">
                      <AUAssetMainPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/company">
                      <AUUnlistedCompanyPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/company/:companyName">
                      <AUUnlistedCompanyDetailPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/bond">
                      <AUBondsPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/mld">
                      <AUMLDPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/gsec">
                      <AUGSecPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/startup">
                      <AUStartupPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/preipobucket">
                      <AUPreIPOBucketPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/assetDetail/:assetClassName/:assetName">
                      <AUAssetDetailPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/assets/payment">
                      <AUAssetPaymentPage />
                    </LoginPrivateRoute>
                    <LoginPrivateRoute exact path="/user/profile">
                      <AUCustomerProfilePage />
                    </LoginPrivateRoute>
                    {/* assisted user routes end */}

                    <Route component={() => (<div>404 Not found </div>)} />

                  </Switch>
                </Router>
              </AUAssetMgmtProvider>
            </AURegister_KYCProvider>
          </ClientDataProvider>
        </CustomerKYCProvider>
      </ForgotPasswordProvider>
    </CustomerSignUpProvider>
  </LoginProvider>,
  document.getElementById('root')
);

reportWebVitals();