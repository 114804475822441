import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link, useRouteMatch } from "react-router-dom";
import { configs } from "../../../assets/Config";
import '../../../assets/styles/CustomerLogin.css';
import { forgotPasswordSendOtp } from "../../../services/apis/ForgotPasswordApi";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import { useForgotPasswordContext } from "../../../services/contexts/ForgotPasswordContext";
import imgdn from '../../../assets/images/leftBG.png'


export default function ForgotPasswordPage() {

    const { setFgtPswdToken } = useForgotPasswordContext()
    const { setVerifyEmail } = useLogin()
    const history = useHistory();
    const match = useRouteMatch();

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    // send otp for forgot password
    async function sendFgtPswdOtp(e) {
        try {
            e.preventDefault();
            if (!email.trim() || !configs.emailRegex.test(String(email).toLowerCase())) {
                setError(configs.errorMessages.fgtPassEmail)
            } else {
                let response = await forgotPasswordSendOtp(email);
                if (response && response.status === 200) {
                    await setFgtPswdToken(response.data.token);
                    setEmail("");
                    setError("");
                    history.push(`${match.url}/verifyOtp`);
                } else {
                    setError(response.data.error)
                }
            }
        } catch (error) {

        }
    }

    // forgot password ui code
    return (
        <div >
            <div>
                <p className="p-0 m-0 welcome-heading">Reset Password</p>
                <p className="p-0 m-0 welcome-sub-heading f-14">Provide your valid email to reset the password</p>
            </div>
            <div className="loginForm">
                <div className="form-group mt-4 mb-2">
                    <input type="email" className="form-control login-screen-input f-14" name="email" placeholder="Enter your email" onChange={(e) => {
                        setError("")
                        setEmail(e.target.value)
                        setVerifyEmail(e.target.value)
                    }} />
                </div>
                <div className="ml-2">
                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{error}</p>
                </div>
                <div className="form-group mt-2 mb-0">
                    <button className="signIn-button curPoint" onClick={sendFgtPswdOtp}>Generate OTP</button>
                </div>
            </div>


        </div>
        // <section>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-6 d-none d-sm-block p-0 m-0">
        //                 <div className="login-screen-wallpaper">
        //                     <img src={imgdn} className="w-100 h-100" alt="image" />
        //                 </div>
        //             </div>
        //             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
        //                 <div className="w-100">
        //                     <div className="login-screen-logo">
        //                         <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="logo" />
        //                     </div>
        //                     <form>
        //                         <div className="form-group">
        //                             <h4 className="mb-0 text-custom-greyDark">{configs.hardCodeTexts.fgtPass.heading}</h4>
        //                             <p className="mt-0 text-custom-grey">{configs.hardCodeTexts.fgtPass.subHeading}</p>
        //                         </div>
        //                         <div className="form-group mt-5">
        //                             <input type="email" className="form-control login-screen-input f-14" name="user_Email" placeholder="Enter your Email" required onChange={(e) => {
        //                                 setEmail(e.target.value);
        //                                 setVerifyEmail(e.target.value);
        //                             }} />
        //                         </div>
        //                         <div className="form-check p-0">
        //                             <p className="text-danger" id="forgotPasswordError">{error}</p>
        //                         </div>
        //                         <div className="form-group text-center">
        //                             <button type="submit" className="btn btn-sm btn-custom btn-primary rounded px-3 login-screen-sign-in-button" disabled={email ? false : true} onClick={sendFgtPswdOtp}>{configs.hardCodeTexts.fgtPass.otpBtn}</button>
        //                         </div>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}
