import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { assistedUserToken, configs } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css';
import { saveCustomerKYCBasicDetail, saveCustomerKYCFinal } from '../../../../services/apis/assistedUser/registration_kyc/AUKYCApi';
import SuccessModalPage from '../../../includes/SuccessModalPage';
import KYCMobileProgressBarPage from './AUKYCMobileProgressBarPage';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import { useCustomerKYCContext } from '../../../../services/contexts/CustomerKYCContext';

export default function AUKYCReviewSubmitPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, getKYCSavedDetailContext, currPage, nextPage, kycDetails, setKycDetails, editPage, setEditPage, getPanProof, getAddressProof, getBankProof, getDematProof, getOtherProof } = useAURegister_KYCContext();
    const { setsuccessModal } = useCustomerKYCContext();
    const { token, logout, setLoginError, proxyEmail, setShowSuccess } = useLogin();

    const [saveFinal, setSaveFinal] = useState(false)
    const [error, setError] = useState("")

    // final save customer kyc
    async function finalCustomerKYCSave(e) {
        try {
            e.preventDefault();
            if (saveFinal) {
                document.getElementById("finBtn").classList.toggle("d-none")
                document.getElementById("finBtnLoader").classList.toggle("d-none")

                let response = await saveCustomerKYCFinal(assistedUserToken(token, proxyEmail));
                if (response && response.status === 200) {
                    setsuccessModal(true);
                } else if (response && response.status === 401) {
                    logout();
                    //setLoginError(configs.unauthorizedErrorMessage);
                } else {
                    setError(response.data.error)
                }

                document.getElementById("finBtn").classList.toggle("d-none")
                document.getElementById("finBtnLoader").classList.toggle("d-none")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // customer kyc review submit ui code
    return (
        <div id="kycReviewSubmitContainer" className="main-kycPanel">
            <div className="col-lg-12 m-0 p-0">
                <p className="subheading2 text-theme mt-2 m-0 p-0"><i className="fas fa-flag-checkered mr-2"></i>Review &amp; Submit</p>
                <p className="text-custom-grey m-0 p-0">Start Investing</p>

                <div className="mt-4 subContent">
                    {kycDetails["remark"] ? <div className={kycDetails["status"] === configs.statusValues.verified ? "alert alert-success "
                        : kycDetails["status"] === configs.statusValues.rejected ? "alert alert-danger "
                            : "alert alert-primary "} role="alert">
                        {kycDetails["remark"]}
                    </div>
                        : null}
                </div>

                <form action="">
                    <div className="form-row mt-4 d-flex flex-row justify-content-between align-items-center">
                        <div className="col-6 mt-2">
                            <p className="text-theme subheading2">Customer Basic Profile</p>
                        </div>
                        <div className="col-6 mt-2 text-right">
                            <button type="submit" className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={(e) => {
                                custKYCSwitchPage(1)
                                setEditPage(true)
                            }}><i className="far fa-edit mr-2"></i>Edit</button>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_email">Email</label>
                            <input type="email" className="form-control" disabled defaultValue={kycDetails.basic_details.email} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_mobile">Mobile Number</label>
                            <input type="text" minLength={10} maxLength={10} className="form-control" disabled defaultValue={`+${kycDetails.basic_details.mobile}`} />
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_name">Full Name</label>
                            <input type="text" className="form-control" disabled defaultValue={kycDetails.basic_details.name} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_PAN">PAN Number</label>
                            <input type="text" className="form-control" disabled defaultValue={kycDetails.basic_details.pan_number} />
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label className="" htmlFor="user_Address">Address</label>
                        <textarea name="user_Address" id="user_Address" className="form-control w-100" rows="2" disabled defaultValue={kycDetails.basic_details.address} />
                    </div>
                    <div className="form-group mt-3">
                        <label className="" >Nationality</label>
                        <input type="text" className="form-control" disabled defaultValue={kycDetails.basic_details.nationality} />
                    </div>

                </form>

                <form action="">
                    <div className="form-row mt-5 d-flex flex-row justify-content-between align-items-center">
                        <div className="col-6 mt-2">
                            <p className="text-theme subheading2">Demat Details</p>
                        </div>
                        <div className="col-6 mt-2 text-right">
                            <button type="submit" className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={(e) => {
                                custKYCSwitchPage(2)
                                setEditPage(true)
                            }}><i className="far fa-edit mr-2"></i>Edit</button>
                        </div>
                    </div>
                    <div className="form-row my-3">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_DP_Id">DP ID</label>
                            <input type="text" className="form-control" disabled defaultValue={kycDetails.demat_details.dp_id} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_Client_Id">Client ID</label>
                            <input type="text" minLength={8} maxLength={8} className="form-control" disabled defaultValue={kycDetails.demat_details.client_id} />
                        </div>
                    </div>
                </form>

                <form action="">
                    <div className="form-row mt-5 d-flex flex-row justify-content-between align-items-center">
                        <div className="col-6 mt-2">
                            <p className="text-theme subheading2">Bank Details</p>
                        </div>
                        <div className="col-6 mt-2 text-right">
                            <button type="submit" className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={(e) => {
                                custKYCSwitchPage(3)
                                setEditPage(true)
                            }}><i className="far fa-edit mr-2"></i>Edit</button>
                        </div>
                    </div>
                    <div className="form-row my-3">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_BankName">Bank Name</label>
                            <input type="text" className="form-control" disabled defaultValue={kycDetails.bank_details.bank_name} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                            <label className="" htmlFor="user_BankIFSC">IFSC Code</label>
                            <input type="text" className="form-control" disabled defaultValue={kycDetails.bank_details.ifsc_code} />
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label className="" htmlFor="user_BankAccNo">Bank Account Number</label>
                        <input type="text" minLength={6} maxLength={20} className="form-control" disabled defaultValue={kycDetails.bank_details.account_number} />
                    </div>
                </form>

                <form action="">
                    <div className="form-row mt-5 d-flex flex-row justify-content-between align-items-center">
                        <div className="col-6 mt-2">
                            <p className="text-theme subheading2">Uploaded Documents</p>
                        </div>
                        <div className="col-6 mt-2 text-right">
                            <button type="submit" className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={(e) => {
                                custKYCSwitchPage(4)
                                setEditPage(true)
                            }}><i className="far fa-edit mr-2"></i>Edit</button>
                        </div>
                    </div>
                    <div className="form-row my-3 d-flex justify-content-around">
                        {kycDetails.user_documents.pan_card ?
                            <div className="mt-2 mt-2 d-flex align-items-center justify-content-center docs-box">

                                <div className="docs-box-hover curPoint" onClick={(e) => {
                                    e.preventDefault();
                                    getPanProof()
                                }}>
                                    <i className="fas fa-file-download heading3"></i> <br />
                                    <p className="m-0 p-0">PAN Card</p>

                                </div>
                            </div>
                            : kycDetails.basic_details && (kycDetails.basic_details.name_status != "verified" || kycDetails.basic_details.pan_status != "verified") ?
                                <div className="mt-2 mt-2 d-flex align-items-center justify-content-center docs-box">

                                    <div className="text-danger">
                                        <i className="fas fa-exclamation-circle heading3"></i> <br />
                                        <p className="m-0 p-0">Kindly upload PAN Card</p>

                                    </div>
                                </div>
                                : ""
                        }
                        {/* <div className="mt-2 mt-2 d-flex align-items-center justify-content-center docs-box">
                            {kycDetails.user_documents.address_proof ?
                                <div className="docs-box-hover curPoint" onClick={(e) => {
                                    e.preventDefault();
                                    getAddressProof()
                                }}>
                                    <i className="fas fa-file-download heading3"></i> <br />
                                    <p className="m-0 p-0">Address Proof</p>

                                </div>
                                : <div className="text-danger">
                                    <i className="fas fa-exclamation-circle heading3"></i> <br />
                                    <p className="m-0 p-0">Kindly upload Address Proof</p>

                                </div>
                            }
                        </div> */}

                        {kycDetails.user_documents.bank_proof ?
                            <div className="mt-2 mt-2 d-flex align-items-center justify-content-center docs-box">

                                <div className="docs-box-hover curPoint" onClick={(e) => {
                                    e.preventDefault();
                                    getBankProof()
                                }}>
                                    <i className="fas fa-file-download heading3"></i> <br />
                                    <p className="m-0 p-0">Bank Proof</p>

                                </div>
                            </div>
                            : kycDetails.bank_details && kycDetails.bank_details.status != "verified" ?
                                <div className="mt-2 mt-2 d-flex align-items-center justify-content-center docs-box">

                                    <div className="text-danger">
                                        <i className="fas fa-exclamation-circle heading3"></i> <br />
                                        <p className="m-0 p-0">Kindly upload Bank Proof</p>

                                    </div>
                                </div>
                                : ""
                        }

                        <div className="mt-2 d-flex align-items-center justify-content-center docs-box">
                            <div>
                                {kycDetails.user_documents.demat_proof ?
                                    <div className="docs-box-hover curPoint" onClick={(e) => {
                                        e.preventDefault();
                                        getDematProof()
                                    }}>
                                        <i className="fas fa-file-download heading3"></i> <br />
                                        <p className="m-0 p-0">Demat Proof</p>

                                    </div>
                                    : <div className="text-danger">
                                        <i className="fas fa-exclamation-circle heading3"></i> <br />
                                        <p className="m-0 p-0">Kindly upload Demat Proof</p>

                                    </div>
                                }
                            </div>
                        </div>

                        <div className="mt-2 d-flex align-items-center justify-content-center docs-box">
                            <div>
                                {kycDetails.user_documents.other_document ?
                                    <div className="docs-box-hover curPoint" onClick={(e) => {
                                        e.preventDefault();
                                        getOtherProof()
                                    }}>
                                        <i className="fas fa-file-download heading3"></i> <br />
                                        <p className="m-0 p-0">Other Document</p>

                                    </div>
                                    : <div className="text-danger">
                                        <i className="fas fa-exclamation-circle heading3"></i> <br />
                                        <p className="m-0 p-0">Kindly upload Other Document</p>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>

            </div>

            <div className="col-lg-12 m-0 p-0">
                <div className="mt-4">
                    <p className="text-custom-grey text-left mt-4 mb-2 pb-0 font-weight-bold">KYC Verification Status</p>
                    <table className="table">
                        <tbody className="text-left">
                            <tr>
                                <td>Name</td>
                                <td className={kycDetails["basic_details"]["name_status"] === configs.statusValues.verified ? "font-weight-bold text-success"
                                    : kycDetails["basic_details"]["name_status"] === configs.statusValues.rejected ? "font-weight-bold text-danger"
                                        : "font-weight-bold text-primary"}><span className="text-capitalize">{kycDetails["basic_details"]["name_status"]}</span></td>
                            </tr>
                            <tr>
                                <td>PAN</td>
                                <td className={kycDetails["basic_details"]["pan_status"] === configs.statusValues.verified ? "font-weight-bold text-success"
                                    : kycDetails["basic_details"]["pan_status"] === configs.statusValues.rejected ? "font-weight-bold text-danger"
                                        : "font-weight-bold text-primary"}><span className="text-capitalize">{kycDetails["basic_details"]["pan_status"]}</span></td>
                            </tr>
                            {/* <tr>
                                <td>Address</td>
                                <td className={kycDetails["basic_details"]["address_status"] === configs.statusValues.verified ? "font-weight-bold text-success"
                                    : kycDetails["basic_details"]["address_status"] === configs.statusValues.rejected ? "font-weight-bold text-danger"
                                        : "font-weight-bold text-primary"}><span className="text-capitalize">{kycDetails["basic_details"]["address_status"]}</span></td>
                            </tr> */}
                            <tr>
                                <td>Bank Details</td>
                                <td className={kycDetails["bank_details"]["status"] === configs.statusValues.verified ? "font-weight-bold text-success"
                                    : kycDetails["bank_details"]["status"] === configs.statusValues.rejected ? "font-weight-bold text-danger"
                                        : "font-weight-bold text-primary"}><span className="text-capitalize">{kycDetails["bank_details"]["status"]}</span></td>
                            </tr>
                            <tr>
                                <td>Demat Details</td>
                                <td className={kycDetails["demat_details"]["status"] === configs.statusValues.verified ? "font-weight-bold text-success"
                                    : kycDetails["demat_details"]["status"] === configs.statusValues.rejected ? "font-weight-bold text-danger"
                                        : "font-weight-bold text-primary"}><span className="text-capitalize">{kycDetails["demat_details"]["status"]}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card-grey pr-3">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={(e) => {
                            setSaveFinal(e.target.checked)
                        }} />
                        <label className="form-check-label text-justify" htmlFor="exampleCheck1">
                            I hereby confirm and declare that all the information provided by me is true and latest to the best of my knowledge. I acknowledge that Altius Investech will use this information to process payment and deliver shares.
                        </label>
                        <div className="form-check p-0">
                            <p className="text-danger" id="finalDetailError">{error}</p>
                        </div>
                        <div className="text-left mt-3">
                            <button id="finBtn" type="submit" disabled={!saveFinal} className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={(e) => {
                                finalCustomerKYCSave(e)
                            }}>Final Submit</button>
                            <div id="finBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                </div>
                <SuccessModalPage {...{ message: "Our executive will respond to your KYC as soon as possible." }} />
            </div>
        </div>
    )
}