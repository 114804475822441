import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { animation, configs } from "../../../assets/Config";
import '../../../assets/styles/CustomerLogin.css';
import '../../../assets/styles/animation.css';

import { getGoogleAuthUrl } from "../../../services/apis/CustomerLoginApi";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";

import googleIcon from '../../../assets/images/google.png'


export default function CustomerLoginPage() {
    // fetching global login context
    const { loginUserWithGoogleCredentials, loginUserWithCredentials, login, loginError, setLoginError, loginUserWithGoogleOneTap } = useLogin()
    const { search } = useLocation();
    const history = useHistory();

    // hook declaration to process login
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(0);
    const [googleAuthURL, setGoogleAuthURL] = useState("");

    // check if active customer sesssion is available and bypass login
    useEffect(() => {
        if (login) {
            setLoginError("")
            history.push("/dashboard")
        }
    }, [login])

    // login customer based on credentials filled
    async function credentialLogin(e) {
        try {
            e.preventDefault();
            if (!username.trim()) {
                setLoginError(configs.errorMessages.login.user);
            } else if (!password.trim()) {
                setLoginError(configs.errorMessages.login.pass);
            } else {
                let response = await loginUserWithCredentials(username, password, remember)
                if (response === configs.errorMessages.success) {
                    setUsername("")
                    setPassword("")
                    setRemember("")
                    setLoginError("")
                    setGoogleAuthURL("")
                }
            }
        } catch (error) {

        }
    }

    // get google oAuth url for login 
    useEffect(() => {
        (async () => {
            try {
                let url = await getGoogleAuthUrl()
                setGoogleAuthURL(url);
            } catch (error) {

            }
        })();
    }, []);


    // login with google oAuth
    useEffect(() => {
        (async () => {
            try {
                var searchParams = new URLSearchParams(search);
                var googleCode = searchParams.get("code");
                // var credential = searchParams.get("credential")
                if (googleCode) {
                    let response = await loginUserWithGoogleCredentials(googleCode)
                    if (response && response.redirect) {
                        history.push('/signup/google/saveMobile')
                    }
                }
                // else if (credential) {
                //     let response = await loginUserWithGoogleOneTap(credential)
                //     if (response && response.redirect) {
                //         history.push('/signup/google/saveMobile')
                //     }
                // }
            } catch (error) {
                console.error(error)
            }
        })();
    }, []);


    // login page ui code
    return (
        <div >
            <div>
                <p className="p-0 m-0 welcome-heading">Altius Partner Login</p>
                <p className="p-0 m-0 welcome-sub-heading f-14">Grow your business with Altius’s <b>Pre-IPO</b> and <b>Unlisted</b> offerings</p>
            </div>
            <div className="loginForm">
                <div className="form-group mt-1 mb-2">
                    <p className="m-0 ml-2 mb-2 p-0 f-14 font-weight-bold">Email</p>
                    <input type="email" className="form-control login-screen-input f-14" name="username" placeholder="Enter your email" onChange={(e) => {
                        setLoginError("")
                        setUsername(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-4 mb-2">
                    <p className="m-0 ml-2 mt-2 mb-2 p-0 f-14 font-weight-bold">Password</p>
                    <input type="password" className="form-control login-screen-input f-14" name="username" placeholder="********" onChange={(e) => {
                        setLoginError("")
                        setPassword(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-4 mb-0">
                    <div className="row mx-1">
                        <div className="col-6 pl-1 d-flex align-items-center">
                            <input type="checkbox" id="exampleCheck1" onChange={(e) => {
                                e.target.checked ? setRemember(1) : setRemember(0)
                            }} />
                            <label className="m-0 ml-2 p-0 f-12 font-weight-bold">{configs.hardCodeTexts.login.remember}</label>
                        </div>
                        <div className="col-6 pr-1 text-right">
                            <label className="m-0 ml-2 p-0 f-12 font-weight-bold curPoint" onClick={(e) => {
                                e.preventDefault();
                                history.push("/forgotPassword")
                            }}>{configs.hardCodeTexts.login.fgtPass}</label>
                        </div>
                    </div>
                </div>
                <div className="ml-2">
                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{loginError}</p>
                </div>
                <div className="form-group mt-2 mb-0">
                    <button className="signIn-button curPoint" onClick={credentialLogin}>Sign in</button>
                </div>
                <div id="gsignIn" className="form-group mt-2">
                    <button className="googleSignIn-button curPoint" onClick={(e) => {
                        e.preventDefault();
                        window.open(googleAuthURL, "_self")
                    }}><img style={{ objectFit: "contain", height: "1.5rem" }} className="mr-2" src={googleIcon} />Sign in with Google</button>
                </div>
                <div id="gsignInAnim" className="form-group mt-2 d-none">
                    <button className="googleSignIn-button gSignIn-parent" onClick={(e) => {
                        e.preventDefault();
                    }}><img style={{ objectFit: "contain", height: "1.5rem" }} className="mr-4" src={googleIcon} /> <div className="gSignIn-Anim"></div></button>
                </div>
                <div>
                    <p className="f-14 m-0 p-0 noAccount">Don't have an account?<span style={{ color: "#009FE3" }} className="curPoint" onClick={(e) => {
                        e.preventDefault();
                        history.push("/signup")
                    }}> Sign up</span></p>
                </div>
            </div>
        </div>
        // <section>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-6 d-none d-sm-block p-0 m-0">
        //                 <div className="login-screen-wallpaper">
        //                     <img src={imgdn} className="w-100 h-100" alt="image" />
        //                 </div>
        //             </div>
        //             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
        //                 <div className="w-100">
        //                     <div className="login-screen-logo">
        //                         <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="logo" />
        //                     </div>
        //                     <form>
        //                         <div className="form-group">
        //                             <h4>{configs.hardCodeTexts.login.heading}</h4>
        //                         </div>
        //                         <div className="form-group">
        //                             <input type="text" className="form-control login-screen-input f-14" name="username" placeholder="Email ID" onChange={(e) => {
        //                                 setUsername(e.target.value)
        //                             }} />
        //                         </div>
        //                         <div className="form-group">
        //                             <input type="password" className="form-control login-screen-input f-14" name="password" placeholder="Password" onChange={(e) => {
        //                                 setPassword(e.target.value)
        //                             }} />
        //                         </div>
        //                         <div className="form-check p-0">
        //                             <p className="text-danger" id="loginFormError">{loginError}</p>
        //                         </div>
        //                         <div className="form-check">
        //                             <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={(e) => {
        //                                 e.target.checked ? setRemember(1) : setRemember(0)
        //                             }} />
        //                             <label className="form-check-label" htmlFor="exampleCheck1">{configs.hardCodeTexts.login.remember}</label>
        //                         </div>
        //                         <div className="form-row mt-4">
        //                             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        //                                 <button type="submit" className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={credentialLogin}>{configs.hardCodeTexts.login.signin}</button>
        //                             </div>
        //                             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-sm-right text-center">
        //                                 <Link to="/forgotPassword" className="text-primary" >{configs.hardCodeTexts.login.fgtPass}</Link>
        //                             </div>
        //                         </div>
        //                     </form>
        //                     <div className="mt-4 text-center position-relative">
        //                         <hr className="w-sm-100 w-75" />
        //                         <div className="login-screen-hr-or">
        //                             <p>{configs.hardCodeTexts.login.or}</p>
        //                         </div>
        //                     </div>
        //                     <div className="mt-4 text-center">
        //                         <a className="btn btn-outline-secondary google-sign-in-button text-wrap" href={googleAuthURL}>
        //                             <img alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
        //                             {configs.hardCodeTexts.login.googleLogin}
        //                         </a>
        //                     </div>
        //                     <div className="mt-5">
        //                         Don't have an account? <Link to="/signup" className="text-primary">{configs.hardCodeTexts.login.createAcct}</Link>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}
