import React, { useEffect, useState } from 'react';
import { configs, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css'
import '../../../../assets/styles/Assets.css'
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import preipo from '../../../../assets/images/preipo.svg';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';



export default function AUPreIPOBucketPage() {

    const { setCurrModule, setcurrMod } = useLogin()

    const { assetList, getAssetsListByClass, getAssetDetailById, setAssetClass, setAssetId } = useAUAssetMgmtContext()
    const [activeTab, setActiveTab] = useState("0")

    const [expanded, setExpanded] = useState('false');
    const history = useHistory()

    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(async () => {
        const controller = new AbortController();

        document.title = `Pre-IPO Basket | Altius Investech`

        // if (!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("PRE_IPO_BUCKET")) {
        //     return history.push('/user/assets')
        // }

        setCurrModule(configs.modules.otherAsset)
        setcurrMod(configs.modules.otherAsset)
        let temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
        let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

        await getAssetsListByClass('bucket/preIPO')

        temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
        temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

        setTimeout(() => {
            if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                document.getElementById("phWrapper").classList.toggle("d-none")
            }
        }, 500)

        return () => {
            controller.abort();
        };
    }, [])

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='assetContainer' className="assetMain" >
                    <div className='assetBgImage'>
                        <div id="assetOpp" className='mainDiv'>
                            <div className='row m-0 p-0 mb-3'>
                                <div className='col-12 m-0 p-0'>
                                    <Link to={`/user/assets`}>Alternate Assets</Link> &gt;&gt; <Link to={`/user/assets/preipobucket`}>Pre-IPO Basket</Link>
                                </div>
                            </div>
                            <div>
                                <div className='row align-items-center m-0 p-0 assetHeader'>
                                    <div className='col-md-8 col-12 m-0 p-0'>
                                        <p className="p-0 m-0 welcome-heading">Pre-IPO Basket</p>
                                        <p className="p-0 m-0 welcome-sub-heading">Baskets help you build a long-term & diversified portfolio easily. They are simple, smart investment portfolios curated by experts.</p>
                                        <div className='row m-0 p-0 align-items-center'>
                                            <div className='col-12 m-0 p-0 refData d-flex'>
                                                <div className='refChange'>
                                                    <div className={`curPoint ${activeTab === "0" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("0")
                                                    }}>
                                                        <p className='m-0 p-0'>Currently Active</p>
                                                    </div>
                                                    <div className={`curPoint ${activeTab === "1" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("1")
                                                    }}>
                                                        <p className='m-0 p-0'>Recently Closed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12 m-0 p-0 text-md-right text-center  d-md-block d-none'>
                                        <img className='assetHeaderImg' src={preipo} alt="Pre-IPO Bucket header image" />
                                    </div>
                                </div>
                                <div className='row m-0 p-0'>
                                    {assetList && assetList.length > 0 ?
                                        assetList.map((asset, key) => {
                                            if (asset.disabletrading.toString() === activeTab) {
                                                return (
                                                    <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3' key={'preIPOBucket' + key}>
                                                        <div className='assetCard'>
                                                            <div className='assetCardTopDiv'>
                                                                <div className='row m-0 p-0'>
                                                                    <div className='col-4 m-0 p-0 px-2'>
                                                                        <img className='assetOppImg' src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${asset.logo}`} alt={`${asset.name} logo`} />
                                                                    </div>
                                                                    <div className='col-8 m-0 p-0'>
                                                                        <p className='m-0 p-0 fw-500 subheading2 text-left'>{asset.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className=''>
                                                                    <p className='m-0 p-0 text-left mt-3'>{asset.description}</p>
                                                                </div>
                                                            </div>
                                                            <div className='assetCardBottom'>
                                                                <div className='row m-0 p-0'>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{asset.suggested_holding_period}</p>
                                                                        <p className='m-0 p-0 subContent'>Suggested Holding Period</p>
                                                                    </div>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{asset.number_of_stocks}</p>
                                                                        <p className='m-0 p-0 subContent'>Number of Stocks</p>
                                                                    </div>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{formatCurrency(parseFloat(asset.minimum_investment ? asset.minimum_investment : 0))}</p>
                                                                        <p className='m-0 p-0 subContent'>Minimum investment</p>
                                                                    </div>
                                                                </div>
                                                                <div className='assetOppSector'>
                                                                    <div className='row m-0 p-0'>
                                                                        {asset.key_value.key_1 && asset.key_value.value_1 ?
                                                                            <div className='col-6 m-0 p-0 pr-1'>
                                                                                <p className='m-0 p-0'>{asset.key_value.key_1}</p>
                                                                                <p className='m-0 p-0 assetOppSectorValue'>{asset.key_value.value_1}</p>
                                                                            </div>
                                                                            : null}
                                                                        {asset.key_value.key_2 && asset.key_value.value_2 ?
                                                                            <div className='col-6 m-0 p-0 pl-1'>
                                                                                <p className='m-0 p-0'>{asset.key_value.key_2}</p>
                                                                                <p className='m-0 p-0 assetOppSectorValue'>{asset.key_value.value_2}</p>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                <div className="assetCardOppBtnDiv">
                                                                    <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setAssetClass("Pre-IPO Bucket")
                                                                        setAssetId(asset.asset_id)
                                                                        getAssetDetailById(asset.asset_id, "bucket/preIPO")
                                                                        history.push(`/user/assets/assetDetail/preipobucket/${asset.name}`)
                                                                    }}>View Details</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        : <div className='col-12 m-0 p-0'> <p className='font-weight-bold text-center'>No Pre-IPO Basket Opportunities available</p></div>}
                                </div>
                            </div>
                            <div id="faq" className='assetHeader'>
                                <p className="p-0 m-0 welcome-heading">FAQs</p>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>What are Pre IPO Baskets?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Pre IPO Baskets are ready made investment portfolios which help you diversify your portfolio with a single click. They individual stock components are handpicked by experts and made available to you in the format of a single investment product.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>How do I see my holdings?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Individual securities which are components of the basket will be transferred to your demat account. You can track these in your NSDL/CDSL or your broker account (Zerodha/Groww/ICICI Securities etc). On Altius you will be able to track price movements in the holdings section. </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Is this actively managed?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>No. Baskets only assist you to invest in multiple opportunities in a single click. These are not actively managed and therefore any further purchases or sale will have to be executed by you.</Typography>
                                    </AccordionDetails>
                                </Accordion><Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Do I get some benefit by investing in Baskets?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Baskets help an investor create a entire portfolio in a single click. Plus, basket also gives you a discount on the overall value as opposed buying the same quantity individually.</Typography>
                                    </AccordionDetails>
                                </Accordion><Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Do I get dividends from baskets?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Yes all corporate actions by any company will be effected directly to you by the issuing entity itself.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <PlaceHolderWrapper />
            </div>
            <BottomBarPage />
        </div>
    )
}