import React, { useEffect, useState } from 'react';
import { configs, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css'
import '../../../../assets/styles/Assets.css'
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import ToastPage from '../../../includes/ToastPage';
import { Link, useHistory, useParams } from 'react-router-dom';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import AUVerifyOrderPopupPage from './AUVerifyOrderPopupPage';


export default function AUAssetDetailPage() {

    const { setCurrModule, setcurrMod, setShowSuccess, currModule } = useLogin()

    const { assetList, getAssetsListByClass, assetDetail, assetClass, setAssetClass, assetId, setAssetId, getAssetDetailById, assetSlabsDetail, setAssetSlabsDetail, getAssetSlabDetailById, createAssetBuyOrder, setOrderType, verifyOrderPopup, setVerifyOrderPopup, assetOrderDetailById } = useAUAssetMgmtContext()

    const { assetClassName, assetName } = useParams()

    const [activeTab, setActiveTab] = useState("")
    const [quantity, setQuantity] = useState("")
    const [error, setError] = useState("")

    const [discount, setDiscount] = useState(0)

    const history = useHistory()

    useEffect(() => {
        if (assetDetail && assetDetail.section_data) {
            setActiveTab(assetDetail.section_data.snapshot ? "snapshot" : assetDetail.section_data.company_pitch ? "company_pitch" : assetDetail.section_data.overview ? "overview" : "")
        }
    }, [assetDetail])

    useEffect(async () => {
        const controller = new AbortController();

        document.title = `${assetName} | Altius Investech`

        // if (!configs.assetDisplay || configs.assetDisplay.length <= 0 || configs.assetDisplay.length === 1 && configs.assetDisplay[0].toLowerCase().includes("company")) {
        //     return history.push('/user/assets')
        // }
        if (assetClassName.toLowerCase() === "mld") {
            setAssetClass("MLD")
        } else if (assetClassName.toLowerCase() === "gsec") {
            setAssetClass("Government Securities")
        } else if (assetClassName.toLowerCase() === "startup") {
            setAssetClass("Start-ups")
        } else if (assetClassName.toLowerCase() === "preipobucket") {
            setAssetClass("Pre-IPO Bucket")
        } else if (assetClassName.toLowerCase() === "mixbucket") {
            setAssetClass("Mix Bucket")
        } else {
            setAssetClass("Bonds")
        }
        // setShowOrderPayment(false)
        setCurrModule(configs.modules.otherAsset)
        setcurrMod(configs.modules.otherAsset)
        setOrderType(configs.shareSub.buy)

        let temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
        let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

        await getAssetsListByClass(assetClassName.toLowerCase() === "preipobucket" ? "bucket/preIPO" : assetClassName.toLowerCase() === "mixbucket" ? "bucket/mix" : assetClassName)

        temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
        temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

        setTimeout(() => {
            if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                document.getElementById("phWrapper").classList.toggle("d-none")
            }
        }, 500)

        return () => {
            controller.abort();
        };
    }, [])

    useEffect(async () => {
        const controller = new AbortController();
        if (assetList) {
            var id;
            for (let i = 0; i < assetList.length; i++) {
                if (assetName === assetList[i].name) {
                    id = assetList[i].asset_id || assetList[i].id
                    setAssetId(id)
                    break;
                }
            }

            if (id) {
                let temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
                let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

                await getAssetDetailById(id, assetClassName.toLowerCase() === "preipobucket" ? "bucket/preIPO" : assetClassName.toLowerCase() === "mixbucket" ? "bucket/mix" : assetClassName)
                await getAssetSlabDetailById(id)
                if (assetClassName.toLowerCase().includes("bucket")) {
                    setQuantity(1)
                }

                temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
                temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

                setTimeout(() => {
                    if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                        document.getElementById("phWrapper").classList.toggle("d-none")
                    }
                }, 500)
            }



        }
        return () => {
            controller.abort();
        };
    }, [assetName])

    useEffect(() => {
        if (quantity && assetSlabsDetail && assetSlabsDetail.slabs && assetSlabsDetail.slabs.length > 0 && assetSlabsDetail.slabs[0].discount && (parseFloat(assetSlabsDetail.slabs[0].discount) > 0)) {
            setDiscount(assetSlabsDetail.slabs[0].discount && assetClassName.toLowerCase().includes("bucket") ? assetSlabsDetail.slabs[0].discount + 1 : assetSlabsDetail.slabs[0].discount)
        } else if (assetClassName.toLowerCase().includes("bucket")) {
            setDiscount(1)
        } else {
            setDiscount(0)
        }
    }, [quantity])

    async function createOrder() {
        try {
            if (assetClassName.toLowerCase() === "startup" && ((quantity * assetSlabsDetail.slabs[0].price) % assetSlabsDetail.slabs[0].price !== 0)) {
                setError(`Please enter amount  (in multiples of ${formatCurrency(parseFloat(assetSlabsDetail.slabs[0].price))})`)
            }
            else {
                document.getElementById("orderBtn").classList.toggle("d-none")
                document.getElementById("orderBtnLoader").classList.toggle("d-none")

                let response = await createAssetBuyOrder(assetId, quantity)
                if (response.success && response.success === configs.errorMessages.success) {
                    await assetOrderDetailById(response.data.order_id)
                    setError("")
                    setQuantity("")
                    if (response.data.order_status === "generated") {
                        setShowSuccess(true)
                        history.push('/user/assets/payment')
                    } else {
                        setVerifyOrderPopup(true)
                    }
                } else {
                    setError(response)
                }

                document.getElementById("orderBtn").classList.toggle("d-none")
                document.getElementById("orderBtnLoader").classList.toggle("d-none")
            }
        } catch (error) {
            console.error(error);
        }
    }

    const orderCard = () => {
        try {
            return (
                <div className='assetDetailBg'>
                    <div className='assetDetailPriceCard'>
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-6 m-0 p-0 assetDetailPriceCardTitle'>{assetClassName.toLowerCase() === "startup" ? "Minimum Investment" : "Current Price"}</div>
                            <div className='col-6 m-0 p-0 text-right'>{formatCurrency(parseFloat(assetSlabsDetail && assetSlabsDetail.slabs && assetSlabsDetail.slabs.length > 0 ? assetSlabsDetail.slabs[0].price : 0))} {assetClassName.toLowerCase() === "startup" ? "" : "per Unit"}</div>
                        </div>
                        {assetSlabsDetail && assetSlabsDetail.slabs && assetSlabsDetail.slabs.length > 0 ?
                            <div className='row m-0 p-0 align-items-center py-4'>
                                <div className='col-md-8 col-6 m-0 p-0 assetDetailPriceCardTitle'>{assetClassName.toLowerCase() === "startup" ? "Amount" : "Quantity"}</div>
                                <div className='col-md-4 col-6 m-0 p-0 text-right'>
                                    <input type="text" id="quantity" className="form-control defaultFont" pattern="[0-9]" inputMode="numeric" placeholder={assetClassName.toLowerCase() === "startup" ? "Enter Amount" : "Enter Quantity"} onChange={(e) => {
                                        e.preventDefault()
                                        if (assetClassName.toLowerCase() === "startup") {

                                            if (e.target.value % assetSlabsDetail.slabs[0].price === 0) {
                                                setQuantity(e.target.value / assetSlabsDetail.slabs[0].price)
                                                setError("")
                                            } else {
                                                setError(`Please enter amount  (in multiples of ${formatCurrency(parseFloat(assetSlabsDetail.slabs[0].price))})`)
                                            }
                                            setQuantity(e.target.value / assetSlabsDetail.slabs[0].price)

                                        } else {
                                            setQuantity(e.target.value)
                                        }
                                    }} />
                                </div>
                            </div>
                            : null
                        }
                        {quantity && discount ?
                            <div className='row m-0 p-0 align-items-center text-success'>
                                <div className='col-8 m-0 p-0 assetDetailPriceCardTitle'>Discount - {assetSlabsDetail.slabs[0].discount}% </div>
                                <div className='col-4 m-0 p-0 text-right'>{formatCurrency(parseFloat(quantity * assetSlabsDetail.slabs[0].price * ((assetSlabsDetail.slabs[0].discount) / 100)))}</div>
                            </div>
                            : null}
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-5 m-0 p-0 assetDetailPriceCardTitle'>Total Investment</div>
                            <div className='col-7 m-0 p-0 text-right'>{formatCurrency(parseFloat(quantity && assetSlabsDetail && assetSlabsDetail.slabs && assetSlabsDetail.slabs.length > 0 ? quantity * (assetSlabsDetail.slabs[0].price * ((100 - discount) / 100)) : 0))}</div>
                        </div>

                        <div className='row m-0 p-0 align-items-center text-primary'>
                            <div className='col-6 m-0 p-0 assetDetailPriceCardTitle'>{assetClassName.toLowerCase() === "startup" ? "Percent Blocked" : "Pre-Tax IRR"}</div>
                            <div className='col-6 m-0 p-0 text-right'>{assetClassName.toLowerCase() === "startup" ? `${formatNumber(assetDetail.percentage_booked)}%` : assetDetail.rate_of_return}</div>
                        </div>
                        <div className="form-check p-0 mt-2">
                            <p className="text-danger" id="calcShareError">{error}</p>
                        </div>
                        <div id="orderBtn" className="assetDetailInvestBtn curPoint" onClick={(e) => {
                            e.preventDefault()
                            createOrder()
                        }}>Invest Now</div>
                        <div className='row m-0 p-0 d-flex align-items-center justify-content-center'>
                            <div id="orderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            console.error(error);
        }
    }

    const bucketOrderCard = () => {
        try {
            return (
                <div className='assetDetailBg'>
                    <div className='assetDetailPriceCard'>
                        {assetDetail && assetDetail.bucket_object && assetDetail.bucket_object.length > 0 ?
                            <>
                                <div className='row m-0 p-0 align-items-center buy-card subContent font-weight-bold' key={`bucketasset`}>
                                    <div className='col-lg-6 col-5 m-0 p-0'>Asset Name</div>
                                    <div className='col-lg-2 col-3 m-0 p-0 text-right'>Quantity</div>
                                    <div className='col-4 m-0 p-0 text-right'>Price</div>
                                </div>
                                {assetDetail.bucket_object.map((element, key) => {
                                    return (
                                        <div className='row m-0 p-0 align-items-center buy-card subContent' key={`bucketasset${key}`}>
                                            <div className='col-lg-6 col-5 m-0 p-0 assetDetailPriceCardTitle'>{element.asset_name}</div>
                                            <div className='col-lg-2 col-3 m-0 p-0 text-right'>{formatNumber(element.quantity)}</div>
                                            <div className='col-4 m-0 p-0 text-right'>{formatCurrency(parseFloat(element.price))}</div>
                                        </div>
                                    )
                                })}
                            </>
                            : null}
                        {assetDetail && assetDetail.bucket_object && assetDetail.bucket_object.length > 0 ?
                            <>
                                <div className='row m-0 p-0 align-items-center pt-4'>
                                    <div className='col-md-8 col-6 m-0 p-0 assetDetailPriceCardTitle'>Gross Basket Value</div>
                                    <div className='col-md-4 col-6 m-0 p-0 text-right'>{formatCurrency(parseFloat((assetDetail.bucket_object.reduce((total, currentValue) => total + (currentValue.price * currentValue.quantity), 0))))}</div>
                                </div>
                                {discount ?
                                    <div className='row m-0 p-0 align-items-center text-success'>
                                        <div className='col-8 m-0 p-0 assetDetailPriceCardTitle'>Discount - {discount}% </div>
                                        <div className='col-4 m-0 p-0 text-right'>{formatCurrency(parseFloat((assetDetail.bucket_object.reduce((total, currentValue) => total + (currentValue.price * currentValue.quantity), 0) * (discount / 100))))}</div>
                                    </div>
                                    : null}
                                <div className='row m-0 p-0 align-items-center'>
                                    <div className='col-md-8 col-6 m-0 p-0 assetDetailPriceCardTitle'>Net Basket Value</div>
                                    <div className='col-md-4 col-6 m-0 p-0 text-right'>{formatCurrency(parseFloat((assetDetail.bucket_object.reduce((total, currentValue) => total + (currentValue.price * currentValue.quantity), 0) * ((100 - discount) / 100))))}</div>
                                </div>
                            </>
                            : null}
                        <div className='row m-0 p-0 align-items-center py-4'>
                            <div className='col-md-8 col-6 m-0 p-0 assetDetailPriceCardTitle'>Number of Baskets</div>
                            <div className='col-md-4 col-6 m-0 p-0 text-right'>
                                <form>
                                    <input type="number" id="quantity" className="form-control defaultFont" value={quantity} step="1" placeholder={assetClassName.toLowerCase() === "startup" ? "Enter Amount" : "Enter Quantity"} onChange={(e) => {
                                        setQuantity(e.target.value)
                                    }} />
                                </form>
                            </div>
                        </div>
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-5 m-0 p-0 assetDetailPriceCardTitle'>Total Investment</div>
                            <div className='col-7 m-0 p-0 text-right'>{assetDetail && assetDetail.bucket_object && assetDetail.bucket_object.length > 0 ? formatCurrency(parseFloat(assetDetail.bucket_object.reduce((total, currentValue) => total + (currentValue.price * currentValue.quantity * quantity), 0) * ((100 - discount) / 100))) : 0}</div>
                        </div>
                        <div className="form-check p-0 mt-2">
                            <p className="text-danger" id="calcShareError">{error}</p>
                        </div>
                        <div id="orderBtn" className="assetDetailInvestBtn curPoint" onClick={(e) => {
                            e.preventDefault()
                            createOrder()
                        }}>Invest Now</div>
                        <div className='row m-0 p-0 d-flex align-items-center justify-content-center'>
                            <div id="orderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            console.error(error);
        }
    }

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='assetContainer' className="assetMain assetDetailMain" >
                    <div id="assetOpp" className='mainDiv'>
                        <div className='row m-0 p-0 mb-3'>
                            <div className='col-12 m-0 p-0'>
                                <Link to={`/user/assets`}>Alternate Assets</Link> &gt;&gt; <Link to={`/user/assets/${assetClassName}`}>{assetClass}</Link> &gt;&gt; <Link to={`/user/assets/assetDetail/${assetClassName}/${assetDetail.name}`}>{assetDetail.name}</Link>
                            </div>
                        </div>
                        {assetDetail.video_link ?
                            <div className='videoDisplayParent d-lg-none'>
                                <iframe key={`video`} className="videoDisplay"
                                    src={assetDetail.video_link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                </iframe>
                            </div> : null}
                        <div className='row m-0 p-0'>
                            <div className='col-lg-8 col-12 m-0 p-0 assetDetailBg'>
                                <div id='assetDetail' className='assetDetailHeader'>

                                    <div className="row m-0 p-0">
                                        <div className='col-md-3 col-12 m-0 p-0 pr-3'>
                                            <img className='assetOppImg' src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${assetDetail.logo}`} alt={`${assetDetail.name} logo`} />
                                        </div>
                                        <div className='col-md-9 col-12 m-0 p-0'>
                                            <p className='m-0 p-0 font-weight-bold subheading2'>{assetDetail.name}</p>
                                            <p className='m-0 p-0 text-dark'>{assetDetail.description}</p>
                                            <div className='row m-0 p-0 mt-3 assetDetailHeaderSub'>
                                                <div className='col-md-4 col-6 m-0 p-0 pt-md-0 pt-3'>
                                                    <p className='m-0 p-0'>Asset Class</p>
                                                    <p className='m-0 p-0 text-dark'>{assetClass}</p>
                                                </div>
                                                <div className='col-md-4 col-6 m-0 p-0 pt-md-0 pt-3'>
                                                    <p className='m-0 p-0'>{assetClassName.toLowerCase().includes("bucket") ? "Suggested Holding Period" : assetClassName.toLowerCase() === "startup" ? "Campaign End Date" : "Pre-Tax IRR"}</p>
                                                    <p className='m-0 p-0 text-dark'>{assetClassName.toLowerCase().includes("bucket") ? assetDetail.suggested_holding_period : assetClassName.toLowerCase() === "startup" ? assetDetail.campaign_end_date : assetDetail.rate_of_return}</p>
                                                </div>
                                                <div className='col-md-4 col-6 m-0 p-0 pt-md-0 pt-3'>
                                                    <p className='m-0 p-0'>Minimum Investment</p>
                                                    <p className='m-0 p-0 text-dark'>{formatCurrency(parseFloat(assetDetail.minimum_investment ? assetDetail.minimum_investment : 0))}</p>
                                                </div>
                                            </div>
                                            <div className='row m-0 p-0 assetDetailHeaderSub'>
                                                {assetDetail.key_value && assetDetail.key_value.key_1 && assetDetail.key_value.value_1 ?
                                                    <div className='col-md-4 col-6 m-0 p-0'>
                                                        <p className='m-0 p-0'>{assetDetail.key_value.key_1}</p>
                                                        <div className='m-0 p-0 text-dark'>{assetDetail.key_value.value_1}</div>
                                                    </div>
                                                    : null}
                                                {assetDetail.key_value && assetDetail.key_value.key_2 && assetDetail.key_value.value_2 ?
                                                    <div className='col-md-4 col-6 m-0 p-0'>
                                                        <p className='m-0 p-0'>{assetDetail.key_value.key_2}</p>
                                                        <div className='m-0 p-0 text-dark'>{assetDetail.key_value.value_2}</div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0 d-lg-none d-block'>
                                        {assetDetail.disabletrading != "1" ? assetClassName.toLowerCase().includes("bucket") ? bucketOrderCard() : orderCard() : ""}
                                    </div>
                                </div>
                                <div className='assetDetailTab'>
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["snapshot"] ? <div className={`curPoint ${activeTab == "snapshot" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("snapshot")
                                    }}>Snapshot</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["company_pitch"] ? <div className={`curPoint ${activeTab == "company_pitch" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("company_pitch")
                                    }}>Company Pitch</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["key_investment_highlights"] ? <div className={`curPoint ${activeTab == "key_investment_highlights" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("key_investment_highlights")
                                    }}>Key Investment Highlights</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["deal_structure"] ? <div className={`curPoint ${activeTab == "deal_structure" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("deal_structure")
                                    }}>Deal Structure</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["deal_terms"] ? <div className={`curPoint ${activeTab == "deal_terms" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("deal_terms")
                                    }}>Deal Terms</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["faqs"] ? <div className={`curPoint ${activeTab == "faqs" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("faqs")
                                    }}>FAQs</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["documents"] ? <div className={`curPoint ${activeTab == "documents" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("documents")
                                    }}>Documents</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["about_the_team"] ? <div className={`curPoint ${activeTab == "about_the_team" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("about_the_team")
                                    }}>About the Team</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["overview"] ? <div className={`curPoint ${activeTab == "overview" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("overview")
                                    }}>Overview</div> : null}
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data["investing_rational"] ? <div className={`curPoint ${activeTab == "investing_rational" ? "assetDetailTabSelected" : ""} tabWidth`} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab("investing_rational")
                                    }}>Investing Rationale</div> : null}
                                </div>
                                <div id="fttSectionData" className='py-5'>
                                    {assetDetail && assetDetail.section_data && assetDetail.section_data[activeTab] ? <div dangerouslySetInnerHTML={{ __html: assetDetail.section_data[activeTab] }} />
                                        : <p className='m-0 p-0 pl-2 font-weight-bold'>No Data Found</p>}
                                </div>
                            </div>
                            <div className='col-md-4 col-12 m-0 p-0 pl-4 d-lg-block d-none'>
                                {assetDetail.video_link ?
                                    <div className='videoDisplayParent'>
                                        <iframe key={`video`} className="videoDisplay"
                                            src={assetDetail.video_link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                        </iframe>
                                    </div>
                                    : null}
                                {assetDetail.disabletrading != "1" ? assetClassName.toLowerCase().includes("bucket") ? bucketOrderCard() : orderCard() : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <PlaceHolderWrapper />
            </div>
            <BottomBarPage />
            <ToastPage />
            <AUVerifyOrderPopupPage />
        </div>
    )
}