import axios from 'axios';
import { configs } from '../../../../assets/Config';

// save basic detail
export function saveBasicDetail(token, name, email, countryCode, mobile) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = {
                "name": name,
                "mobile": mobile,
                "country_code": countryCode,
                "email": email
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/api/v2/registration/saveBasicDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// save basic detail verify OTP
export function saveBasicDetailVerifyOTP(token, verifyToken, OTP) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = {
                "token": verifyToken,
                "otp": OTP
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/api/v2/registration/verifyRegistrationOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// save basic detail resend OTP
export function saveBasicDetailResendOTP(token, verifyToken) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = {
                "token": verifyToken
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/api/v2/registration/resendRegistrationOTP`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}