import React, { useState } from 'react';
import { saveCustomerKYCBankDetail } from '../../../../services/apis/assistedUser/registration_kyc/AUKYCApi';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import '../../../../assets/styles/KYC.css';
import AUKYCMobileProgressBarPage from './AUKYCMobileProgressBarPage';
import { assistedUserToken } from '../../../../assets/Config';

export default function AUKYCBankDetailPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, nextPage, kycDetails, setKycDetails, editPage, getKYCSavedDetailContext } = useAURegister_KYCContext()
    const { token, logout, setLoginError, proxyEmail } = useLogin();

    const [bank_name, setBank_name] = useState("");
    const [account_number, setAccount_number] = useState("");
    const [ifsc_code, setIfsc_code] = useState("");
    const [error, setError] = useState("");
    const [nameChange, setNameChange] = useState(false);


    // validate ifsc
    function validateIFSC(ifscCode) {
        try {
            const re = /\b[a-zA-Z0-9]{11}\b/;
            let res = re.test(ifscCode);
            if (res !== true) setError("IFSC code must be 11 digit alpha-numeric.");

            return res;
        } catch (error) {

        }
    }

    // validate bank account number
    function validateBankAccountNumber(bankAccountNumber) {
        try {
            const re = /\b[0-9]{6,20}\b/;
            let res = re.test(bankAccountNumber);
            if (res !== true) setError("Please Enter a valid Account Number.");

            return res;
        } catch (error) {

        }
    }

    // validate bank name
    function validateBankName(bankName) {
        try {
            const res = bankName.trim() !== "" && bankName.length < 100;
            if (res !== true) setError("Please Enter a valid Bank Name.");

            return res;
        } catch (error) {

        }
    }

    // save customer demat details
    async function saveKYCBankDetail(e) {
        try {
            e.preventDefault();

            if (bank_name !== kycDetails.bank_details.bank_name || account_number !== kycDetails.bank_details.account_number || ifsc_code !== kycDetails.bank_details.ifsc_code) {
                const isIFSCValid = validateIFSC(ifsc_code);
                const isBankAccountNumberValid = validateBankAccountNumber(account_number);
                const isBankNameValid = validateBankName(bank_name);

                if (isIFSCValid && isBankNameValid && isBankAccountNumberValid) {
                    document.getElementById("bankBtn").classList.toggle("d-none")
                    document.getElementById("bankBtnLoader").classList.toggle("d-none")

                    let response = await saveCustomerKYCBankDetail(assistedUserToken(token, proxyEmail), bank_name, account_number, ifsc_code)
                    if (response && response.status === 200) {
                        if (response && response.data.data.bank_verification_status.toLowerCase() === "verified" && response.data.data.updated_bank_name != bank_name) {
                            kycDetails["bank_details"]["bank_name"] = response.data.data.updated_bank_name
                            setBank_name(response.data.data.updated_bank_name)
                            setNameChange(true);
                        } else {
                            kycDetails["bank_details"]["bank_name"] = bank_name
                        }
                        kycDetails["bank_details"]["account_number"] = account_number
                        kycDetails["bank_details"]["ifsc_code"] = ifsc_code
                        setKycDetails(kycDetails)
                        await getKYCSavedDetailContext(proxyEmail)
                        // setTimeout(() => {
                        //     document.getElementById("bankBtn").classList.toggle("d-none")
                        //     document.getElementById("bankBtnLoader").classList.toggle("d-none")

                        //     editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
                        // }, response.data.data.bank_verification_status.toLowerCase() === "verified" ? 3000 : 0)
                    } else if (response && response.status === 401) {
                        logout();
                        //setLoginError(configs.unauthorizedErrorMessage);
                    } else {
                        setError(response.data.error)
                    }
                }
            } else {
                editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // customer kyc bank detail ui code
    return (<div id="kycBankDetailContainer" className="main-kycPanel">
        <AUKYCMobileProgressBarPage />
        <p className="subheading2 text-theme mt-2">Bank Details</p>
        <p className="text-custom-grey mt-4 mb-1">Your bank account information</p>
        <form action="" className="mb-4">
            <div className="form-group">
                <label className="" htmlFor="user_bank_name">Bank Name</label>
                <input type="text" name="user_bank_name" id="user_bank_name" className="form-control" required value={bank_name} onChange={(e) => {
                    setBank_name(e.target.value)
                }} />{nameChange ? <p className="text-success mt-1 ">Your bank name has been updated as captured from Bank Verification.</p> : null}
            </div>
            <div className="form-group">
                <label className="" htmlFor="user_bank_accNo">Bank Account Number</label>
                <input type="text" minLength={6} maxLength={20} name="user_bank_accNo" id="user_bank_accNo" className="form-control" required value={account_number ? account_number : ""} onChange={(e) => {
                    setAccount_number(e.target.value)
                }} />
                {(kycDetails.bank_details.status && kycDetails.bank_details.status.toLowerCase() === "verified") && (kycDetails.bank_details.account_number && account_number && kycDetails.bank_details.account_number === account_number) ?
                    <p className="text-success mt-1 "><i className="far fa-check-circle mr-1"></i>Verified</p> : null}
            </div>
            <div className="form-group">
                <label className="" htmlFor="user_bank_ifsc">Bank IFSC Code</label>
                <input type="text" name="user_bank_ifsc" id="user_bank_ifsc" className="form-control" maxLength={11} pattern="[A-Z0-9]{11}" required value={ifsc_code ? ifsc_code : ""} onChange={(e) => {
                    setIfsc_code(e.target.value)
                }} />
                {(kycDetails.bank_details.status && kycDetails.bank_details.status.toLowerCase() === "verified") && (kycDetails.bank_details.account_number && account_number && kycDetails.bank_details.account_number === account_number) ?
                    <p className="text-success mt-1 "><i className="far fa-check-circle mr-1"></i>Verified</p> : null}
            </div>
            <div className="form-check p-0">
                <p className="text-danger" id="loginFormError">{error}</p>
            </div>
            <button id="bankBtn" className="btn btn-primary rounded px-4" onClick={(e) => {
                saveKYCBankDetail(e)
            }}>{editPage ? "Update" : "Next"}</button>
            <div id="bankBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
        </form>
    </div>)
}