import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { configs, downloadDoc, formatCurrency, formatNumber, showDocNewTab } from '../../../../assets/Config';
import { getExecutedOrders, getHoldings, getOpenOrders, getPaymentDetails, lendboxGetDetailByUserId, liquiloansGetDetailByUserId } from '../../../../services/apis/assistedUser/assets/AUCustomerProfileApi';
import { useClientDataContext } from '../../../../services/contexts/ClientDataContext';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';


export default function AUCustomerProfilePage() {

    const { setCurrModule, token, logout, setLoginError, allowedFunc } = useLogin()
    const { kycRequesterUserDetail, setKycRequesterUserDetail } = useAURegister_KYCContext();

    const [curTab, setCurTab] = useState("holdings")
    const [parentTab, setParentTab] = useState("holdings")
    const [orderType, setOrderType] = useState("buy")

    const [holdings, setHoldings] = useState("")
    const [openOrder, setOpenOrder] = useState("")
    const [execOrder, setExecOrder] = useState("")
    const [payment, setPayment] = useState("")
    const [remark, setRemark] = useState("")

    const [netPL, setNetPL] = useState(0)
    const [netChange, setNetChange] = useState(0)
    const [totalInvest, setTotalInvest] = useState(0)
    const [totalCurr, setTotalCurr] = useState(0)

    const [holdingLogModal, setHoldingLogModal] = useState(false)
    const [orderDetail, setOrderDetail] = useState("")

    const [lbKYC, setLBKYC] = useState("")
    const [lbPortfolio, setLBPortfolio] = useState("")
    const [lbInvest, setLBInvest] = useState("")
    const [lbWithdraw, setLBWithdraw] = useState("")

    const [lQKYC, setLQKYC] = useState("")
    const [lQPortfolio, setLQPortfolio] = useState("")
    const [lQInvest, setLQInvest] = useState("")
    const [lQWithdraw, setLQWithdraw] = useState("")


    const history = useHistory()

    useEffect(() => {
        setCurrModule(configs.modules.custProf)
        try {
            (async () => {
                if (kycRequesterUserDetail)
                    getHoldingsByCustId();
                else
                    history.push('/clients')
            })()
        } catch (error) {

        }

    }, [])

    // handle modal close
    const handleClose = () => {
        setHoldingLogModal(false)
    }

    // get holdings
    async function getHoldingsByCustId() {
        try {
            setHoldings("")
            let response = await getHoldings(token, kycRequesterUserDetail.id)
            if (response && response.status === 200) {
                setHoldings(response.data)
                computeNet(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get holdings
    async function getOpenOrdersByCustId(orderType) {
        try {
            setOpenOrder("")
            let response = await getOpenOrders(token, kycRequesterUserDetail.id, orderType)
            if (response && response.status === 200) {
                setOpenOrder(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get executed orders
    async function getExecutedOrdersByCustId(orderType) {
        try {
            setExecOrder("")
            let response = await getExecutedOrders(token, kycRequesterUserDetail.id, orderType)
            if (response && response.status === 200) {
                setExecOrder(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get payments
    async function getPaymentsByCustId() {
        try {
            setPayment("")
            let response = await getPaymentDetails(token, kycRequesterUserDetail.id)
            if (response && response.status === 200) {
                setPayment(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get lendbox portfolio
    async function getLendboxPortfolio() {
        try {
            setRemark("")
            let response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "portfolio")
            if (response && response.status === 200) {
                let data = []
                response.data.data.dashboard.forEach(element => {
                    if (element.currentInvestment || element.interestPerDay || element.totalInterestEarning || element.amountWithdrawn || element.availableToWithdraw) {
                        data.push(element)
                    }
                });
                setLBPortfolio(data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get lendbox invest order
    async function getLendboxInvest() {
        try {
            setRemark("")
            let response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "paymentOrder")
            if (response && response.status === 200) {
                setLBInvest(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get lendbox withdraw order
    async function getLendboxWithdraw() {
        try {
            setRemark("")
            let response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "debitOrder")
            if (response && response.status === 200) {
                setLBWithdraw(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get Liquiloans portfolio
    async function getLiquiloansPortfolio() {
        try {
            setRemark("")
            let response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "investor-dashboard")
            if (response && response.status === 200) {
                if (response.data.data.investment.total || response.data.data.locked_in_amount_bal || response.data.data.current_value || response.data.data.requested_withdrawal || response.data.data.withdrawable_amount) {
                    setLQPortfolio([response.data.data])
                } else {
                    setLQPortfolio([]);
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get Liquiloans invest order
    async function getLiquiloansInvest() {
        try {
            setRemark("")
            let response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "paymentOrder")
            if (response && response.status === 200) {
                setLQInvest(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get Liquiloans withdraw order
    async function getLiquiloansWithdraw() {
        try {
            setRemark("")
            let response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "debitOrder")
            if (response && response.status === 200) {
                setLQWithdraw(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }


    // compute net details for holdings
    function computeNet(data) {
        try {
            let percent = 0, tpl = 0, nc = 0, ti = 0, tc = 0, cd = [['Instrument', 'Share Percentage']];
            data.map((element) => {
                ti += parseFloat(parseFloat(element.quantity) * parseFloat(element.average_cost))
                tc += parseFloat(element.current_value)
                tpl += (parseFloat(element.profit_loss))
            })
            data.map((element) => {
                cd.push([element.company_name, parseFloat(parseFloat(element.current_value) / parseFloat(tc) * 100)])
            })
            nc = parseFloat(parseFloat(tc - ti) / parseFloat(ti) * 100)
            setNetPL(parseFloat(tpl))
            setNetChange(nc.toFixed(2))
            setTotalInvest(ti)
            setTotalCurr(tc)

        } catch (error) {
            console.error(error)
        }
    }

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='dashContainer' className="dash-main">
                    {kycRequesterUserDetail ? <section className="containerW m-2">
                        <div className="row d-flex align-items-start">
                            <div className="col-8">
                                <p className='dashTitle'>User Profile</p>
                                <p className="f-14 font-weight-bold text-primary mb-0">{kycRequesterUserDetail.name}</p>
                                <p className="f-12 text-custom-grey mb-0">{kycRequesterUserDetail.email}</p>
                            </div>
                            {/* <div className="col-4 text-right f-14">
                                <a href="#" className="custom-link"><i className="fas fa-pen mr-2"></i>Edit</a>
                            </div> */}
                        </div>
                        <div className="menu-list f-14 mt-3">
                            <ul className="custProfileUl">
                                <li><a href="#holdings" className={parentTab === "holdings" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                    e.preventDefault()
                                    setCurTab("holdings")
                                    setParentTab("holdings")
                                    getHoldingsByCustId()
                                }}>Holdings</a></li>
                                <li><a href="#openOrders" className={parentTab === "orders" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                    e.preventDefault()
                                    setOrderType("buy")
                                    setParentTab("orders")
                                    setCurTab("execOrder")
                                    getExecutedOrdersByCustId("buy")
                                }}>Orders</a></li>
                                <li><a href="#payment" className={parentTab === "payment" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                    e.preventDefault()
                                    setParentTab("payment")
                                    setCurTab("payment")
                                    getPaymentsByCustId()
                                }}>Online Payments</a></li>
                                <li><a href="#payment" className={parentTab === "lendbox" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                    e.preventDefault()
                                    setParentTab("lendbox")
                                    setCurTab("lbpo")
                                    getLendboxPortfolio()
                                }}>Lendbox</a></li>
                                <li><a href="#payment" className={parentTab === "liquiloan" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                    e.preventDefault()
                                    setParentTab("liquiloan")
                                    setCurTab("lqpo")
                                    getLiquiloansPortfolio()
                                }}>Liquiloans</a></li>
                            </ul>
                        </div>

                        {/* ----------------------------order sub type--------------------------  */}
                        {parentTab === "orders" ?
                            <div className="menu-list f-14 mt-3">
                                <ul className="custProfileUl">
                                    <li><a href="#openOrders" className={curTab === "openOrder" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("buy")
                                        setCurTab("openOrder")
                                        getOpenOrdersByCustId("buy")
                                    }}>Open Orders - Buy</a></li>
                                    <li><a href="#executedOrders" className={curTab === "execOrder" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("buy")
                                        setCurTab("execOrder")
                                        getExecutedOrdersByCustId("buy")
                                    }}>Executed Orders - Buy</a></li>
                                    <li><a href="#openOrdersSell" className={curTab === "openOrderSell" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("sell")
                                        setCurTab("openOrderSell")
                                        getOpenOrdersByCustId("sell")
                                    }}>Open Orders - Sell</a></li>
                                    <li><a href="#executedOrdersSell" className={curTab === "execOrderSell" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("sell")
                                        setCurTab("execOrderSell")
                                        getExecutedOrdersByCustId("sell")
                                    }}>Executed Orders - Sell</a></li>
                                </ul>
                            </div>
                            : null}

                        {/* ----------------------------lendbox sub type--------------------------  */}
                        {parentTab === "lendbox" ?
                            <div className="menu-list f-14 mt-3">
                                <ul className="custProfileUl">
                                    <li><a href="#payment" className={curTab === "lbpo" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lbpo")
                                        getLendboxPortfolio()
                                    }}>Portfolio</a></li>
                                    <li><a href="#payment" className={curTab === "lbio" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lbio")
                                        getLendboxInvest()
                                    }}>Investment Order</a></li>
                                    <li><a href="#payment" className={curTab === "lbwo" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lbwo")
                                        getLendboxWithdraw()
                                    }}>Withdraw Order</a></li>
                                </ul>
                            </div>
                            : null}
                        {/* ----------------------------liquiloans sub type--------------------------  */}
                        {parentTab === "liquiloan" ?
                            <div className="menu-list f-14 mt-3">
                                <ul className="custProfileUl">
                                    <li><a href="#payment" className={curTab === "lqpo" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lqpo")
                                        getLiquiloansPortfolio()
                                    }}>Portfolio</a></li>
                                    <li><a href="#payment" className={curTab === "lqio" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lqio")
                                        getLiquiloansInvest()
                                    }}>Investment Order</a></li>
                                    <li><a href="#payment" className={curTab === "lqwo" ? "custProfileUlActive text-primary" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lqwo")
                                        getLiquiloansWithdraw()
                                    }}>Withdraw Order</a></li>
                                </ul>
                            </div>
                            : null}
                        <div className="custProf-main ml-2">
                            {/* <!-- holdings section --> */}

                            {curTab === "holdings" ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {holdings && holdings.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Instrument</th>
                                                        <th scope="col" className="border-right-0">Qty</th>
                                                        <th scope="col" className="border-left-0 border-right-0">Avg Cost</th>
                                                        <th scope="col" className="border-left-0">LTP</th>
                                                        <th scope="col" className="border-right-0">Cur. Value</th>
                                                        <th scope="col" className="border-right-0 border-left-0">P&amp;L</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Change</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {holdings.map((element, key) => {
                                                        return (
                                                            <tr key={`holdtab` + key}>
                                                                <td className="border-left-0">{element.company_name}  <b>{element.company_status === "Now Listed" ? "(Now Listed)" : element.disabletrading ? "(Currently Not Traded)" : ""}</b></td>
                                                                <td className="border-right-0">{formatNumber(parseInt(element.quantity))}</td>
                                                                <td className="border-right-0 border-left-0">{formatCurrency(parseFloat(element.average_cost))}</td>
                                                                <td className="border-left-0">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatCurrency(parseFloat(element.ltp))}</td>
                                                                <td className="border-right-0">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatCurrency(parseFloat(element.current_value))}</td>
                                                                {parseFloat(element.profit_loss) < 0 ? <td className="text-success border-right-0 border-left-0 text-danger">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatNumber(parseFloat(parseFloat(element.profit_loss)))}</td> : <td className="text-success border-right-0 border-left-0 text-success">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatNumber(parseFloat(parseFloat(element.profit_loss)))}</td>}
                                                                {parseFloat(element.profit_loss) < 0 ? <td className="text-success border-right-0 border-left-0 text-danger">{element.company_status === "Now Listed" || element.disabletrading ? "-" : `${formatNumber(parseFloat(element.net_change))}%`}</td> : <td className="text-success border-right-0 border-left-0 text-success">{element.company_status === "Now Listed" || element.disabletrading ? "-" : `${formatNumber(parseFloat(element.net_change))}%`}</td>}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row -d-flex align-items-center">
                                            <div className="col-lg-6">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-6">
                                                        <span className="text-custom-grey h6">Total Investment - </span>
                                                        <span className={"text-custom-greyDark sub-desc mr-2"}>{formatCurrency((parseFloat(totalInvest)))}</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="text-custom-grey h6">Current Value - </span>
                                                        <span className={"text-custom-greyDark sub-desc mr-2"}>{formatCurrency((parseFloat(totalCurr)))}</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="text-custom-grey h6">Net P&amp;L - </span>
                                                        <span className={parseFloat(netPL) < 0 ? "text-custom-greyDark text-danger sub-desc mr-2" : "text-custom-greyDark text-success sub-desc mr-2"}>{formatNumber(parseFloat(netPL))}</span> <br />

                                                    </div>
                                                    <div className="col-6">
                                                        <span className="text-custom-grey h6">Net Change - </span>
                                                        <span className={parseFloat(netChange) < 0 ? "text-custom-greyDark text-danger sub-desc mr-2" : "text-custom-greyDark text-success sub-desc mr-2"}>{formatNumber(parseFloat(netChange))}%</span> <br />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                : null}

                            {/* <!-- open orders --> */}

                            {(curTab === "openOrder" || curTab === "openOrderSell") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {openOrder && openOrder.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Instrument</th>
                                                        <th scope="col" className="border-right-0">Date/Time</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Qty</th>
                                                        <th scope="col" className="border-left-0 border-right-0">Price/Share</th>
                                                        <th scope="col" className="border-left-0">Price</th>
                                                        <th scope="col" className="border-left-0">Upmark</th>
                                                        <th scope="col" className="border-left-0">Upmark Final Pay</th>
                                                        <th scope="col" className="border-right-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {openOrder.map((element, key) => {
                                                        return (
                                                            <tr key={`openOrder` + key}>
                                                                <td className="border-left-0">{element.order_id}</td>
                                                                <td className="border-left-0">{orderType === "buy" ? <sup className="buycheck sup-pos">Buy</sup> : <sup className="sellcheck sup-pos">Sell</sup>}{element.company_name}{element.bucket_object && element.bucket_object.length > 0 ? <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={async (e) => {
                                                                    e.preventDefault()
                                                                    setOrderDetail(element)
                                                                    setHoldingLogModal(true)
                                                                }}></i> : null}</td>
                                                                <td className="border-right-0">{element.order_time}</td>
                                                                <td className="border-right-0 border-left-0">{formatNumber(parseInt(element.quantity))}</td>
                                                                <td className="border-left-0 border-right-0">{formatCurrency(parseFloat(element.price_per_share))}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.price))}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.upmark))}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.upmark_final_pay))}</td>
                                                                <td className="border-right-0 text-capitalize">{element.statusMessage}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Open Orders</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}

                            {/* <!-- executed orders --> */}

                            {(curTab === "execOrder" || curTab === "execOrderSell") ?

                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {execOrder && execOrder.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Instrument</th>
                                                        <th scope="col" className="border-right-0">Date/Time</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Qty</th>
                                                        <th scope="col" className="border-left-0 border-right-0">Price/Share</th>
                                                        <th scope="col" className="border-left-0">Price</th>
                                                        <th scope="col" className="border-left-0">Upmark</th>
                                                        <th scope="col" className="border-left-0">Upmark Final Pay</th>
                                                        <th scope="col" className="border-right-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {execOrder.map((element, key) => {
                                                        return (
                                                            <tr key={`execOrder` + key}>
                                                                <td className="border-left-0">{element.order_id}</td>
                                                                <td className="border-left-0">{orderType === "buy" ? <sup className="buycheck sup-pos">Buy</sup> : <sup className="sellcheck sup-pos">Sell</sup>}{element.company_name}{element.bucket_object && element.bucket_object.length > 0 ? <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={async (e) => {
                                                                    e.preventDefault()
                                                                    setOrderDetail(element)
                                                                    setHoldingLogModal(true)
                                                                }}></i> : null}</td>
                                                                <td className="border-right-0">{element.order_time}</td>
                                                                <td className="border-right-0 border-left-0">{formatNumber(parseInt(element.quantity))}</td>
                                                                <td className="border-left-0 border-right-0">{formatCurrency(parseFloat(element.price_per_share))}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.price))}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.upmark))}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.upmark_final_pay))}</td>
                                                                <td className="border-right-0 text-capitalize">{element.statusMessage}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Executed Orders</p>}
                                        </div>
                                    </div>

                                </div>


                                : null}

                            {/* <!-- payment section --> */}

                            {curTab === "payment" ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {payment && payment.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Instrument</th>
                                                        <th scope="col" className="border-right-0">Date/Time</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Transaction ID</th>
                                                        <th scope="col" className="border-left-0">Amount</th>
                                                        <th scope="col" className="border-right-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {payment.map((element, key) => {
                                                        return (
                                                            <tr key={`payment` + key}>
                                                                <td className="border-left-0">{element.order_id}</td>
                                                                <td className="border-left-0"><sup className="buycheck sup-pos">Buy</sup>{element.company_name}</td>
                                                                <td className="border-right-0">{element.trans_date}</td>
                                                                <td className="border-right-0 border-left-0">{element.tracking_id}</td>
                                                                <td className="border-left-0">{formatCurrency(parseFloat(element.amount))}</td>

                                                                <td className="border-right-0 text-capitalize">{element.order_status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Payment Details found</p>}
                                        </div>
                                    </div>

                                </div>
                                : null}

                            {/* <!-- lendbox portfolio --> */}
                            {(curTab === "lbpo") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {lbPortfolio && lbPortfolio.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Investment Type</th>
                                                        <th scope="col" className="text-right">Current Investment</th>
                                                        <th scope="col" className="text-right">Interest per day</th>
                                                        <th scope="col" className="text-right">Total Interest Earning</th>
                                                        <th scope="col" className="text-right">Amount Withdrawn</th>
                                                        <th scope="col" className="text-right">Available to Withdraw</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lbPortfolio.map((element, key) => {
                                                        return (
                                                            <tr key={`lbIPortfolio` + key} >
                                                                <td className="border-left-0">{element.type}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.currentInvestment))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.interestPerDay))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.totalInterestEarning))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.amountWithdrawn))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.availableToWithdraw))}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Lendbox Portfolio data found</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}

                            {/* <!-- lendbox invvest order --> */}
                            {(curTab === "lbio") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {lbInvest && lbInvest.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Date</th>
                                                        <th scope="col" className="border-left-0" style={{ width: "50%" }}>Add On</th>
                                                        <th scope="col" className='text-right'>Amount</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lbInvest.map((element, key) => {
                                                        return (
                                                            <tr key={`lbInvest` + key} >
                                                                <td scope="col" className="border-left-0">{element.id}</td>
                                                                <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                <td scope="col" className="border-left-0">{element.add_on}</td>
                                                                <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Lendbox Investment Orders</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}
                            {/* <!-- lendbox withdraw order --> */}
                            {(curTab === "lbwo") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {lbWithdraw && lbWithdraw.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Date</th>
                                                        <th scope="col" className="border-left-0" style={{ width: "50%" }}>Add On</th>
                                                        <th scope="col" className='text-right'>Amount</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lbWithdraw.map((element, key) => {
                                                        return (
                                                            <tr key={`lbWithdraw` + key} >
                                                                <td scope="col" className="border-left-0">{element.id}</td>
                                                                <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                <td scope="col" className="border-left-0">{element.add_on}</td>
                                                                <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Lendbox Withdraw Orders</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}

                            {/* <!-- Liquiloans portfolio --> */}
                            {(curTab === "lqpo") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {lQPortfolio && lQPortfolio.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-right">Total Investment</th>
                                                        <th scope="col" className="text-right">Locked In Amount</th>
                                                        <th scope="col" className="text-right">Current Investment</th>
                                                        <th scope="col" className="text-right">Requested Withdrawal</th>
                                                        <th scope="col" className="text-right">Available to Withdraw</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lQPortfolio.map((element, key) => {
                                                        return (
                                                            <tr key={`lQIPortfolio` + key} >
                                                                <td className="text-right">{formatCurrency(parseFloat(element.investment.total))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.locked_in_amount_bal))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.current_value))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.requested_withdrawal))}</td>
                                                                <td className="text-right">{formatCurrency(parseFloat(element.withdrawable_amount))}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Liquiloans Portfolio data found</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}

                            {/* <!-- Liquiloans invvest order --> */}
                            {(curTab === "lqio") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {lQInvest && lQInvest.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Date</th>
                                                        <th scope="col" className="border-left-0">Scheme Id</th>
                                                        <th scope="col" className='text-right'>Amount</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lQInvest.map((element, key) => {
                                                        return (
                                                            <tr key={`lQInvest` + key} >
                                                                <td scope="col" className="border-left-0">{element.id}</td>
                                                                <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                <td scope="col" className="border-left-0">{element.scheme_id}</td>
                                                                <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Liquiloans Investment Orders</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}
                            {/* <!-- Liquiloans withdraw order --> */}
                            {(curTab === "lqwo") ?
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {lQWithdraw && lQWithdraw.length > 0 ? <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-left-0">Order Id</th>
                                                        <th scope="col" className="border-left-0">Date</th>
                                                        <th scope="col" className='text-right'>Amount</th>
                                                        <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lQWithdraw.map((element, key) => {
                                                        return (
                                                            <tr key={`lQWithdraw` + key} >
                                                                <td scope="col" className="border-left-0">{element.id}</td>
                                                                <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table> : <p>No Liquiloans Withdraw Orders</p>}
                                        </div>
                                    </div>

                                </div>

                                : null}
                        </div>
                        {/* ----------------------------basket order log--------------------------  */}
                        <Modal open={holdingLogModal} onClose={handleClose}>
                            <div className="modal-fade modalPosition" id="orderDetail" tabIndex="-1" role="dialog" aria-labelledby="addHoldingTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header border-0">
                                            <p className="modal-title text-primary subheading2 m-0 p-0 font-weight-bold" id="exampleModalLongTitle">Order {orderDetail.order_id} Breakup</p>
                                            <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                                e.preventDefault()
                                                handleClose()
                                            }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p><span className="text-custom-grey">Instrument -</span> {orderDetail.company_name}</p>
                                            <div className="table-responsive">
                                                {orderDetail && orderDetail.bucket_object.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" >ISIN</th>
                                                            <th scope="col" >Asset Name</th>
                                                            <th scope="col" className='text-right' >Quantity</th>
                                                            <th scope="col" className='text-right' >Rate</th>
                                                            <th scope="col" className='text-right' >Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderDetail.bucket_object.map((element, key) => {
                                                            return (
                                                                <tr key={`holdtab` + key} className="subContent">
                                                                    <td >{element.isin}</td>
                                                                    <td >{element.asset_name}</td>
                                                                    <td className='text-right'>{formatNumber(parseInt(element.quantity))}</td>
                                                                    <td className='text-right'>{formatCurrency(parseFloat(element.price / element.quantity))}</td>
                                                                    <td className='text-right'>{formatCurrency(parseFloat(element.price))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr className="subContent">
                                                            <td colSpan={4} className='text-right font-weight-bold'>Total Amount</td>
                                                            <td className='text-right'>{formatCurrency(parseFloat(orderDetail.price))}</td>
                                                        </tr>
                                                    </tbody>
                                                </table> : <div className="loader"></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                        : <div className="loader"></div>}
                </div>
            </div >
            <BottomBarPage />
        </div>
    )
}