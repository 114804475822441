import React from 'react';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import '../../../../assets/styles/KYC.css'

export default function AUKYCMobileProgressBarPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, currPage, nextPage } = useAURegister_KYCContext()

    // customer kyc main mobile ui code
    return (<div id="kycMainMobile" className="progress-body mt-2 d-md-none">
        <div className="progress-container">
            <div className="progress" id="progress"> </div>
            <div className={currPage === 1 ? "circle active" : "circle"}><a onClick={() => custKYCSwitchPage(1)} className="custom-link">1</a></div>
            <div className={currPage === 2 ? "circle active" : "circle"}><a onClick={() => custKYCSwitchPage(2)} className="custom-link">2</a></div>
            <div className={currPage === 3 ? "circle active" : "circle"}><a onClick={() => custKYCSwitchPage(3)} className="custom-link">3</a></div>
            <div className={currPage === 4 ? "circle active" : "circle"}><a onClick={() => custKYCSwitchPage(4)} className="custom-link">4</a></div>
        </div>
    </div>)
}