import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { configs } from "../../assets/Config";
import '../../assets/styles/CustomerLogin.css';
import { createpassword, resetpassword } from "../../services/apis/CreatePassApi";
import { useLogin } from "../../services/contexts/CustomerLoginContext";
import { useCustomerSignUpContext } from "../../services/contexts/CustomerSignUpContext";
import { useForgotPasswordContext } from "../../services/contexts/ForgotPasswordContext";
import imgdn from '../../assets/images/leftBG.png'

export default function CreatePasswordPage() {

    const { signUpToken } = useCustomerSignUpContext();
    const { fgtPswdToken } = useForgotPasswordContext();
    const { saveLoginDetails } = useLogin();
    const history = useHistory();

    // hook declaration to process password creation
    const [password, setPassword] = useState("");
    const [cnfPass, setCnfPass] = useState("");
    const [passError, setPassError] = useState("");

    const [checks, setChecks] = useState({
        minChar: false,
        capital: false,
        small: false,
        number: false,
        special: false
    })

    // password real time validator
    async function passChecker() {
        try {
            let data = checks
            if (/[A-Z]{1,}/.test(password)) {
                data.capital = true
            } else {
                data.capital = false
            }

            if (/[a-z]{1,}/.test(password)) {
                data.small = true
            } else {
                data.small = false
            }

            if (/[0-9]{1,}/.test(password)) {
                data.number = true
            } else {
                data.number = false
            }

            if (/[@$!%*?&]{1,}/.test(password)) {
                data.special = true
            } else {
                data.special = false
            }

            if (password.length >= 8) {
                data.minChar = true
            } else {
                data.minChar = false
            }

            setChecks({
                ...checks,
                ...data
            })
        } catch (error) {
            console.error(error)
        }
    }

    // create password
    async function createPass(e) {
        try {
            e.preventDefault();

            if (!configs.passRegex.test(password)) {
                setPassError(configs.passValidationMessage)
            } else if (cnfPass !== password) {
                setPassError(configs.errorMessages.passNotMatch)
            } else {
                if (signUpToken) {
                    let response = await createpassword(password, cnfPass, signUpToken)
                    if (response && response.status === 200) {
                        saveLoginDetails(response.data)
                        history.push('/dashboard')
                    } else if (response && response.status === 401) {
                        history.push('/signup')
                    }
                    else {
                        setPassError(response.data.error)
                    }
                } else if (fgtPswdToken) {
                    let response = await resetpassword(password, cnfPass, fgtPswdToken)
                    if (response && response.status === 200) {
                        history.push('/login')
                    } else if (response && response.status === 401) {
                        history.push('/forgotPassword')
                    } else {
                        setPassError(response.data.error)
                    }
                }
            }
        } catch (error) {

        }

    }

    // create password ui code
    return (
        <div >
            <div>
                <p className="p-0 m-0 welcome-heading">Set up your Password</p>
                <p className="p-0 m-0 welcome-sub-heading f-14">Please set a secure password for easy login</p>
            </div>
            <div className="loginForm">
                <div className="form-group mt-1 mb-2">
                    <input type="password" className="form-control login-screen-input f-14" name="password" placeholder="New Password" onChange={(e) => {
                        setPassword(e.target.value)
                    }} onKeyUp={passChecker} />
                </div>
                <div className="form-group mt-2 mb-2">
                    <p className="m-0 ml-2 p-0 f-12"><span style={{ color: "#009FE3" }}>
                        Password should meet following criteria:

                    </span> </p>
                    <ul style={{ color: "#009FE3" }} className=" f-12">
                        <li>1 Capital Character {checks.capital ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
                        <li>1 Small Character {checks.small ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
                        <li>1 Special Character {checks.special ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
                        <li>1 Number {checks.number ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
                        <li>Minimum 8 Characters {checks.minChar ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
                    </ul>
                </div>
                <div className="form-group mt-4 mb-2">
                    <input type="password" className="form-control login-screen-input f-14" name="confirmPassword" placeholder="Confirm Password" onChange={(e) => {
                        setCnfPass(e.target.value)
                    }} onKeyUp={(e) => {
                        cnfPass && password !== cnfPass ? setPassError("Passwords do not match.") : setPassError("")
                    }} />
                </div>

                <div className="ml-2">
                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{passError}</p>
                </div>
                <div className="form-group mt-2 mb-0">
                    <button className="signIn-button curPoint" disabled={password === cnfPass ? false : true} onClick={createPass}>{fgtPswdToken ? "Reset Password" : "Create Account"}</button>
                </div>

            </div>
        </div >
        // <section>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-6 d-none d-sm-block p-0 m-0">
        //                 <div className="login-screen-wallpaper">
        //                     <img src={imgdn} className="w-100 h-100" alt="building" />
        //                 </div>
        //             </div>
        //             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
        //                 <div className="w-100">
        //                     <div className="login-screen-logo">
        //                         <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="logo" />
        //                     </div>
        //                     <form>
        //                         <div className="form-group">
        //                             <h4 className="mb-0 text-custom-greyDark">{configs.hardCodeTexts.createPass.heading}</h4>
        //                             <p className="mt-0 text-custom-grey">{configs.hardCodeTexts.createPass.subHeading}</p>
        //                         </div>
        //                         <div className="form-group mt-5">
        //                             <input type="password" className="form-control login-screen-input mb-1" name="user_newPassword" placeholder="New Password" required onChange={(e) => {
        //                                 setPassword(e.target.value)
        //                             }} onKeyUp={passChecker} />
        //                             <p className="f-12">
        //                                 Password should meet following criteria:
        //                                 <ul>
        //                                     <li>1 Capital Character {checks.capital ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
        //                                     <li>1 Small Character {checks.small ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
        //                                     <li>1 Special Character {checks.special ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
        //                                     <li>1 Number {checks.number ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
        //                                     <li>Minimum 8 Characters {checks.minChar ? <i className="fa fa-check text-success" aria-hidden="true"></i> : <i className="fa fa-times text-danger" aria-hidden="true"></i>}</li>
        //                                 </ul>
        //                             </p>

        //                         </div>
        //                         <div className="form-group mt-5">
        //                             <input type="password" className="form-control login-screen-input" name="user_confirmPassword" placeholder="Confirm Password" required onChange={(e) => {
        //                                 setCnfPass(e.target.value);
        //                             }} onKeyUp={(e) => {
        //                                 cnfPass && password !== cnfPass ? setPassError("Passwords do not match.") : setPassError("")
        //                             }} />
        //                         </div>
        //                         <div className="form-check p-0">
        //                             <p className="text-danger" id="createPassError">{passError}</p>
        //                         </div>
        //                         <div className="form-group text-center">
        //                             <button type="submit" className="btn btn-sm btn-custom btn-primary rounded px-3 login-screen-sign-in-button" disabled={password === cnfPass ? false : true} onClick={createPass}>{fgtPswdToken ? configs.hardCodeTexts.createPass.resetPassBtn : configs.hardCodeTexts.createPass.createAccountBtn}</button>
        //                         </div>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}
