import React, { useState } from 'react';
import { assistedUserToken } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css';
import { saveCustomerKYCDematDetail } from '../../../../services/apis/assistedUser/registration_kyc/AUKYCApi';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import KYCMobileProgressBarPage from './AUKYCMobileProgressBarPage';

export default function AUKYCDematDetailPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, nextPage, kycDetails, setKycDetails, editPage, getKYCSavedDetailContext } = useAURegister_KYCContext()
    const { token, logout, setLoginError, proxyEmail } = useLogin();

    const [dp_id, setDp_id] = useState("");
    const [client_id, setClient_id] = useState("");
    const [error, setError] = useState("");

    // validate dp id
    function validateDpId(dpId) {
        try {
            const re = /\b[a-zA-Z0-9]{8}\b/;
            let res = re.test(dpId);
            if (res !== true) setError("DP Id must be 8 digit Alpha Numeric.");
            return res;
        } catch (error) {

        }
    }

    // validate client id
    function validateClientId(clientId) {
        try {
            const re = /^\d{8}$/;
            let res = re.test(clientId);
            if (res !== true) setError("Client Id must be 8 digit Numeric.");
            return res;
        } catch (error) {

        }
    }

    // save customer demat details
    async function saveKYCDematDetail(e) {
        try {
            e.preventDefault();
            if (dp_id !== kycDetails.demat_details.dp_id || client_id !== kycDetails.demat_details.dp_id) {
                const isClientIdValid = validateClientId(client_id);
                const isDpIdValid = validateDpId(dp_id);
                if (isClientIdValid && isDpIdValid) {
                    document.getElementById("dematBtn").classList.toggle("d-none")
                    document.getElementById("dematBtnLoader").classList.toggle("d-none")

                    let response = await saveCustomerKYCDematDetail(assistedUserToken(token, proxyEmail), dp_id, client_id)
                    if (response && response.status === 200) {
                        kycDetails["demat_details"]["dp_id"] = dp_id
                        kycDetails["demat_details"]["client_id"] = client_id
                        await getKYCSavedDetailContext(proxyEmail)
                        setKycDetails(kycDetails)
                        editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
                    } else if (response && response.status === 401) {
                        logout();
                        //setLoginError(configs.unauthorizedErrorMessage);
                    } else {
                        setError(response.data.error)
                    }

                    // document.getElementById("dematBtn").classList.toggle("d-none")
                    // document.getElementById("dematBtnLoader").classList.toggle("d-none")
                }
            } else {
                editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
            }
        } catch (error) {

        }
    }

    // customer kyc demat detail ui code
    return (<div id="kycDematDetailContainer" className="main-kycPanel">
        <KYCMobileProgressBarPage />
        <p className="subheading2 text-theme mt-2">Demat Details</p>
        <p className="text-custom-grey mt-4 mb-1">This is required for transfering securites to your demat account.</p>
        <form action="">
            <div className="form-group">
                <label className="" htmlFor="user_DP_Id">DP Id</label>
                <input type="text" name="user_DP_Id" id="user_DP_Id" className="form-control" maxLength={8} pattern="[A-Z0-9]{8}" required value={dp_id ? dp_id : ""} onChange={(e) => {
                    setDp_id(e.target.value)
                }} />
                {/* <p className="subContent">The Depository ID is the identification number of the depository participant member i.e. the entity where your Demat account is maintained.</p> */}
            </div>
            <div className="form-group">
                <label className="" htmlFor="user_client_Id">Client Id</label>
                <input type="text" name="user_client_Id" id="user_client_Id" className="form-control" maxLength={8} pattern="[A-Z0-9]{8}" required value={client_id ? client_id : ""} onChange={(e) => {
                    setClient_id(e.target.value)
                }} />
                {/* <p className="subContent">A client ID is a unique eight-digit number generated by the depository participants to easily identify their clients. This number is generated by using the in-house formula of your chosen brokerage house.</p> */}
            </div>
            <div className="form-check p-0 ">
                <p>Your Demat Account Number = DP ID (first 8 characters) + Client ID (last 8 digits)<br /><br />This can be found on your broker / DP login, Holding Statement, DIS booklet. Need help? <a href="https://altiusinvestech.com/blog/where-to-find-your-demat-account-number/" target="_blank" className="custom-link">Click Here</a></p>
            </div>
            <div className="form-check p-0">
                <p className="text-danger" id="loginFormError">{error}</p>
            </div>
            <button id="dematBtn" className="btn btn-primary rounded px-4" onClick={(e) => {
                saveKYCDematDetail(e)
            }}>{editPage ? "Update" : "Next"}</button>
            <div id="dematBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
        </form>
    </div>)
}