import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { configs, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/Shares.css'
import { useCustomerKYCContext } from '../../../../services/contexts/CustomerKYCContext';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import ToastPage from '../../../includes/ToastPage';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import SuccessModalPage from '../../../includes/SuccessModalPage';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import { useClientDataContext } from '../../../../services/contexts/ClientDataContext';

export default function AUAssetPaymentPage() {

    const { saveUTRByOrder, assetGeneratePaymentLinkById, assetOrderDetail, payGoBack, assetManualPaymentById, payKYCDetail, setPayKYCDetail } = useAUAssetMgmtContext()
    const { setShowSuccess, token, logout, setsuccessModal, setCurrModule, setcurrMod } = useLogin()
    const { setActiveDetail } = useClientDataContext()

    const [offLineModal, setOffLineModal] = useState(false)
    const [utrNumber, setUtrNumber] = useState("")
    const [bankName, setBankName] = useState("")
    const [utrList, setUtrList] = useState([])
    const [error, setError] = useState("")
    const [opError, setOPError] = useState("")
    const [CouponError, setCouponError] = useState("")
    const [couponDiscount, setcouponDiscount] = useState(0)
    const [paymentLink, setPaymentLink] = useState("")
    const [paymentLinkCopy, setPaymentLinkCopy] = useState("")

    const history = useHistory()

    useEffect(() => {
        setCurrModule(configs.modules.otherAsset)
        setcurrMod(configs.modules.otherAsset)
    }, [])

    // offline modal functions
    const handleClose = () => {
        setOffLineModal(false)
    }

    // add utr function
    function addUtr() {
        try {
            if (!utrNumber.trim() || utrNumber.length > 30) {
                setError("Please enter a valid UTR number.")
            } else if (!bankName.trim()) {
                setError("Please enter a valid Bank Name.")
            } else {
                let utr = utrList
                utr.push({
                    "utr_num": utrNumber,
                    "bank_name": bankName
                })
                setUtrNumber("")
                setUtrList(utr)
                setError("")
            }
        } catch (error) {

        }
    }

    // delete utr
    function deleteUtr(element) {
        try {
            let utr = utrList
            let index = utr.indexOf(element)
            utr.splice(index, 1)
            setUtrList(utr)
            setError("")
        } catch (error) {

        }
    }

    // submit utr details
    async function saveUTRDetails() {
        try {
            if (!utrList) {
                setError("Please enter a valid UTR number to submit.")
            } else {
                let response = await assetManualPaymentById(assetOrderDetail.order_id, utrList)
                if (response && response.success === configs.errorMessages.success) {
                    setError("")
                    setUtrList([])
                    setOffLineModal(false)
                    setShowSuccess(true)
                    setActiveDetail("Client Orders")
                    history.push('/clients')
                } else {
                    setError(response)
                }
            }
        } catch (error) {

        }
    }

    // online payment initiate
    async function onlinePay() {
        try {

            let response = await assetGeneratePaymentLinkById(assetOrderDetail.order_id)
            if (response && response.success) {
                setPaymentLink(response.data.link_url)
                setOPError("")
            } else {
                setOPError(response)
            }
        } catch (error) {
            console.error(error)
        }
    }


    // apply coupon
    async function applyCoupon() {
        try {
            // if (!couponCode) {
            //     setCouponError("Please enter coupon code.")
            // } else {
            //     document.getElementById("couponBtn").classList.toggle("d-none")
            //     document.getElementById("couponBtnLoader").classList.toggle("d-none")

            //     let response = await applyCouponCode(token, assetOrderDetail.order_id, couponCode)
            //     if (response && response.status === 200) {
            //         setCouponError("")
            //         let temp1 = ((assetOrderDetail.amount_to_be_paid * response.data.discount) / 100)
            //         let discount = (response.data.max_discount < temp1) ? response.data.max_discount : temp1
            //         setcouponDiscount(discount)
            //         setsuccessModal(true)
            //     } else if (response && response.status === 401) {
            //         logout()
            //         //setLoginError(configs.unauthorizedErrorMessage)
            //     } else {
            //         setCouponError(response.data.error)
            //     }

            //     document.getElementById("couponBtn").classList.toggle("d-none")
            //     document.getElementById("couponBtnLoader").classList.toggle("d-none")
            // }
        } catch (error) {
            console.error(error)
        }
    }

    // share create order ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                <div id="orderPayment">
                    <div className="row d-flex align-items-center shares-create-header">
                        <div className="col-lg-12">
                            <p className="heading4 font-weight-bold text-custom-grey mb-0">
                                {/* <a href="#" className="text-primary" onClick={(e) => {
                                e.preventDefault()
                                if (payGoBack === configs.modules.orderBook) {
                                    history.push("/orderBook")
                                }
                                else {
                                    history.goBack()
                                }
                            }}><i className="fas fa-arrow-circle-left mr-2"></i></a> */}
                                Order Summary</p>
                        </div>
                    </div>
                    <div id="sharesCreateOrder" className="shares-create-order">
                        {assetOrderDetail ? <div className="col-lg-8">
                            <p className="text-primary subheading2 font-weight-bold"><span className="buycheck subContent">Buy</span>{assetOrderDetail.company_name}</p>
                            <p className="m-0">Order ID : <b>{assetOrderDetail.order_id}</b></p>
                            <p className="my-1 text-custom-grey font-weight-normal">Order Summary</p>
                            <div className="row">
                                <div className="col-6">
                                    {assetOrderDetail.asset_class === "STARTUP" ? "Units" : "Quantity"}
                                </div>
                                <div className="col-6">
                                    {formatNumber(parseInt(assetOrderDetail.quantity))}
                                </div>
                                <div className="col-6">
                                    {assetOrderDetail.asset_class === "STARTUP" ? "Investment/unit" : "Price/Security"}
                                </div>
                                <div className="col-6">
                                    {formatCurrency(parseFloat(parseFloat(assetOrderDetail.price) / parseFloat(assetOrderDetail.quantity)))}
                                </div>
                                <div className="col-6">
                                    {assetOrderDetail.asset_class === "STARTUP" ? "Total Investment Amount" : "Estimated Cost"}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {formatCurrency(assetOrderDetail.price)}
                                </div>
                                <div className="col-6">
                                    Referral Bonus applied
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {formatCurrency(assetOrderDetail.referral_bonus)}
                                </div>
                            </div>
                            {/* {haveCoupon ?
                            <div className='row align-items-center mt-2'>
                                <div className="col-md-6 col-7 f-12">
                                    <input type="text" className="form-control f-12" id="couponCde" value={couponCode} onChange={(e) => {
                                        setCouponCode(e.target.value)
                                        setCouponError("")
                                    }} />
                                </div>
                                {couponDiscount && couponDiscount > 0 ? <div className="col-md-6 col-5 p-0 f-12">
                                    <button id="couponRemoveBtn" type="submit" className="side-nav-btn-danger" onClick={(e) => {
                                        e.preventDefault()
                                        setcouponDiscount(0)
                                        setCouponCode("")
                                    }}>Remove Coupon</button>
                                </div>
                                    :
                                    <div className="col-md-6 col-5 p-0 f-12">
                                        <button id="couponBtn" type="submit" className="side-nav-btn" onClick={(e) => {
                                            e.preventDefault()
                                            applyCoupon()
                                        }}>Apply Coupon</button>
                                        <div id="couponBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                    </div>
                                }
                            </div>

                            :
                            <a href='#' onClick={(e) => {
                                e.preventDefault();
                                setHaveCoupon(true)
                            }}>Have a Coupon? Apply It!</a>
                        } */}
                            {CouponError ? <div className="form-check p-0">
                                <p className="text-danger mb-1" id="opError">{CouponError}</p>
                            </div> : null}
                            <div className='row'>
                                <div className={`col-6 ${parseInt(couponDiscount) > 0 ? "text-success" : ""}`}>
                                    Coupon Discount
                                </div>
                                <div className={`col-6 font-weight-bold ${parseInt(couponDiscount) > 0 ? "text-success" : ""}`}>
                                    {formatCurrency(couponDiscount)}
                                </div>
                                <div className="col-6">
                                    Payable Amount
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {formatCurrency(assetOrderDetail.amount_to_be_paid - couponDiscount)}
                                </div>
                            </div>
                            <hr className='my-2' />
                            {assetOrderDetail.asset_class !== "STARTUP" ?
                                <form action="">
                                    {/* <p className="m-0 text-custom-grey font-weight-normal">Select Payment Mode</p>
                                    <div className="form-row mb-3">
                                        <div className="col-lg-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="netradio" value="Net banking" onChange={(e) => {
                                                    // setOpMethod(e.target.value)
                                                }} />
                                                <label className="form-check-label" htmlFor="netradio">
                                                    Net Banking
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="upiradio" value="UPI" onChange={(e) => {
                                                    // setOpMethod(e.target.value)
                                                }} />
                                                <label className="form-check-label" htmlFor="upiradio">
                                                    UPI
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="debitradio" value="Debit Card" onChange={(e) => {
                                                    // setOpMethod(e.target.value)
                                                }} />
                                                <label className="form-check-label" htmlFor="debitradio">
                                                    Debit Card
                                                </label>
                                            </div>
                                        </div>
                                        <div className='row m-0 p-0'>
                                            <p className='m-0 p-0 pl-1 mt-2 subContent'><i className="fa fa-info-circle" aria-hidden="true"></i> In case of UPI payments, it might take 5-7 minutes to confirm payment</p>
                                        </div>
                                    </div> */}
                                    <div className="form-group mb-1">
                                        <a href="#" className="custom-link" onClick={(e) => {
                                            e.preventDefault()
                                            setOffLineModal(true)
                                        }}>Having Trouble in online Payment?</a>
                                    </div>
                                    <div className="form-check p-0">
                                        <p className='m-0'>By proceeding with payment, I agree with <a href="https://trade.altiusinvestech.com/TermsofUse.pdf" target="_blank">Terms of Use</a> and <a href="https://trade.altiusinvestech.com/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a>.</p>
                                    </div>
                                    {paymentLink ? <div className="form-check p-0 paymentLink curPoint" onClick={(e) => {
                                        e.preventDefault()
                                        navigator.clipboard.writeText(`${paymentLink}`);
                                        setPaymentLinkCopy(true)
                                        setTimeout(() => {
                                            setPaymentLinkCopy(false)
                                        }, 2000)
                                    }}>
                                        <p className="text-success m-0 subContent" id="payLink">{paymentLink}</p>
                                        <p className='m-0' >
                                            {paymentLinkCopy ? <i className="fa fa-check text-primary cursor" ></i> : <i className="far fa-copy text-primary cursor" ></i>}
                                        </p>
                                    </div>
                                        : null}
                                    <div className="form-check p-0">
                                        <p className="text-danger m-0 subContent" id="opError">{opError}</p>
                                    </div>
                                    <button type="submit" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        onlinePay()
                                    }}>Generate Payment Link</button>
                                </form> :
                                <button type="submit" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    setOffLineModal(true)
                                }}>Enter Payment Details</button>
                            }
                        </div>
                            : null}
                        <div className="col-lg-4">
                            <div >
                                <p className="mt-4 text-custom-grey font-weight-normal">Billing Details</p>
                                {payKYCDetail ? <p>
                                    <span className="sub-details">Name: </span>{payKYCDetail.user_name ? payKYCDetail.user_name : payKYCDetail.name}<br />
                                    <span className="sub-details">Email: </span>{payKYCDetail.user_email ? payKYCDetail.user_email : payKYCDetail.email}<br />
                                    <span className="sub-details">Mobile: </span>+{payKYCDetail.mobile}<br />
                                </p> : null}
                            </div>
                        </div>
                    </div>

                    {/* take offline payment details */}
                    <Modal open={offLineModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                        <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <p className="text-custom-grey subheading2 m-0 p-0 font-weight-bold" id="exampleModalLabel">NEFT / RTGS Details</p>
                                        <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                            e.preventDefault()
                                            setOffLineModal(false)
                                        }}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        Get in touch with us at <a href="tel:8240614850" className="custom-link">8240614850</a> or write us at&nbsp;
                                        <a href="mailto:support@altiusinvestech.com" className="custom-link">support@altiusinvestech.com</a> <br />
                                        <p className="mt-4">
                                            Also, you can transfer the amount via NEFT / RTGS to us on account number
                                            mentioned below. The payment Will reflect within 24 hours of receipt of payment.
                                        </p>
                                        <p className="mt-4"><span className="text-custom-grey mr-2">Account Name</span>Altius Investech Private Limited (same for both banks)</p>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <p>
                                                    <span className="text-custom-grey mr-2">Bank Name</span>ICICI Bank <br />
                                                    <span className="text-custom-grey mr-2">Account No</span>000605028824 <br />
                                                    <span className="text-custom-grey mr-2">IFSC</span>ICIC0000006 <br />
                                                    <span className="text-custom-grey mr-2">Branch</span>R N Mukherjee Road
                                                </p>
                                            </div>
                                            <div className="col-lg-6">
                                                <p>
                                                    <span className="text-custom-grey mr-2">Bank Name</span>HDFC Bank <br />
                                                    <span className="text-custom-grey mr-2">Account No</span>50200017556819 <br />
                                                    <span className="text-custom-grey mr-2">IFSC</span>HDFC0000008 <br />
                                                    <span className="text-custom-grey mr-2">Branch</span>Stephen House
                                                </p>
                                            </div>
                                        </div>
                                        <p className="mt-4 text-custom-grey font-weight-normal">UTR Details</p>
                                        <div className="row d-flex m-0">
                                            {utrList.length > 0 ? utrList.map((element, key) => {
                                                return (<div key={`utrList` + key} id={`utrList` + key} className=" col-lg-4 utr-card">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-9">
                                                            <p className="mb-0">
                                                                <span className="text-primary font-weight-bold">{element.utr_num}</span> <br />
                                                                <span className="text-custom-grey">{element.bank_name}</span>
                                                            </p>
                                                        </div>
                                                        <div className="col-3 text-right">
                                                            <a href="#" className="custom-link text-danger" onClick={(e) => {
                                                                e.preventDefault()
                                                                deleteUtr(element)
                                                                document.getElementById(`utrList` + key).style.display = "none";
                                                            }}><i className="far fa-trash-alt"></i></a>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }) : null}
                                        </div>
                                        <form action="">
                                            <div className="row align-items-end">
                                                <div className="form-group col-12 col-lg-5">
                                                    <p className="mt-4 text-custom-grey font-weight-normal">Enter UTR Details</p>
                                                    <input type="text" className="form-control" placeholder="UTR Number" value={utrNumber ? utrNumber : ""} onChange={(e) => {
                                                        setUtrNumber(e.target.value)
                                                    }} />
                                                </div>
                                                <div className="form-group col-12 col-lg-5">
                                                    <p className="mt-4 text-custom-grey font-weight-normal">Payment send to bank</p>
                                                    <select name="bank_name" id=" bank_name" className="form-control select-ht" defaultValue={bankName ? bankName : "default"} onChange={(e) => {
                                                        setBankName(e.target.value)
                                                    }}>
                                                        <option disabled value="default"> Select an option </option>
                                                        <option value="ICICI">ICICI - 000605028824</option>
                                                        <option value="HDFC">HDFC - 50200017556819</option>
                                                    </select>
                                                    {/* <input type="text" className="form-control" placeholder="Bank Name" value={bankName ? bankName : ""} onChange={(e) => {
                                            setBankName(e.target.value)
                                        }} /> */}
                                                </div>

                                                <div className="form-group">
                                                    <button className="text-primary custom-button" onClick={(e) => {
                                                        e.preventDefault()
                                                        addUtr()
                                                    }}><i className="fas fa-plus-circle mr-2"></i>Save UTR</button>
                                                </div>
                                            </div>

                                        </form>
                                        <div className="form-check p-0">
                                            <p className="text-danger" id="addUtrError">{error}</p>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="button" className="btn btn-primary" onClick={(e) => {
                                            e.preventDefault()
                                            saveUTRDetails()
                                        }}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <ToastPage />
                </div>
                <PlaceHolderWrapper />
                <SuccessModalPage {...{ message: "Coupon Applied Successfully.", done: true }} />
                <BottomBarPage />
            </div>
        </div>
    )
}