import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useForgotPasswordContext } from "../contexts/ForgotPasswordContext";

export default function ForgotPasswordPrivateRoute({ children, ...rest }) {
    const { fgtPswdToken } = useForgotPasswordContext();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                fgtPswdToken ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/forgotPassword", state: { from: location } }} />
                )
            }
        />
    );
}
