import axios from 'axios';
import { configs } from '../../assets/Config';

// fetch all companies list
export function getLoginCompanies() {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/all/home/featuredCompanies`,
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })

}

// get distributor type
export function getDistributorType(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/login/info`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// backend api for customer credential login
export function credentialLogin(username, password, remember) {
    return new Promise(async (resolve, reject) => {
        try {


            var data = JSON.stringify({
                "username": username,
                "password": password,
                "remember": remember
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/login`,
                headers: {
                    'Authorization': 'Bearer asd',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// generate google auth url for customer oAuth login
export function getGoogleAuthUrl() {
    return new Promise(async (resolve, reject) => {
        try {

            let res = await axios.get(
                `${process.env.REACT_APP_BASEURL}/distributor/google/authURL`
            );
            resolve(res?.data?.authURL);
        } catch (err) {
            reject(err?.response)
        }
    })
}

// backend api for customer oAuth login 
export function loginCustomerWithGoogleCred(googleCode) {
    return new Promise(async (resolve, reject) => {
        const bodyParameters = {
            code: googleCode,
        };

        try {
            let res = await axios.post(
                `${process.env.REACT_APP_BASEURL}/distributor/google/login`,
                bodyParameters
            );
            resolve(res)

        } catch (err) {
            resolve(err?.response)

        }
    })
}

// backend api for customer one tap login 
export function loginCustomerWithGoogleOneTap(googleCode) {
    return new Promise(async (resolve, reject) => {
        const bodyParameters = {
            code: googleCode,
        };

        try {
            let res = await axios.post(
                `${process.env.REACT_APP_BASEURL}/distributor/google/loginOneTap`,
                bodyParameters
            );
            if (res && res.data && res.data.token) {
                var d = new Date();
                d.setTime(res.data.expire * 1000);
                var expires = "expires=" + d.toUTCString();
                document.cookie = `${res.data.cookie_name}=${res.data.token};domain=${process.env.REACT_APP_COOKIE_DOMAIN};expires=${expires}; path=/`;
                document.location.reload()
                window.location.href = res.data.redirect_url;
                // resolve(res)
            }
        } catch (err) {
            // resolve(err?.response)

        }
    })
}

// logout customer api
export function customerLogout(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/logout`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// load all notifications
export function loadAllNotifications(token, page) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/bellnotifications/${page}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// mark all notification as read
export function markAllNotiRead(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/bellnotifications/markAllAsRead`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// mark notification as read by id
export function markNotiReadById(token, notificationId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "notification_id": notificationId,
                "is_seen": "1"
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/bellnotifications/markAsRead`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get dashboard data
export function getCustomerDashboardData(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/dashboard`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// generate UTM
export function generateUTMLink(token, utmValue) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/profile/utm`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    utm: utmValue
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// save distributor query
export function saveDistributorQuery(name, email, mobile, message) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/distributor/public/formLeads`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    name: name,
                    email: email,
                    mobile: mobile,
                    message: message
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
