import axios from 'axios';
import { configs } from '../../assets/Config';

// get referral consumed history
export function getReferralConsumption(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/referral`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            reject(err?.response)
        }
    })
}

// get referral credit history
export function getReferralCredit(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/distributor/referral/bonus`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            reject(err?.response)
        }
    })
}

// remind for referral API
export function referralReminder(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/distributor/referral/reminder`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            reject(err?.response)
        }
    })
}