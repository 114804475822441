import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { animation, configs, formatCurrency, formatNumber } from "../../../assets/Config";
import '../../../assets/styles/Analytics.css';
import { useAUAssetMgmtContext } from "../../../services/contexts/assistedUser/assets/AUAssetMgmtContext";
import { useClientDataContext } from "../../../services/contexts/ClientDataContext";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import AUVerifyOrderPopupPage from "../assistedUser/assets/AUVerifyOrderPopupPage";


export default function ClientOrderPage() {

    const { tableData, dupTableData, setDupTableData, clientOrderData, column, setColumn, order, setOrder, start, end } = useClientDataContext();
    const { assetOrderDetailById, payKYCDetail, setPayKYCDetail, setVerifyOrderPopup, setAssetOrderDetail } = useAUAssetMgmtContext()
    const { setProxyEmail, setImpersonating } = useLogin()

    const history = useHistory()

    useEffect(() => {
        try {
            (async () => {
                await clientOrderData()
            })()
        } catch (error) {
            console.error(error)
        }
    }, [])

    // sort data
    useEffect(() => {
        try {
            let data = dupTableData
            if (order === "desc") {
                data = typeof dupTableData[0][column] === "string" ? [...dupTableData].sort((a, b) => a[column] > b[column] ? -1 : 1) : [...dupTableData].sort((a, b) => b[column] - a[column]);
            } else if (order === "asc") {
                data = typeof dupTableData[0][column] === "string" ? [...dupTableData].sort((a, b) => a[column] > b[column] ? 1 : -1) : [...dupTableData].sort((a, b) => a[column] - b[column]);
            }
            setDupTableData(data)
        } catch (error) {
            console.error(error)
        }

    }, [order, column])

    async function getOrderDetail(element, redirect) {
        try {
            let response = await assetOrderDetailById(element.order_id, element.user_email)
            if (response && response.success) {
                history.push("/user/assets/payment")
                setProxyEmail(element.user_email)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const orderDetailCard = (element) => {
        try {
            return (
                <div className="dashCard">
                    <div className="row m-0 align-items-center">
                        <div className="col-6 m-0 p-0">
                            <div className="buyButton">
                                <p className="m-0 p-0 f-12">Buy</p>
                            </div>
                        </div>
                        <div className="col-6 m-0 mt-2 p-0 text-right">
                            <p className="m-0 p-0 f-12">Id: <b>{element.order_id}</b></p>
                        </div>
                        <div className="col-12 m-0 mt-2 p-0">
                            <p className="m-0 p-0 font-weight-bold">{element.company_name ? element.company_name : element.add_on ? element.add_on.replace(/_/g, " ") : element.scheme_id ? element.scheme_id : ""}</p>
                        </div>
                        {!element.asset_class ?
                            <>
                                <div className="col-8 m-0 mt-2 p-0">
                                    <p className="m-0 p-0 f-12">Price/Share: <b>{formatCurrency(parseFloat(element.order_price_per_share ? element.order_price_per_share : 0))}</b></p>
                                </div>
                                <div className="col-4 m-0 mt-2 p-0 text-right">
                                    <p className="m-0 p-0 f-12">Qty: <b>{formatNumber(parseFloat(element.order_quantity ? element.order_quantity : 0))}</b></p>
                                </div>
                            </>
                            : null}
                        <div className="col-6 m-0 mt-2 p-0">
                            <p className="m-0 p-0 f-12">{element.order_date} {element.order_time}</p>
                        </div>
                        {element.order_status && (element.order_status.toLowerCase() === "intervention_needed" || element.order_status.toLowerCase() === "generated") && element.isPlacedByDistributor ?
                            <div className="col-6 m-0 mt-2 p-0 d-flex justify-content-end">
                                <div className="orderCancelBtn curPoint f-12" onClick={(e) => {
                                    e.preventDefault()
                                    setAssetOrderDetail(element)
                                    setVerifyOrderPopup(true)
                                    setProxyEmail(element.user_email)
                                }}>Cancel</div>
                            </div>
                            : null}
                        {element.order_status && element.order_status.toLowerCase() === "generated" && element.isPlacedByDistributor ?
                            <div className="col-12 m-0 mt-2 p-0">
                                <div className="csvDownBtn curPoint f-12" onClick={(e) => {
                                    e.preventDefault()
                                    setProxyEmail(element.user_email)
                                    setImpersonating(true)
                                    getOrderDetail(element, true)
                                    setPayKYCDetail(element)
                                }}>Complete Payment</div>
                            </div>
                            : null}
                    </div>
                </div>
            )
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div id="analytics">
            {dupTableData && dupTableData.length > 0 ?
                <div>
                    <div className="d-md-block d-none">
                        <div className="table-scroll">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Name</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("user_name")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("user_name")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" className={`${configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? "orderDetailColWidth" : ""}`}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Order {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? "Details" : "Time"}</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "order_id" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        { configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? setColumn("order_id") : setColumn("order_date") }
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "order_id" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        { configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? setColumn("order_id") : setColumn("order_date") }
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Status</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "order_status_message" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("order_status_message")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "order_status_message" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("order_status_message")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Amount</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "order_price" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("order_price")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "order_price" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("order_price")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        {configs.commissionModel.toLowerCase() != "custom" ?
                                            <th scope="col">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>Commissions</div>
                                                    <div className="d-flex flex-column mx-2">
                                                        <i className={`fa fa-caret-up curPoint ${(column === "market_cap" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("asc")
                                                        }}></i>
                                                        <i className={`fa fa-caret-down curPoint ${(column === "market_cap" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                            e.preventDefault()
                                                            setOrder("desc")
                                                        }}></i>
                                                    </div>
                                                </div>
                                            </th>
                                            : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dupTableData.map((element, key) => {
                                        if (key >= start && key < end) {
                                            return (
                                                <tr key={`clientOrderDetail` + key}>
                                                    <td className="rightBorder">
                                                        <div className="tableBreakWord">
                                                            <p className="m-0 p-0 f-12 font-weight-bold">{element.user_name}</p>
                                                            <p className="m-0 p-0 f-12">{element.user_email}</p>
                                                            <p className="m-0 p-0 f-12">+{element.mobile}</p>
                                                        </div>
                                                    </td>
                                                    <td className={`rightBorder ${configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? "orderDetailColWidth" : ""}`}>
                                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? orderDetailCard(element) : `${element.order_date} ${element.order_time}`}
                                                    </td>
                                                    <td className="rightBorder">{element.order_status_message ? element.order_status_message : element.order_status}</td>
                                                    <td className={`text-right ${configs.commissionModel.toLowerCase() != "custom" ? "rightBorder" : ""}`}>{formatCurrency(parseFloat(element.order_price ? element.order_price : 0))}</td>
                                                    {configs.commissionModel.toLowerCase() != "custom" ?
                                                        <td className="text-right">{formatCurrency(parseFloat(element.commission_credited))}</td>
                                                        : null}
                                                </tr>
                                            )

                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-md-none">
                        {dupTableData.map((element, key) => {
                            if (key >= start && key < end) {
                                return (
                                    <div className="orderDetailCard" key={`clientOrderDetail` + key}>
                                        <div className="row m-0 mb-2 p-0">
                                            <div className="col-9 m-0 p-0 pr-3">
                                                <p className="m-0 p-0 f-12 font-weight-bold" ><b>{element.user_name}</b></p>
                                                <p className="m-0 p-0 f-12" >{element.user_email}</p>
                                                <p className="m-0 p-0 f-12" >+{element.mobile}</p>
                                            </div>
                                            <div className="col-3 m-0 p-0 text-right">
                                                <p className="m-0 p-0 text-right">Status: <b>{element.order_status_message}</b></p>
                                            </div>
                                        </div>
                                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ? orderDetailCard(element)
                                            :
                                            <div className="row m-0 mt-3 p-0 align-items-center">
                                                <div className="col-12 m-0 p-0">
                                                    <p className="m-0 p-0">Order Time: <b>{element.order_date} {element.order_time}</b></p>
                                                </div>
                                            </div>
                                        }
                                        <div className="row m-0 mt-3 p-0 align-items-center">
                                            <div className="col-6 m-0 p-0">
                                                <p className="m-0 p-0">Amount: <b>{formatCurrency(parseFloat(element.order_price ? element.order_price : 0))}</b></p>
                                            </div>
                                            {configs.commissionModel.toLowerCase() != "custom" ?
                                                <div className="col-6 m-0 p-0 text-right">
                                                    <p className="m-0 p-0">Commissions: <b>{formatCurrency(parseFloat(element.commission_credited))}</b></p>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                : <p className="m-0 mt-2 p-0">No data found</p>}
            <AUVerifyOrderPopupPage {...{ flag: true }} />
        </div>
    )
}