import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link, useRouteMatch } from "react-router-dom";
import { configs, countries } from "../../../assets/Config";
import '../../../assets/styles/CustomerLogin.css';
import { getGoogleAuthUrl } from "../../../services/apis/CustomerSignUpApi";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import { useCustomerSignUpContext } from "../../../services/contexts/CustomerSignUpContext";
import imgdn from '../../../assets/images/leftBG.png'
import { Autocomplete } from "@material-ui/lab";
import { Box, TextField } from "@material-ui/core";
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'libphonenumber-js';

export default function SignUpGoogleMobilePage() {

    const { token, setVerifyEmail } = useLogin();
    const { registerUserGoogleCredentialsSaveMobile } = useCustomerSignUpContext();
    const { search } = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    // hook declaration to process signup
    const [country, setCountry] = useState('91')
    const [mobile, setMobile] = useState(0);
    const [referralCode, setReferralCode] = useState(configs.referralCode);
    const [refExist, setRefExist] = useState(configs.referralCode)
    const [error, setError] = useState("");

    //check if active customer registration sesssion is going on
    useEffect(() => {
        if (token) {
            history.push('/dashboard')
        }
    }, [token])

    // vlaidate mobile
    function validateMobile() {
        try {
            // const re = /[0-9]{10}/g;
            // let res = re.test(mobile);
            // if (res !== true) setError("Mobile number must be 10 digit numeric.");

            let res = isValidPhoneNumber(`+${country}${mobile}`, `+${country}`)
            if (res !== true) setError(configs.errorMessages.signUp.mobile);
            return res;
        } catch (error) {

        }
    }

    // get countryCode
    const getCountryCode = (e, value) => {
        if (value && value.phone)
            setCountry(`${value.phone}`)
    }

    // get country to display
    function getCountry() {
        let number;
        countries.map(((element, key) => {
            if (element.phone == country) {
                number = element
            }
        }))

        return number
    }

    // save customer personal detail on signup
    async function saveMobile(e) {
        try {
            e.preventDefault();
            if (!mobile.toString().trim() || !validateMobile()) {
                setError(configs.errorMessages.signUp.mobile)
            } else if (!country) {
                setError(configs.errorMessages.signUp.country)
            }
            else if (referralCode && referralCode.length !== 8) {
                setError(configs.errorMessages.signUp.referralCode)
            }
            else {
                let response = await registerUserGoogleCredentialsSaveMobile(mobile, country, referralCode)
                if (response && response.success === configs.errorMessages.success) {
                    setMobile("")
                    setError("")
                    setReferralCode("")
                    setCountry("")
                } else {
                    setError(response)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }


    // customer sign up personal detail ui code
    return (
        <div >
            <div>
                <p className="p-0 m-0 welcome-heading">Join Us!</p>
                <p className="p-0 m-0 welcome-sub-heading f-14">Grow your business with Altius’s <b>Pre-IPO</b> and <b>Unlisted</b> offerings</p>
            </div>
            <div className="loginForm">
                <div className="form-group mt-4 mb-2">
                    <p className="m-0 ml-2 mt-2 mb-2 p-0 f-14 font-weight-bold">Mobile Number <sup className="text-danger">*</sup></p>
                    <div className="row m-0 p-0">
                        <div className="col-4 m-0 p-0 pr-2">
                            <Autocomplete
                                id="country-select-demo"
                                options={countries}
                                className="f-14 form-control login-screen-input "
                                placeholder="Choose a country"
                                autoHighlight
                                onChange={getCountryCode}
                                value={country ? getCountry() : countries[103]}
                                getOptionLabel={(option) => `+${option.phone}`}
                                renderOption={(props, option) => (
                                    <div className="f-14">
                                        {`+${props.phone}`}
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder="Choose a country"
                                        {...params}
                                        className="f-14"
                                    />
                                )}
                            />
                        </div>
                        <div className="col-8 m-0 p-0">
                            <input type="phone" className="form-control login-screen-input f-14" name="mobile" placeholder="Enter your mobile number" onChange={(e) => {
                                setError("")
                                setMobile(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>

                {!refExist ?
                    <div className="form-group mt-2 mb-2">
                        <label className="m-0 ml-2 p-0 f-12 font-weight-bold">Have a Referral code?<span style={{ color: "#009FE3" }} className="curPoint" onClick={(e) => {
                            e.preventDefault();
                            setRefExist(true)
                        }}> Click here </span> to enter</label>
                    </div>
                    :
                    <div className="form-group mt-4 mb-2">
                        <p className="m-0 ml-2 mb-2 p-0 f-14 font-weight-bold">Referral Code</p>
                        <input type="text" className="form-control login-screen-input f-14" name="referralCode" placeholder="Enter te Referral Code" value={referralCode ? referralCode : ""} onChange={(e) => {
                            setError("")
                            setReferralCode(e.target.value)
                        }} />
                    </div>
                }

                <div className="ml-2">
                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{error}</p>
                </div>
                <div className="form-group mt-2 mb-0">
                    <button className="signIn-button curPoint" onClick={saveMobile}>Sign up</button>
                </div>
                <div>
                    <p className="f-14 m-0 p-0 noAccount">Already have an account?<span style={{ color: "#009FE3" }} className="curPoint" onClick={(e) => {
                        e.preventDefault();
                        history.push("/login")
                    }}> Login Now</span></p>
                </div>
            </div>
        </div >
        // <section>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-6 d-none d-sm-block p-0 m-0">
        //                 <div className="login-screen-wallpaper">
        //                     <img src={imgdn} className="w-100 h-100" alt="image" />
        //                 </div>
        //             </div>
        //             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
        //                 <div className="w-100">
        //                     <div className="login-screen-logo">
        //                         <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="logo" />
        //                     </div>
        //                     <form>
        //                         <div className="form-group">
        //                             <h4 className="mb-0 text-custom-greyDark">{configs.hardCodeTexts.signUp.heading}</h4>
        //                             <p className="mt-0 text-custom-grey">{configs.hardCodeTexts.signUp.subHeading}</p>
        //                         </div>
        //                         <div className="form-group">
        //                             <div className="row">
        //                                 <div className="col-lg-4 col-6">
        //                                     <Autocomplete
        //                                         id="country-select-demo"
        //                                         options={countries}
        //                                         className="f-14"
        //                                         placeholder="Choose a country"
        //                                         autoHighlight
        //                                         onChange={getCountryCode}
        //                                         value={country ? getCountry() : countries[103]}
        //                                         getOptionLabel={(option) => `(${option.code}) +${option.phone}`}
        //                                         renderOption={(props, option) => (
        //                                             <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        //                                                 <img
        //                                                     loading="lazy"
        //                                                     width="20"
        //                                                     src={`https://flagcdn.com/w20/${props.code.toLowerCase()}.png`}
        //                                                     srcSet={`https://flagcdn.com/w40/${props.code.toLowerCase()}.png 2x`}
        //                                                     alt=""
        //                                                 />
        //                                                 &nbsp;({props.code}) +{props.phone}
        //                                             </Box>
        //                                         )}
        //                                         renderInput={(params) => (
        //                                             <TextField
        //                                                 placeholder="Choose a country"
        //                                                 {...params}
        //                                                 className="f-12"
        //                                             />
        //                                         )}
        //                                     />
        //                                 </div>
        //                                 <div className="col-lg-8 col-6">
        //                                     <input type="text" className="form-control login-screen-input f-14" name="user_mobile" placeholder="Mobile Number" required onChange={(e) => {
        //                                         setMobile(e.target.value)
        //                                     }} />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="form-group">
        //                             {!refExist ? <label>Have a Referral Code? <a href="" onClick={(e) => {
        //                                 e.preventDefault()
        //                                 setRefExist(true)
        //                             }}>Click here</a> to enter</label>
        //                                 : <input type="text" className="form-control login-screen-input f-14" name="user_mobile" placeholder="Referral Code" required maxLength={10} value={referralCode ? referralCode : ""} onChange={(e) => {
        //                                     setReferralCode(e.target.value)
        //                                 }} />
        //                             }
        //                         </div>
        //                         <div className="form-check p-0">
        //                             <p className="text-danger" id="saveMobileError">{error}</p>
        //                         </div>
        //                         <div className="form-group text-center">
        //                             <button type="submit" className="btn btn-sm btn-custom btn-primary rounded px-3 login-screen-sign-in-button" onClick={(e) => {
        //                                 e.preventDefault();
        //                                 saveMobile()
        //                             }}>{configs.hardCodeTexts.signUp.saveMobile}</button>
        //                         </div>
        //                     </form>
        //                     <div className="mt-5">
        //                         <p className="text-custom-grey">{configs.hardCodeTexts.signUp.haveAcct}<Link to="/login" className="text-primary">{configs.hardCodeTexts.signUp.loginBtn}</Link></p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}