import React, { useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/KYC.css';
import { saveCustomerUploadDocument } from '../../../services/apis/CustomerKYCApi';
import { useCustomerKYCContext } from '../../../services/contexts/CustomerKYCContext';
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import KYCMobileProgressBarPage from './KYCMobileProgressBarPage';

export default function KYCUploadDocumentPage() {

    const fileTypes = {
        "pan": "pan",
        "address": "address",
        "bank": "bank",
        "demat": "demat",
        "other": "other"
    }

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, currPage, nextPage, editPage, getKYCSavedDetailContext, kycDetails, getPanProof, getAddressProof, getBankProof, getDematProof, getOtherProof } = useCustomerKYCContext();
    const { token, logout, setLoginError } = useLogin();

    const [panFile, setPanFile] = useState("")
    const [addressFile, setAddressFile] = useState("")
    const [bankFile, setBankFile] = useState("")
    const [dematFile, setDematFile] = useState("")
    const [otherFile, setOtherFile] = useState("")
    const [panPassword, setPanPassword] = useState("")
    const [addressPassword, setAddressPassword] = useState("")
    const [bankPassword, setBankPassword] = useState("")
    const [dematPassword, setDematPassword] = useState("")
    const [otherPassword, setOtherPassword] = useState("")
    const [error, setError] = useState("")

    // get file contents through file chooser
    function uploadFile(file, fileType) {
        try {
            if ((file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf") && file.size <= 2000000) {
                switch (fileType) {
                    case fileTypes.pan:
                        setPanFile(file);
                        break;
                    case fileTypes.address:
                        setAddressFile(file);
                        break;
                    case fileTypes.bank:
                        setBankFile(file);
                        break;
                    case fileTypes.demat:
                        setDematFile(file);
                        break;
                    case fileTypes.other:
                        setOtherFile(file);
                        break;
                }
            } else {
                alert("Only pdf or image files upto 2MB are allowed.")
            }
        } catch (error) {

        }
    }

    // upload customer kyc documents
    async function saveCustomerDocuments(e) {
        try {
            e.preventDefault();
            let response = await saveCustomerUploadDocument(token, panFile, addressFile, bankFile, dematFile, otherFile, panPassword, addressPassword, bankPassword, dematPassword, otherPassword)
            if (response && response.status === 200) {
                editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
                await getKYCSavedDetailContext()
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            } else {
                setError(response.data.error)
            }
        } catch (error) {

        }
    }


    // customer kyc demat detail ui code
    return (<div id="kycUploadDocumentContainer" className="main-kycPanel">
        <KYCMobileProgressBarPage />
        <p className="h5 text-theme mt-2">Upload Documents</p>
        <p className="text-custom-grey mt-4 mb-1">Documents maximum allowed size <span className="text-danger">2MB</span></p>
        <form action="" className="mb-4">
            {kycDetails && kycDetails.basic_details && (kycDetails.basic_details.name_status != "verified" || kycDetails.basic_details.pan_status != "verified") ?
                <div className="form-group">
                    <label className="f-12" htmlFor="upload_userPAN">PAN Card</label>
                    <div id="panUploadDiv" className="file-border rounded pt-2" onDragOver={(e) => {
                        e.preventDefault();
                    }} onDrop={(e) => {
                        uploadFile(e.dataTransfer.files[0], fileTypes.pan)
                        e.preventDefault();
                    }}>
                        <i className="fas fa-file-upload f-18"></i>
                        <p className="f-12">
                            Drag your file or &nbsp;
                            <input type="file" id="upload_userPAN" required hidden accept="image/jpg, image/jpeg, image/png, application/pdf" onChange={(e) => {
                                uploadFile(e.target.files[0], fileTypes.pan)
                            }} />
                            <label className="f-12" htmlFor="upload_userPAN">Click here</label>
                            &nbsp; to choose.  <br /> Upload : <span className="text-theme">{panFile.name}</span>
                        </p>

                    </div>
                    <label htmlFor="pass_userPAN" className="mt-2 text-secondary f-9">If your file is password protected please provide us with the password</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="text" id="pass_userPAN" className="form-control w-50" placeholder="Password" onChange={(e) => {
                            setPanPassword(e.target.value)
                        }} />
                        {kycDetails.distributor_documents.pan_card ? <a href="#" className="col-6 text-right custom-link" onClick={(e) => {
                            e.preventDefault()
                            getPanProof()
                        }}>Download</a> : null}
                    </div>
                </div>
                : ""}

            {/* <div className="form-group mt-5">
                <label className="f-12" htmlFor="upload_userAddress">Address Proof (Aadhar / Bank Statement / Driving license / Passport / Voter Id)</label>
                <div id="addressUploadDiv" className="file-border rounded pt-2" onDragOver={(e) => {
                    e.preventDefault();
                }} onDrop={(e) => {
                    uploadFile(e.dataTransfer.files[0], fileTypes.address)
                    e.preventDefault();
                }}>
                    <i className="fas fa-file-upload f-18"></i>
                    <p className="f-12">
                        Drag your file or &nbsp;
                        <input type="file" id="upload_userAddress" required hidden accept="image/jpg, image/jpeg, image/png, application/pdf" onChange={(e) => {
                            uploadFile(e.target.files[0], fileTypes.address)
                        }} />
                        <label className="f-12" htmlFor="upload_userAddress">Click here</label>
                        &nbsp; to choose. <br /> Upload : <span className="text-theme">{addressFile.name}</span>
                    </p>

                </div>
                <label htmlFor="pass_userAddress" className="mt-2 text-secondary f-9">If your file is password protected please provide us with the password</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="text" id="pass_userAddress" className="form-control w-50" placeholder="Password" onChange={(e) => {
                        setAddressPassword(e.target.value)
                    }} />
                    {kycDetails.distributor_documents.address_proof ? <a href="#" className="col-6 text-right custom-link" onClick={(e) => {
                        e.preventDefault()
                        getAddressProof()
                    }}>Download</a> : null}
                </div>

            </div> */}

            {kycDetails && kycDetails.bank_details && kycDetails.bank_details.status != "verified" ?
                <div className="form-group mt-5">
                    <label className="f-12" htmlFor="upload_userBank">Bank (Passbook / Cancelled Cheque / Screenshot of login - Evidencing bank account no &amp; IFSC code)</label>
                    <div id="bankUploadDiv" className="file-border rounded pt-2" onDragOver={(e) => {
                        e.preventDefault();
                    }} onDrop={(e) => {
                        uploadFile(e.dataTransfer.files[0], fileTypes.bank)
                        e.preventDefault();
                    }}>
                        <i className="fas fa-file-upload f-18"></i>
                        <p className="f-12">
                            Drag your file or &nbsp;
                            <input type="file" id="upload_userBank" required hidden accept="image/jpg, image/jpeg, image/png, application/pdf" onChange={(e) => {
                                uploadFile(e.target.files[0], fileTypes.bank)
                            }} />
                            <label className="f-12" htmlFor="upload_userBank">Click here</label>
                            &nbsp; to choose. <br /> Upload : <span className="text-theme">{bankFile.name}</span>
                        </p>

                    </div>
                    <label htmlFor="pass_userBank" className="mt-2 text-secondary f-9">If your file is password protected please provide us with the password</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="text" id="pass_userBank" className="form-control w-50" placeholder="Password" onChange={(e) => {
                            setBankPassword(e.target.value)
                        }} />
                        {kycDetails.distributor_documents.bank_proof ? <a href="#" className="col-6 text-right custom-link" onClick={(e) => {
                            e.preventDefault()
                            getBankProof()
                        }}>Download</a> : null}
                    </div>

                </div>
                : ""}

            {/* <div className="form-group mt-5">
                <label className="f-12" htmlFor="upload_userDemat">Demat Account (DIS booklet, screenshot showing account name, DP Id and Client Id)</label>
                <div id="dematUploadDiv" className="file-border rounded pt-2" onDragOver={(e) => {
                    e.preventDefault();
                }} onDrop={(e) => {
                    uploadFile(e.dataTransfer.files[0], fileTypes.demat)
                    e.preventDefault();
                }}>
                    <i className="fas fa-file-upload f-18"></i>
                    <p className="f-12">
                        Drag your file or &nbsp;
                        <input type="file" id="upload_userDemat" required hidden accept="image/jpg, image/jpeg, image/png, application/pdf" onChange={(e) => {
                            uploadFile(e.target.files[0], fileTypes.demat)
                        }} />
                        <label className="f-12" htmlFor="upload_userDemat">Click here</label>
                        &nbsp; to choose. <br /> Upload : <span className="text-theme">{dematFile.name}</span>
                    </p>

                </div>
                <label htmlFor="pass_userDemat" className="mt-2 text-secondary f-9">If your file is password protected please provide us with the password</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="text" id="pass_userDemat" className="form-control w-50" placeholder="Password" onChange={(e) => {
                        setDematPassword(e.target.value)
                    }} />
                    {kycDetails.distributor_documents.demat_proof ? <a href="#" className="col-6 text-right custom-link" onClick={(e) => {
                        e.preventDefault()
                        getDematProof()
                    }}>Download</a> : null}
                </div>

            </div> */}

            <div className="form-group mt-5">
                <label className="f-12" htmlFor="upload_userDemat">Other Document (Optional)</label>
                <div id="otherUploadDiv" className="file-border rounded pt-2" onDragOver={(e) => {
                    e.preventDefault();
                }} onDrop={(e) => {
                    uploadFile(e.dataTransfer.files[0], fileTypes.other)
                    e.preventDefault();
                }}>
                    <i className="fas fa-file-upload f-18"></i>
                    <p className="f-12">
                        Drag your file or &nbsp;
                        <input type="file" id="upload_userOther" required hidden accept="image/jpg, image/jpeg, image/png, application/pdf" onChange={(e) => {
                            uploadFile(e.target.files[0], fileTypes.other)
                        }} />
                        <label className="f-12" htmlFor="upload_userOther">Click here</label>
                        &nbsp; to choose. <br /> Upload : <span className="text-theme">{otherFile.name}</span>
                    </p>

                </div>
                <label htmlFor="pass_userDemat" className="mt-2 text-secondary f-9">If your file is password protected please provide us with the password</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="text" id="pass_userOther" className="form-control w-50" placeholder="Password" onChange={(e) => {
                        setOtherPassword(e.target.value)
                    }} />
                    {kycDetails.distributor_documents.other_document ? <a href="#" className="col-6 text-right custom-link" onClick={(e) => {
                        e.preventDefault()
                        getOtherProof()
                    }}>Download</a> : null}
                </div>

            </div>

            <div className="form-check p-0">
                <p className="text-danger" id="loginFormError">{error}</p>
            </div>
            <button className="btn btn-primary rounded px-4" onClick={(e) => {
                saveCustomerDocuments(e)
            }}>{editPage ? "Update" : "Next"}</button>
        </form>
    </div>)
}