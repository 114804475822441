import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useCustomerSignUpContext } from "../contexts/CustomerSignUpContext";


export default function SignUpPrivateRoute({ children, ...rest }) {
    const { signUpToken } = useCustomerSignUpContext();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                signUpToken ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/signup", state: { from: location } }} />
                )
            }
        />
    );
}
