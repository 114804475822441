import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link, useRouteMatch } from "react-router-dom";
import { configs, countries } from "../../../assets/Config";
import '../../../assets/styles/CustomerLogin.css';
import { getGoogleAuthUrl } from "../../../services/apis/CustomerLoginApi";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import { useCustomerSignUpContext } from "../../../services/contexts/CustomerSignUpContext";
import imgdn from '../../../assets/images/leftBG.png'
import { Autocomplete } from "@material-ui/lab";
import { Box, TextField } from "@material-ui/core";
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'libphonenumber-js';

import googleIcon from '../../../assets/images/google.png'

export default function SignUpPersonalDetailPage() {

    const { token, setVerifyEmail } = useLogin();
    const { signUpError, setSignUpError, signUpToken, submitCustomerPersonalDetail } = useCustomerSignUpContext();
    const { loginUserWithGoogleCredentials } = useLogin()
    const { search } = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    // hook declaration to process signup
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState('91')
    const [mobile, setMobile] = useState(0);
    const [referralCode, setReferralCode] = useState(configs.referralCode);
    const [refExist, setRefExist] = useState(configs.referralCode)
    const [googleAuthURL, setGoogleAuthURL] = useState("");


    //check if active customer registration sesssion is going on
    useEffect(() => {

        if (token) {
            history.push('/dashboard')
        }
    }, [token])

    // vlaidate mobile
    function validateMobile() {
        try {
            // const re = /[0-9]{10}/g;
            // let res = re.test(mobile);
            // if (res !== true) setSignUpError("Mobile number must be 10 digit numeric.");

            let res = isValidPhoneNumber(`+${country}${mobile}`, `+${country}`)
            if (res !== true) setSignUpError(configs.errorMessages.signUp.mobile);

            return res;
        } catch (error) {

        }
    }

    // get countryCode
    const getCountryCode = (e, value) => {
        if (value && value.phone)
            setCountry(`${value.phone}`)
    }

    // get country to display
    function getCountry() {
        let number;
        countries.map(((element, key) => {
            if (element.phone == country) {
                number = element
            }
        }))

        return number
    }

    // save customer personal detail on signup
    async function submitPersonalDetail(e) {
        try {
            e.preventDefault();
            if (!name.trim()) {
                setSignUpError(configs.errorMessages.signUp.name)
            } else if (!email.trim() || !configs.emailRegex.test(String(email).toLowerCase())) {
                setSignUpError(configs.errorMessages.signUp.email)
            } else if (!mobile.trim() || !validateMobile()) {
                setSignUpError(configs.errorMessages.signUp.mobile)
            } else if (!country) {
                setSignUpError(configs.errorMessages.signUp.country)
            }
            else if (referralCode && referralCode.length !== 8) {
                setSignUpError(configs.errorMessages.signUp.referralCode)
            }
            else {
                let response = await submitCustomerPersonalDetail(name, email, mobile, country, token, referralCode)
                if (response === configs.errorMessages.success) {
                    setEmail("")
                    setName("")
                    setMobile("")
                    setGoogleAuthURL("")
                    setSignUpError("")
                    setReferralCode("")
                    setCountry("")
                    history.push(`${match.url}/verifyOtp`)
                }
            }
        } catch (error) {

        }
    }

    // get google oAuth url for registration 
    useEffect(() => {
        (async () => {
            try {
                configs.referrer = new URLSearchParams(search).get("utm") ? new URLSearchParams(search).get("utm") : "";
                if (configs.referrer) {
                    window.localStorage.setItem("referrer", configs.referrer)
                }

                configs.referralCode = new URLSearchParams(search).get("referralCode") ? new URLSearchParams(search).get("referralCode") : "";
                if (configs.referralCode) {
                    window.localStorage.setItem("referralCode", configs.referralCode)
                    setReferralCode(configs.referralCode)
                    setRefExist(configs.referralCode)
                }

                let url = await getGoogleAuthUrl()
                setGoogleAuthURL(url);
            } catch (error) {

            }
        })();
    }, []);


    // register with google oAuth
    useEffect(() => {
        (async () => {
            try {
                var searchParams = new URLSearchParams(search);
                var googleCode = searchParams.get("code");
                if (googleCode) {
                    let response = await loginUserWithGoogleCredentials(googleCode)
                    if (response && response.redirect) {
                        history.push('/signup/google/saveMobile')
                    }
                }
            } catch (error) {

            }
        })();
    }, []);


    // customer sign up personal detail ui code
    return (
        <div >
            <div>
                <p className="p-0 m-0 welcome-heading">Join Us!</p>
                <p className="p-0 m-0 welcome-sub-heading f-14">Grow your business with Altius’s <b>Pre-IPO</b> and <b>Unlisted</b> offerings</p>
            </div>
            <div className="loginForm">
                <div className="form-group mt-1 mb-2">
                    <p className="m-0 ml-2 mb-2 p-0 f-14 font-weight-bold">Name <sup className="text-danger">*</sup></p>
                    <input type="text" className="form-control login-screen-input f-14" name="username" placeholder="Enter your name" onChange={(e) => {
                        setSignUpError("")
                        setName(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-4 mb-2">
                    <p className="m-0 ml-2 mt-2 mb-2 p-0 f-14 font-weight-bold">Email <sup className="text-danger">*</sup></p>
                    <input type="email" className="form-control login-screen-input f-14" name="email" placeholder="Enter your email" onChange={(e) => {
                        setSignUpError("")
                        setEmail(e.target.value)
                        setVerifyEmail(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-4 mb-2">
                    <p className="m-0 ml-2 mt-2 mb-2 p-0 f-14 font-weight-bold">Mobile Number <sup className="text-danger">*</sup></p>
                    <div className="row m-0 p-0">
                        <div className="col-4 m-0 p-0 pr-2">
                            <Autocomplete
                                id="country-select-demo"
                                options={countries}
                                className="f-14 form-control login-screen-input "
                                placeholder="Choose a country"
                                autoHighlight
                                onChange={getCountryCode}
                                value={country ? getCountry() : countries[103]}
                                getOptionLabel={(option) => `+${option.phone}`}
                                renderOption={(props, option) => (
                                    <div className="f-14">
                                        {`+${props.phone}`}
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder="Choose a country"
                                        {...params}
                                        className="f-14"
                                    />
                                )}
                            />
                        </div>
                        <div className="col-8 m-0 p-0">
                            <input type="phone" className="form-control login-screen-input f-14" name="mobile" placeholder="Enter your mobile number" onChange={(e) => {
                                setSignUpError("")
                                setMobile(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>

                {!refExist ?
                    <div className="form-group mt-2 mb-2">
                        <label className="m-0 ml-2 p-0 f-12 font-weight-bold">Have a Referral code?<span style={{ color: "#009FE3" }} className="curPoint" onClick={(e) => {
                            e.preventDefault();
                            setRefExist(true)
                        }}> Click here </span> to enter</label>
                    </div>
                    :
                    <div className="form-group mt-4 mb-2">
                        <p className="m-0 ml-2 mb-2 p-0 f-14 font-weight-bold">Referral Code</p>
                        <input type="text" className="form-control login-screen-input f-14" name="referralCode" placeholder="Enter te Referral Code" value={referralCode ? referralCode : ""} onChange={(e) => {
                            setSignUpError("")
                            setReferralCode(e.target.value)
                        }} />
                    </div>
                }

                <div className="ml-2">
                    <p className="f-12 m-0 p-0 text-danger font-weight-bold">{signUpError}</p>
                </div>
                <div className="form-group mt-2 mb-0">
                    <button className="signIn-button curPoint" onClick={submitPersonalDetail}>Sign up</button>
                </div>
                <div id="gsignIn" className="form-group mt-2">
                    <button className="googleSignIn-button curPoint" onClick={(e) => {
                        e.preventDefault();
                        window.open(googleAuthURL, "_self")
                    }}><img style={{ objectFit: "contain", height: "1.5rem" }} className="mr-2" src={googleIcon} />Sign in with Google</button>
                </div>
                <div id="gsignInAnim" className="form-group mt-2 d-none">
                    <button className="googleSignIn-button gSignIn-parent" onClick={(e) => {
                        e.preventDefault();
                    }}><img style={{ objectFit: "contain", height: "1.5rem" }} className="mr-4" src={googleIcon} /> <div className="gSignIn-Anim"></div></button>
                </div>
                <div>
                    <p className="f-14 m-0 p-0 noAccount">Already have an account?<span style={{ color: "#009FE3" }} className="curPoint" onClick={(e) => {
                        e.preventDefault();
                        history.push("/login")
                    }}> Login Now</span></p>
                </div>
            </div>
        </div >
        // <section>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-6 d-none d-sm-block p-0 m-0">
        //                 <div className="login-screen-wallpaper">
        //                     <img src={imgdn} className="w-100 h-100" alt="image" />
        //                 </div>
        //             </div>
        //             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
        //                 <div className="w-100">
        //                     <div className="login-screen-logo">
        //                         <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="logo" />
        //                     </div>
        //                     <form>
        //                         <div className="form-group">
        //                             <h4 className="mb-0 text-custom-greyDark">{configs.hardCodeTexts.signUp.heading}</h4>
        //                         </div>
        //                         <div className="form-group">
        //                             <input type="text" className="form-control login-screen-input f-14" name="user_name" placeholder="Name (Required)" required onChange={(e) => {
        //                                 setName(e.target.value)
        //                             }} />
        //                         </div>
        //                         <div className="form-group">
        //                             <input type="email" className="form-control login-screen-input f-14" name="user_email" placeholder="Email ID (Required)" required onChange={(e) => {
        //                                 setEmail(e.target.value);
        //                                 setVerifyEmail(e.target.value);
        //                             }} />
        //                         </div>
        //                         <div className="form-group">
        //                             <div className="row">
        //                                 <div className="col-lg-4 col-6">
        //                                     <Autocomplete
        //                                         id="country-select-demo"
        //                                         options={countries}
        //                                         className="f-14"
        //                                         placeholder="Choose a country"
        //                                         autoHighlight
        //                                         onChange={getCountryCode}
        //                                         value={country ? getCountry() : countries[103]}
        //                                         getOptionLabel={(option) => `(${option.code}) +${option.phone}`}
        //                                         renderOption={(props, option) => (
        //                                             <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        //                                                 <img
        //                                                     loading="lazy"
        //                                                     width="20"
        //                                                     src={`https://flagcdn.com/w20/${props.code.toLowerCase()}.png`}
        //                                                     srcSet={`https://flagcdn.com/w40/${props.code.toLowerCase()}.png 2x`}
        //                                                     alt=""
        //                                                 />
        //                                                 &nbsp;({props.code}) +{props.phone}
        //                                             </Box>
        //                                         )}
        //                                         renderInput={(params) => (
        //                                             <TextField
        //                                                 placeholder="Choose a country"
        //                                                 {...params}
        //                                                 className="f-14"
        //                                             />
        //                                         )}
        //                                     />
        //                                 </div>
        //                                 <div className="col-lg-8 col-6">
        //                                     <input type="text" className="form-control login-screen-input f-14" name="user_mobile" placeholder="Mobile Number (Required)" required onChange={(e) => {
        //                                         setMobile(e.target.value)
        //                                     }} />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="form-group">
        //                             {!refExist ? <label>Have a Referral Code? <a href="" onClick={(e)=>{
        //                                 e.preventDefault()
        //                                 setRefExist(true)
        //                             }}>Click here</a> to enter</label>
        //                                 : <input type="text" className="form-control login-screen-input f-14" name="user_referral" placeholder="Referral Code (Optional)" required maxLength={10} value={referralCode ? referralCode : ""} onChange={(e) => {
        //                                     setReferralCode(e.target.value)
        //                                 }} />
        //                             }
        //                         </div>
        //                         <div className="form-check p-0">
        //                             <p className="text-danger" id="loginFormError">{signUpError}</p>
        //                         </div>
        //                         <div className="form-group text-center">
        //                             <button type="submit" className="btn btn-sm btn-custom btn-primary rounded px-3 login-screen-sign-in-button" disabled={name && email && mobile ? false : true} onClick={submitPersonalDetail}>{configs.hardCodeTexts.signUp.signUpBtn}</button>
        //                         </div>
        //                     </form>
        //                     <div className="mt-4 text-center position-relative">
        //                         <hr className="w-sm-100 w-75" />
        //                         <div className="login-screen-hr-or">
        //                             <p>{configs.hardCodeTexts.signUp.or}</p>
        //                         </div>
        //                     </div>
        //                     <div className="mt-4 text-center">
        //                         <a className="btn btn-outline-secondary google-sign-in-button text-wrap" href={googleAuthURL}>
        //                             <img alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
        //                             {configs.hardCodeTexts.signUp.googleSignUp}
        //                         </a>
        //                     </div>
        //                     <div className="mt-5">
        //                         <p className="text-custom-grey">{configs.hardCodeTexts.signUp.haveAcct}<Link to="/login" className="text-primary">{configs.hardCodeTexts.signUp.loginBtn}</Link></p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}