import { TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Autocomplete } from "@material-ui/lab";
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'libphonenumber-js';
import { useEffect, useState } from "react";
import { configs, countries } from "../../../../assets/Config";
import { useAURegister_KYCContext } from "../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext";
import { useClientDataContext } from "../../../../services/contexts/ClientDataContext";
import { useLogin } from "../../../../services/contexts/CustomerLoginContext";
import ToastPage from "../../../includes/ToastPage";

export default function AURegisterPage() {

    const { showRegisterModal, setShowRegisterModal, registerUser, registerUserOTPVerification, registerUserResendOTP } = useAURegister_KYCContext();
    const { clientData } = useClientDataContext()
    const { setShowSuccess, setProxyEmail, proxyEmail } = useLogin()

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState('91')
    const [mobile, setMobile] = useState(0);
    const [signUpError, setSignUpError] = useState("")
    const [registerToken, setRegisterToken] = useState("")
    const [OTP, setOTP] = useState("");
    const [otpTimer, setOtpTimer] = useState(parseInt(configs.otpTimer));

    const handleClose = () => {
        setShowRegisterModal(false)
        setName("")
        setEmail("")
        setCountry("91")
        setMobile("")
        setSignUpError("")
        setRegisterToken("")
        setOTP("")
        setProxyEmail("")
    };

    // otp timer
    useEffect(() => {
        const controller = new AbortController();
        try {
            const timer = otpTimer > 0 && setInterval(() => setOtpTimer(otpTimer - 1), 1000);
            return () => clearInterval(timer);
        } catch (error) {

        }
        return () => {
            controller.abort();
        };
    }, [otpTimer]);


    // vlaidate mobile
    function validateMobile() {
        try {
            let res = isValidPhoneNumber(`+${country}${mobile}`, `+${country}`)
            if (res !== true) setSignUpError(configs.errorMessages.signUp.mobile);

            return res;
        } catch (error) {

        }
    }

    // get countryCode
    const getCountryCode = (e, value) => {
        if (value && value.phone)
            setCountry(`${value.phone}`)
    }

    // get country to display
    function getCountry() {
        let number;
        countries.map(((element, key) => {
            if (element.phone == country) {
                number = element
            }
        }))

        return number
    }

    // save customer personal detail on registration
    async function submitPersonalDetail(e) {
        try {
            e.preventDefault();
            // if (!name.trim()) {
            //     setSignUpError(configs.errorMessages.signUp.name)
            // } else if (!email.trim() || !configs.emailRegex.test(String(email).toLowerCase())) {
            //     setSignUpError(configs.errorMessages.signUp.email)
            // } else if (!mobile.trim() || !validateMobile()) {
            //     setSignUpError(configs.errorMessages.signUp.mobile)
            // } else if (!country) {
            //     setSignUpError(configs.errorMessages.signUp.country)
            // }
            // else {
            document.getElementById("signUpBtn").classList.toggle("d-none")
            document.getElementById("signUpBtnLoader").classList.toggle("d-none")

            let response = await registerUser(name, email, country, mobile)
            if (response && response.success) {
                setRegisterToken(response.data)
                setOtpTimer(configs.otpTimer)
                setSignUpError(`OTP has been sent to client email ${proxyEmail}`)
            } else {
                setSignUpError(response)
            }

            document.getElementById("signUpBtn").classList.toggle("d-none")
            document.getElementById("signUpBtnLoader").classList.toggle("d-none")
            // }
        } catch (error) {
            console.error(error);
        }
    }

    // verify otp to register
    async function auRegisterOTPVerify(e) {
        try {
            e.preventDefault();
            // if (!otp.trim()) {
            //     setSignUpError(configs.errorMessages.verifyOtp)
            // } 
            // else {
            document.getElementById("signUpBtn").classList.toggle("d-none")
            document.getElementById("signUpBtnLoader").classList.toggle("d-none")

            let response = await registerUserOTPVerification(registerToken, OTP)
            if (response && response.success) {
                await clientData()
                handleClose()
                setShowSuccess(true)
            } else {
                setSignUpError(response)
            }

            document.getElementById("signUpBtn").classList.toggle("d-none")
            document.getElementById("signUpBtnLoader").classList.toggle("d-none")
            // }
        } catch (error) {
            console.error(error);
        }
    }

    // resend otp to register
    async function auRegisterOTPResend(e) {
        try {
            e.preventDefault();
            document.getElementById("signUpBtn").classList.toggle("d-none")
            document.getElementById("signUpBtnLoader").classList.toggle("d-none")

            let response = await registerUserResendOTP(registerToken)
            if (response && response.success) {
                setSignUpError(response.data)
                setOtpTimer(parseInt(configs.otpTimer));
                setTimeout(() => {
                    setSignUpError("")
                }, 2000)
            } else {
                setSignUpError(response)
            }

            document.getElementById("signUpBtn").classList.toggle("d-none")
            document.getElementById("signUpBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Modal open={showRegisterModal} onClose={handleClose}>
            <div className="modal-fade modalPosition" id="AURegisterModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="m-0 p-0 font-weight-bold">Register Client</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1 mb-2">
                                    <p className="m-0 ml-2 mb-2 p-0  font-weight-bold">Name <sup className="text-danger">*</sup></p>
                                    <input type="text" className="form-control login-screen-input defaultFont" name="username" value={name ? name : ""} placeholder="Enter your name" onChange={(e) => {
                                        setSignUpError("")
                                        setName(e.target.value)
                                    }} />
                                </div>
                                <div className="form-group mt-4 mb-2">
                                    <p className="m-0 ml-2 mt-2 mb-2 p-0  font-weight-bold">Email <sup className="text-danger">*</sup></p>
                                    <input type="email" className="form-control login-screen-input defaultFont" name="email" value={email ? email : ""} placeholder="Enter your email" onChange={(e) => {
                                        setSignUpError("")
                                        setEmail(e.target.value)
                                        setProxyEmail(e.target.value)
                                    }} />
                                </div>
                                <div className="form-group mt-4 mb-2">
                                    <p className="m-0 ml-2 mt-2 mb-2 p-0  font-weight-bold">Mobile Number <sup className="text-danger">*</sup></p>
                                    <div className="row m-0 p-0">
                                        <div className="col-4 m-0 p-0 pr-2">
                                            <Autocomplete
                                                id="country-select-demo"
                                                options={countries}
                                                className=" form-control login-screen-input "
                                                placeholder="Choose a country"
                                                autoHighlight
                                                onChange={getCountryCode}
                                                value={country ? getCountry() : countries[103]}
                                                getOptionLabel={(option) => `+${option.phone}`}
                                                renderOption={(props, option) => (
                                                    <div className="">
                                                        {`+${props.phone}`}
                                                    </div>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        placeholder="Choose a country"
                                                        {...params}
                                                        className=""
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-8 m-0 p-0">
                                            <input type="phone" className="form-control login-screen-input defaultFont" name="mobile" value={mobile ? mobile : ""} placeholder="Enter your mobile number" onChange={(e) => {
                                                setSignUpError("")
                                                setMobile(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                {registerToken ?
                                    <div className="form-group mt-4 mb-2">
                                        <p className="m-0 ml-2 mt-2 mb-2 p-0  font-weight-bold">OTP <sup className="text-danger">*</sup></p>
                                        <input type="number" className="form-control login-screen-input defaultFont" name="otp" value={OTP ? OTP : ""} placeholder="Enter your OTP" onChange={(e) => {
                                            setSignUpError("")
                                            setOTP(e.target.value)
                                        }} />
                                    </div>
                                    : null
                                }
                                {registerToken ?
                                    <div className="col-12 m-0 p-0 text-right">
                                        <label className="m-0 ml-2 p-0 subContent font-weight-bold"><span className={otpTimer === 0 ? "curPoint text-primary" : ""} onClick={otpTimer === 0 ? auRegisterOTPResend : (e) => {
                                            e.preventDefault()
                                        }}>{otpTimer === 0 ? configs.hardCodeTexts.verifyOTP.resendBtn : configs.hardCodeTexts.verifyOTP.timerText.replace("0", otpTimer)}</span></label>
                                    </div>
                                    : null}
                                <div className="ml-2">
                                    <p className="subContent m-0 p-0 text-danger font-weight-bold">{signUpError}</p>
                                </div>
                                <div className="form-group mt-2 mb-0">
                                    <button id="signUpBtn" className="signIn-button curPoint" onClick={registerToken ? auRegisterOTPVerify : submitPersonalDetail}>{registerToken ? "Verify OTP" : "Register Client"}</button>
                                    <div id="signUpBtnLoader" className='animBtn d-none w-100'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </Modal>)
}