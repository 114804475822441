import React, { useEffect, useState } from 'react';
import { configs, formatCurrency, formatNumber } from '../../../assets/Config';
import '../../../assets/styles/Referral.css'
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import BottomBarPage from '../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import referralLink from '../../../assets/images/referralLink.svg'
import Twitter from '../../../assets/images/Twitter.svg'
import WhatsApp from '../../../assets/images/WhatsApp.svg'
import referralBanner from '../../../assets/images/referralBanner.svg'
import done from '../../../assets/images/done.svg'
export default function ReferralPage() {
    const { setShowMobBS, setCurrModule, setcurrMod, getReferralCreditByCustomer, getReferralConsumptionByCustomer, referralReminderCustomer, setShowSuccess } = useLogin()
    const type = {
        refUsed: "Referral Sign ups",
        creditHistory: "Transaction History"
    }
    const [refCopied, setRefCopied] = useState(false)
    const [tableData, setTableData] = useState("")
    const [currData, setCurrData] = useState(type.refUsed)
    const [referralId, setReferralId] = useState("")
    const [refData, setRefData] = useState("")
    const [limit, setLimit] = useState({
        "total": 0,
        "used": 0
    })
    const [creditHistory, setCreditHistory] = useState("")
    document.title = `Referral Insight | Altius Investech`
    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            try {
                setShowMobBS(false)
                setCurrModule(configs.modules.referral)
                setcurrMod(configs.modules.referral)
                let res = await getReferralConsumptionByCustomer()
                if (res && res.success) {
                    setTableData(res.data.data)
                    setReferralId(res.data.referral_id)
                    setRefData(res.data)
                    let limit = {
                        total: res.data.referral_limit,
                        used: res.data.data.length
                    }
                    setLimit(limit)
                }

            } catch (error) {
            }
        })()
        return () => {
            controller.abort();
        };
    }, [])
    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            document.getElementById("refDataDisplay").classList.toggle("d-none")
            setCreditHistory("")
            if (currData === type.creditHistory) {
                let res = await getReferralCreditByCustomer()
                if (res && res.success) {
                    setTableData(res.data.data)
                    let history = {
                        total: res.data.total_referral_earning,
                        current: res.data.available_referral_amount
                    }
                    setCreditHistory(history)
                }
            }
            else {
                let res = await getReferralConsumptionByCustomer()
                if (res && res.success) {
                    setTableData(res.data.data)
                    setReferralId(res.data.referral_id)
                    setRefData(res.data)
                    let limit = {
                        total: res.data.referral_limit,
                        used: res.data.data.length
                    }
                    setLimit(limit)
                }
            }
            document.getElementById("refDataDisplay").classList.toggle("d-none")
        })()
        return () => {
            controller.abort();
        };
    }, [currData])
    // remind customer
    async function remindCustomer() {
        try {
            let res = await referralReminderCustomer()
            if (res && res.success) {
                setShowSuccess(true);
            }
        } catch (error) {
        }
    }
    // customer order book ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                <div id='referralContainer' className="dash-main">
                    <div className='refMain'>
                        <div className='referralBanner'>
                            <div className='row m-0 p-0 align-items-center'>
                                <div className='col-md-6 col-12 m-2 py-0 pl-2'>
                                    <p className='m-0 p-0 referralBannerHead'>Invite and Earn</p>
                                    <p className='m-0 p-0 referralBannerSubHead'>
                                        Invite Partners to join Altius and earn a 0.2% commission on their client’s orders
                                    </p>
                                </div>
                                <div className='col-md-5 col-12 m-0 pl-4 p-0'>
                                    <img className='refImg' src={referralBanner} alt="altius investech referral banner" />
                                </div>
                            </div>
                        </div>
                        <div className='row m-0 p-0'>
                            <div className='col-4 m-0 p-0'>
                                <div className='refShare curPoint' onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://wa.me?text=${configs.referralMessage.replace(/referee_amount|referral_id/gi, matched => refData[matched])}`, "_blank");
                                }}>
                                    <img className='refShareIcon' src={WhatsApp} alt="share altius investech referral on whatsapp" />
                                    <p className='m-0 p-0 font-weight-bold'>Share on WhatsApp</p>
                                </div>
                            </div>
                            <div className='col-4 m-0 p-0'>
                                <div className={`refShare ${refCopied ? "" : "curPoint"}`} onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(`https://partners.altiusinvestech.com/signup?referralCode=${refData["referral_id"]}`);
                                    setRefCopied(true)
                                    setTimeout(() => {
                                        setRefCopied(false)
                                    }, 2000)
                                }}>
                                    {refCopied ? <img className='refShareIcon' src={done} alt="copy altius investech referral" /> : <img className='refShareIcon' src={referralLink} alt="copy altius investech referral" />}
                                    <p className='m-0 p-0 font-weight-bold'>{refCopied ? "Copied" : "Copy"} Referral Link</p>
                                </div>
                            </div>
                            <div className='col-4 m-0 p-0'>
                                <div className='refShare curPoint' onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`https://twitter.com/intent/tweet?text=${configs.referralMessage.replace(/referee_amount|referral_id/gi, matched => refData[matched])}`, "_blank");
                                }}>
                                    <img className='refShareIcon' src={Twitter} alt="share altius investech referral on twitter" />
                                    <p className='m-0 p-0 font-weight-bold'>Share on Twitter</p>
                                </div>
                            </div>
                        </div>
                        <hr className='refHr' />
                        <div className='row m-0 p-0'>
                            <div className='col-lg-10 col-md-9 col-12 m-0 p-0'>
                                <p className='m-0 p-0 welcome-heading'>Referrals Done({limit.used})</p>
                            </div>
                            <div className='col-lg-2 col-md-3 col-12 m-0 p-0'>
                                <p className='m-0 p-0 refCode'>Your referral code is:
                                    <span className='font-weight-bold text-dark'> {refData["referral_id"]}</span></p>
                            </div>
                        </div>
                        <div className="row m-0 p-0 refBalance">
                            <div className="col-12 m-0 p-0 f-14">
                                Total Referral Commissions: <b>{formatCurrency(parseFloat(refData["total_referral_earning"] ? refData["total_referral_earning"] : "0"))}</b>
                            </div>
                            {/* <div className="col-6 m-0 p-0 f-14 text-right">
                                Current Balance:<br /> <b>{formatCurrency(parseFloat(refData["available_referral_amount"] ? refData["available_referral_amount"] : "0"))}</b>
                            </div> */}
                        </div>
                        {/* <p className='m-0 p-0 f-14'>Refer altius to friends and earn. On the first Successful transaction of your Referee, You will get {formatCurrency(parseFloat(refData.referral_amount))} and Your friend will get {formatCurrency(parseFloat(refData.referee_amount))}.</p> */}
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-12 m-0 p-0 refData d-flex justify-content-center'>
                                <div className='refChange'>
                                    <div className={`${currData === type.refUsed ? "refActive" : "refInActive"} curPoint`} onClick={(e) => {
                                        e.preventDefault()
                                        setCurrData(type.refUsed)
                                    }}>
                                        <p className='m-0 p-0'>{type.refUsed}</p>
                                    </div>
                                    <div className={`${currData === type.creditHistory ? "refActive" : "refInActive"} curPoint`} onClick={(e) => {
                                        e.preventDefault()
                                        setCurrData(type.creditHistory)
                                    }}>
                                        <p className='m-0 p-0'>{type.creditHistory}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div id="refDataDisplay" className='refTableParent'>
                                <div className='row m-0 p-0 align-items-center'>
                                    <div className='col-8 order-lg-first m-0 p-0 refData'>
                                        <p className='m-0 f-0 f-18 font-weight-bold'>{currData}</p>
                                    </div>
                                    <div className='col-4 m-0 p-0 refData'>
                                        {(refData.pending_referral > 0 && currData !== type.creditHistory) ? <div className='d-flex justify-content-end'>
                                            <div className="refActive curPoint" onClick={(e) => {
                                                e.preventDefault()
                                                remindCustomer()
                                            }}>
                                                <p className='m-0 p-0'>Remind All</p>
                                            </div>
                                        </div>
                                            : null}
                                    </div>
                                </div>
                                <div className='table-scroll'>
                                    {currData === type.creditHistory ?
                                        tableData ?
                                            <div>
                                                <div className='d-md-block d-none'>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style={{ "whiteSpace": "nowrap" }}>Date</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col" className="text-right">Transactions</th>
                                                                <th scope="col" style={{ "whiteSpace": "nowrap" }} className="text-right">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableData.map((element, key) => {
                                                                var date;
                                                                if (element.date)
                                                                    date = element.date.toString().split(" ")
                                                                return (
                                                                    <tr key={`openOrder` + key}>
                                                                        <td style={{ "whiteSpace": "nowrap" }}>{date && date.length > 0 ? date[0] : ""}</td>
                                                                        <td>{element.description}</td>
                                                                        <td className={`text-right ${element.credit && element.credit > 0 ? "text-success" : element.debit && element.debit > 0 ? "text-danger" : ""}`}>{element.credit && element.credit > 0 ? `+ ${formatNumber(parseFloat(element.credit ? element.credit : "0"))}` : element.debit && element.debit > 0 ? `- ${formatNumber(parseFloat(element.debit ? element.debit : "0"))}` : 0}</td>
                                                                        <td style={{ "whiteSpace": "nowrap" }} className="text-right">{formatCurrency(parseFloat(element.total_amount ? element.total_amount : "0"))}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='d-md-none d-block'>
                                                    {tableData.map((element, key) => {
                                                        var date;
                                                        if (element.date)
                                                            date = element.date.toString().split(" ")
                                                        return (<div key={`creditHistory` + key} className="row m-0 d-flex align-items-center border-bottom py-1">
                                                            <div className="col-12 m-0 p-0">
                                                                <span className="f-12 font-weight-bold mr-1">Desc - </span>
                                                                <span className="f-10">{element.description}</span> <br />
                                                            </div>
                                                            <div className="col-6 m-0 p-0">
                                                                <span className="f-12 font-weight-bold mr-1">Date - </span>
                                                                <span className="f-10">{date && date.length > 0 ? date[0] : ""}</span> <br />
                                                            </div>
                                                            <div className="col-6 m-0 p-0">
                                                                <span className="f-12 font-weight-bold mr-1">Transaction - </span>
                                                                <span className={`f-10 ${element.credit && element.credit > 0 ? "text-success" : element.debit && element.debit > 0 ? "text-danger" : ""}`}>{element.credit && element.credit > 0 ? `+ ${formatNumber(parseFloat(element.credit ? element.credit : "0"))}` : element.debit && element.debit > 0 ? `- ${formatNumber(parseFloat(element.debit ? element.debit : "0"))}` : 0}</span> <br />
                                                            </div>
                                                            <div className="col-6 m-0 p-0">
                                                                <span className="f-12 font-weight-bold mr-1">Total - </span>
                                                                <span className="f-10">{formatCurrency(parseFloat(element.total_amount ? element.total_amount : "0"))}</span> <br />
                                                            </div>
                                                            {/* <div className="col-6 m-0 p-0">
                                                                <span className="f-12 font-weight-bold mr-1">Debit - </span>
                                                                <span className="f-10">{formatCurrency(parseFloat(element.debit ? element.debit : "0"))}</span> <br />
                                                            </div> */}
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            : <p>No Referral {currData} found</p>
                                        :
                                        tableData ?
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="refDataRefName">Referee Name</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((element, key) => {
                                                        return (
                                                            <tr key={`openOrder` + key}>
                                                                <td className="refDataRefName">{element.distributor_name}</td>
                                                                <td>{element.successful == 1 ?
                                                                    <div className='refDataRefActive'>
                                                                        Transacted
                                                                    </div>
                                                                    : <div className='refDataRefPending'>
                                                                        Signed-up
                                                                    </div>
                                                                }</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            : <p>No Referral {currData} found</p>
                                    }
                                </div>
                                <p style={{ color: "#009FE3" }} className="m-0 p-0 curPoint font-weight-bold f-14 text-right" onClick={(e) => {
                                    e.preventDefault();
                                    window.open("https://trade.altiusinvestech.com/ReferralTermsandConditions.pdf", "_blank");
                                }}>Referral Terms &amp; Conditions*</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ToastPage />
            <BottomBarPage />
        </div >
    )
}