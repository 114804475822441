import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs, getCookie } from '../../assets/Config';
import { credentialLogin, customerLogout, generateUTMLink, getCustomerDashboardData, loadAllNotifications, loginCustomerWithGoogleCred, loginCustomerWithGoogleOneTap, markAllNotiRead, markNotiReadById } from '../apis/CustomerLoginApi';
import OneSignal from 'react-onesignal';
import { useCustomerSignUpContext } from './CustomerSignUpContext';
import { getReferralConsumption, getReferralCredit, referralReminder } from '../apis/ReferralApi';

// create global login context
export const LoginContext = createContext();

export function LoginProvider({ children }) {
    // fetch existing login session 
    const savedToken = getCookie("DISTRIBUTOR_AUTH_TOKEN");
    const [login, setLogin] = useState(savedToken);
    const [token, setToken] = useState(savedToken);
    const [showSuccess, setShowSuccess] = useState(false)
    const [currModule, setCurrModule] = useState("");
    const [showMobBS, setShowMobBS] = useState(false)
    const [showDefaultMobBtn, setShowDefaultMobBtn] = useState(true)
    const [activeList, setActiveList] = useState(configs.list.all)
    const [defaultDisplay, setDefaultDisplay] = useState(false)
    const [loginError, setLoginError] = useState("");
    const [profileModal, setProfileModal] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [notifications, setNotifications] = useState("")
    const [notiPage, setNotiPage] = useState(1)
    const [showNotModal, setShowNotModal] = useState(false)
    const [hideShowMore, setHideShowMore] = useState(false)
    const [currMod, setcurrMod] = useState(currModule);
    const [dashboardData, setDashboardData] = useState("");
    const [googleSignUpToken, setGoogleSignUpToken] = useState("")
    const [proxyEmail, setProxyEmail] = useState("")
    const [impersonating, setImpersonating] = useState(false)

    // set update login session details
    async function saveLoginDetails(tokenData) {
        try {
            setLogin(true);
            setToken(tokenData.token);
            configs.isWealthManager = tokenData && tokenData.type ? tokenData.type : "false"
            configs.commissionModel = tokenData && tokenData.commission_modal ? tokenData.commission_modal : configs.commissionModel
            var d = new Date();
            d.setTime(tokenData.expire * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = `${tokenData.cookie_name}=${tokenData.token};domain=${process.env.REACT_APP_COOKIE_DOMAIN};expires=${expires}; path=/`;

            OneSignal.init({
                appId: "368a1458-c282-4a50-8edb-23ea3077d77e",
                safari_web_id: "web.onesignal.auto.175a5781-a0a7-4966-97e0-0bfa01fcdb1f",
                notifyButton: {
                    enable: true,
                },
                subdomainName: "altius",
                persistNotification: false
            }).then(() => {
                OneSignal.setExternalUserId(tokenData.token).then(async () => {
                }).catch(error => {
                    console.error("--", error)
                })
                // let response = await
            }).catch((error) => {
                console.error(error)
            })

            OneSignal.on('notificationDisplay', function (event) {
                try {
                    configs.notiSeen = false
                } catch (error) {
                }
            });

            let unread = 0;
            let response = await loadAllNotifications(tokenData.token, 1);
            if (response && response.status === 200) {
                if (response.data && response.data.length > 0) {
                    response.data.map((element) => {
                        if (element.is_seen === 0) {
                            unread += 1;
                        }
                    })
                }
                if (unread > 0) {
                    configs.notiSeen = false
                }
            }
            window.localStorage.removeItem("referrer")
            window.localStorage.removeItem("referralCode")
        } catch (error) {
            console.error(error)
        }
    }

    // login customer with credentials shared
    async function loginUserWithCredentials(username, password, remember) {
        try {
            let response = await credentialLogin(username, password, remember);
            if (response && response.status === 200) {
                saveLoginDetails(response.data);
            } else {
                setLogin(false)
                setLoginError(response.data.error)
            }
        } catch (error) {

        }
    }

    // login customer with google oAuth
    async function loginUserWithGoogleCredentials(googleCode) {
        try {
            let hide = document.getElementById("gsignIn")
            hide.classList.toggle("d-none");
            let show = document.getElementById("gsignInAnim")
            show.classList.toggle("d-none")

            let response = await loginCustomerWithGoogleCred(googleCode);

            hide.classList.toggle("d-none")
            show.classList.toggle("d-none")

            if (response && response.status === 200) {
                if (response.data.account_exists === 1) {
                    saveLoginDetails(response.data);
                    return configs.errorMessages.success
                } else {
                    setGoogleSignUpToken(response.data)
                    return {
                        "redirect": "mobile"
                    }
                }
            } else {
                setLogin(false)
                setLoginError(response.data.error)
                return response.data.error
            }
        } catch (error) {

        }
    }



    // clear session on logout
    async function logout() {
        try {
            document.cookie = `DISTRIBUTOR_AUTH_TOKEN=;domain=${process.env.REACT_APP_COOKIE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
            document.cookie = "g_state=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
            setLogin(false);
            setToken(null);
            setShowMobBS(false)
            let response = await customerLogout(token);

        } catch (error) {

        }
    }

    // load all notifications
    async function getAllNotifications(page, append) {
        try {
            let response = await loadAllNotifications(token, page);
            if (response && response.status === 200) {
                if (append) {
                    let data = notifications;
                    response.data.map((element, key) => {
                        data.push(element)
                        if (key === response.data.length - 1) {
                            setNotifications(data)
                        }
                    })
                } else {
                    setNotifications(response.data);
                }

                if (response.data.length < 10) {
                    setHideShowMore(true)
                } else {
                    setHideShowMore(false)
                }
                return configs.errorMessages.success
            } else if (response && response.status === 401) {
                setLogin(false)
                //setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // mark all notifications read
    async function markReadAllNotification() {
        try {
            let response = await markAllNotiRead(token);
            if (response && response.status === 200) {
                getAllNotifications(notiPage)
            } else if (response && response.status === 401) {
                setLogin(false)
                //setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // mark notification read by id
    async function markReadNotificationById(notificationId) {
        try {
            let response = await markNotiReadById(token, notificationId);
            if (response && response.status === 200) {
                getAllNotifications(notiPage)
            } else if (response && response.status === 401) {
                setLogin(false)
                //setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get customer dashboard data
    async function getDashboardData() {
        try {
            let response = await getCustomerDashboardData(token);
            if (response && response.status === 200) {
                setDashboardData(response.data)
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                setLogin(false)
            }
        } catch (error) {

        }
    }

    // get customer dashboard data
    async function generateUTM(utmValue) {
        try {
            let response = await generateUTMLink(token, utmValue);
            if (response && response.status === 200) {
                await getDashboardData()
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                setLogin(false)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get referral consumption history
    async function getReferralConsumptionByCustomer() {
        try {
            let response = await getReferralConsumption(token);
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                setLogin(false)
            }
        } catch (error) {

        }
    }

    // get referral credit history
    async function getReferralCreditByCustomer() {
        try {
            let response = await getReferralCredit(token);
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                setLogin(false)
            }
        } catch (error) {

        }
    }

    // referral reminder
    async function referralReminderCustomer() {
        try {
            let response = await referralReminder(token);
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success,
                }
            } else if (response && response.status === 401) {
                setLogin(false)
            }
        } catch (error) {
        }
    }

    // export global context
    return (
        <LoginContext.Provider
            value={{
                login,
                loginUserWithCredentials,
                loginUserWithGoogleCredentials,
                setLogin,
                logout,
                loginError,
                setLoginError,
                token,
                googleSignUpToken,
                setGoogleSignUpToken,
                currModule,
                setCurrModule,
                saveLoginDetails,
                profileModal,
                setProfileModal,
                verifyEmail,
                setVerifyEmail,
                showSuccess,
                setShowSuccess,
                showMobBS,
                setShowMobBS,
                showDefaultMobBtn,
                setShowDefaultMobBtn,
                activeList,
                setActiveList,
                defaultDisplay,
                setDefaultDisplay,
                notifications,
                notiPage,
                setNotiPage,
                getAllNotifications,
                markReadAllNotification,
                markReadNotificationById,
                showNotModal,
                setShowNotModal,
                hideShowMore,
                getCustomerDashboardData,
                getDashboardData,
                currMod,
                setcurrMod,
                dashboardData,
                setDashboardData,
                generateUTM,
                referralReminderCustomer,
                getReferralCreditByCustomer,
                getReferralConsumptionByCustomer,
                proxyEmail,
                setProxyEmail,
                impersonating,
                setImpersonating
            }}
        >
            {children}
        </LoginContext.Provider>
    );
}

// check if existing login session has expired 
export function useLogin() {
    // const { time } = JSON.parse(localStorage?.getItem("login")) || {};
    // const currentTime = Date.now();
    // if (currentTime - time > 2 * 60 * 60 * 1000) {
    //     localStorage.removeItem("login");
    // }

    return useContext(LoginContext);
}