import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLogin } from '../../services/contexts/CustomerLoginContext';

export default function ToastPage() {
    const { showSuccess, setShowSuccess } = useLogin()
    if (showSuccess) {
        toast.success("Success.")
        var timer = setTimeout(() => {
            setShowSuccess(false)
        }, 1)
    }

    function handleClose() {
        setShowSuccess(false);
        clearTimeout(timer)
    }
    return (
        <div>
            <ToastContainer
                closeOnClick
                closeButton
                autoClose={2000}
                onClick={handleClose}
            />
        </div>
    );
}