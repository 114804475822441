import React, { useEffect, useState } from "react";
import BottomBarPage from "../../includes/dashboard/BottomBarPage";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import { useCustomerKYCContext } from "../../../services/contexts/CustomerKYCContext";
import { Link, useHistory } from "react-router-dom";
import { computeNet, configs, downloadCSVUsingData, formatCurrency, formatNumber } from "../../../assets/Config";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import '../../../assets/styles/dashboard.css';
import ToastPage from '../../includes/ToastPage'
import ClientOrderPage from "../clientData/ClientOrderPage";
import { useClientDataContext } from "../../../services/contexts/ClientDataContext";
import '../../../assets/styles/Analytics.css';
import balance from '../../../assets/images/balance.svg';
import commission from '../../../assets/images/commission.svg';
import payout from '../../../assets/images/payout.svg';


export default function CommissionPage() {

    const { kycDetails } = useCustomerKYCContext();
    const { setShowMobBS, setCurrModule, setcurrMod, setShowSuccess, getDashboardData, setDashboardData, dashboardData } = useLogin()
    const { start, setStart, end, setEnd, dupTableData, setDupTableData, tableData, rowPerPage, setRowPerPage, commissionLedgerData, column, setColumn, order, setOrder, currPage, setCurrPage, maxPage, commissionData, downloadCSV } = useClientDataContext()

    const history = useHistory()

    useEffect(() => {
        setShowMobBS(false)
        setCurrModule(configs.modules.commission)
        setcurrMod(configs.modules.commission)
        try {
            (async () => {
                setDupTableData([])
                let response = await commissionLedgerData()
            })()
        } catch (error) {

        }

    }, [])


    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                <div id='commissionContainer' className="dash-main" >
                    <div className="row dashTitle">
                        <div className="col-6">
                            Commission
                        </div>
                        {configs.commissionModel.toLowerCase() != "custom" ?
                            <div className="col-6 m-0 p-0 d-flex justify-content-end">
                                <div id="downloadCSVbtn" className={`csvDownBtn curPoint f-12`} onClick={(e) => {
                                    e.preventDefault();
                                    downloadCSVUsingData(tableData, "Commission Detail")
                                }}>
                                    Export Commission Detail
                                </div>
                            </div>
                            : null}
                    </div>
                    {/* commission high level numbers--------------------------------------*/}
                    {configs.commissionModel.toLowerCase() != "custom" ?
                        <div className="row mx-2 my-1">
                            <div className="col-md-4 col-6 m-0 p-0">
                                <div className="dashCard">
                                    <div>
                                        <div className="row m-0 align-items-top">
                                            <div className="col-6 p-0">
                                                <p className="m-0 pb-2">Total Commissions</p>
                                                <div className="commissionCardNumber">
                                                    <p className="m-0">{commissionData && commissionData.total_commission ? formatCurrency(parseFloat(commissionData.total_commission)) : 0}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 text-right">
                                                <img src={commission} className="commissionCardIcon" alt="altius investech commission" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-6 m-0 p-0">
                                <div className="dashCard">
                                    <div>
                                        <div className="row m-0 align-items-top">
                                            <div className="col-6 p-0">
                                                <p className="m-0 pb-2">Total Payout</p>
                                                <div className="commissionCardNumber">
                                                    <p className="m-0">{commissionData && commissionData.total_payout ? formatCurrency(parseFloat(commissionData.total_payout)) : 0}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 text-right">
                                                <img src={payout} className="commissionCardIcon" alt="altius investech payout" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-6 m-0 p-0 margin">
                                <div className="dashCard">
                                    <div>
                                        <div className="row m-0 align-items-top">
                                            <div className="col-6 p-0">
                                                <p className="m-0 pb-2">Available Balance</p>
                                                <div className="commissionCardNumber">
                                                    <p className="m-0">{commissionData && commissionData.available_balance ? formatCurrency(parseFloat(commissionData.available_balance)) : 0}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 text-right">
                                                <img src={balance} className="commissionCardIcon" alt="altius investech balance" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="row m-0 px-3">
                            <div id="downloadCSVbtn" className={`csvDownBtn curPoint f-12`} onClick={(e) => {
                                e.preventDefault();
                                downloadCSV("Commission Ledger")
                            }}>
                                Download Commission Ledger
                            </div>
                        </div>}

                    {/* dashboard table selector--------------------------------------*/}

                    {/* dashboard table--------------------------------------*/}
                    {configs.commissionModel.toLowerCase() != "custom" ?
                        <div id="analytics" className="row mx-2 my-4">
                            <div className="col-12 m-0 p-0">
                                <div className="dashCard">
                                    {dupTableData && dupTableData.length > 0 ?
                                        <div>
                                            <div className="d-md-block d-none">
                                                <div className="table-scroll">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Date</div>
                                                                        {/* <div className="d-flex flex-column mx-2">
                                                                        <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("asc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                        <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("desc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                    </div> */}
                                                                    </div>
                                                                </th>
                                                                <th scope="col" className="orderDetailColWidth">
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Description</div>
                                                                        {/* <div className="d-flex flex-column mx-2">
                                                                        <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("asc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                        <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("desc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                    </div> */}
                                                                    </div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className='text-right'>
                                                                        <div>Credit</div>
                                                                        {/* <div className="d-flex flex-column mx-2">
                                                                        <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("asc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                        <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("desc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                    </div> */}
                                                                    </div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className='text-right'>
                                                                        <div>Debit</div>
                                                                        {/* <div className="d-flex flex-column mx-2">
                                                                        <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("asc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                        <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("desc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                    </div> */}
                                                                    </div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className='text-right'>
                                                                        <div>Balance</div>
                                                                        {/* <div className="d-flex flex-column mx-2">
                                                                        <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("asc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                        <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setOrder("desc")
                                                                            setColumn("user_name")
                                                                        }}></i>
                                                                    </div> */}
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dupTableData.map((element, key) => {
                                                                if (key >= start && key < end) {
                                                                    return (
                                                                        <tr key={`clientOrderDetail` + key}>
                                                                            <td className="rightBorder">
                                                                                <div className="tableBreakWord">
                                                                                    <p className="m-0 p-0 f-12 font-weight-bold">{element.date}</p>
                                                                                    <p className="m-0 p-0 f-12">{element.time}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="rightBorder">{element.description}</td>
                                                                            <td className="rightBorder text-right text-success">{element.credit > 0 ? formatCurrency(parseFloat(element.credit)) : "-"}</td>
                                                                            <td className="rightBorder text-right text-danger">{element.debit > 0 ? formatCurrency(parseFloat(element.debit)) : "-"}</td>
                                                                            <td className="text-right">{formatCurrency(parseFloat(element.available_balance))}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="d-md-none">
                                                {dupTableData.map((element, key) => {
                                                    if (key >= start && key < end) {
                                                        return (
                                                            <div className="orderDetailCard" key={`clientOrderDetail` + key}>
                                                                <div className="row m-0 mb-2 p-0">
                                                                    <div className="col-6 m-0 p-0 d-flex">
                                                                        <p className="m-0 p-0"><span className="font-weight-bold">Date - &nbsp;</span></p>
                                                                        <p className="m-0 p-0">{element.date} <br />{element.time}</p>
                                                                    </div>
                                                                    <div className="col-6 m-0 p-0">
                                                                        <p className="m-0 p-0"><span className="font-weight-bold">Credit - </span><span className="text-success">{element.credit > 0 ? formatCurrency(parseFloat(element.credit)) : "-"}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-0 mb-2 p-0">
                                                                    <div className="col-6 m-0 p-0">
                                                                        <p className="m-0 p-0"><span className="font-weight-bold">Balance - </span>{formatCurrency(parseFloat(element.available_balance))}</p>
                                                                    </div>
                                                                    <div className="col-6 m-0 p-0">
                                                                        <p className="m-0 p-0"><span className="font-weight-bold">Debit - </span><span className="text-danger">{element.debit > 0 ? formatCurrency(parseFloat(element.debit)) : "-"}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-0 mb-2 p-0">
                                                                    <div className="col-12 m-0 p-0 ">
                                                                        <p className="m-0 p-0"><span className="font-weight-bold">Description - </span>{element.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        : <p className="m-0 mt-2 p-0">No data found</p>}
                                </div>
                            </div>
                            <div className="col-12 m-0 p-0">
                                <div className="row m-3 p-0">
                                    <div className="col-2  d-flex m-0 p-0 align-items-center justify-content-start">
                                        <p className="m-0 f-0 f-12">{start >= 0 && dupTableData.length > 0 ? start + 1 : 0} - {end > dupTableData.length ? dupTableData.length : end} of {dupTableData.length}</p>
                                    </div>
                                    <div className="col-10 d-flex m-0 p-0 align-items-center justify-content-end">
                                        <div className="mr-3 d-flex align-items-center">
                                            <p className="m-0 pr-1 f-0 f-12">Rows per page:</p>
                                            <select className="f-12 noBorder" value={rowPerPage} onChange={(e) => {
                                                setRowPerPage(parseInt(e.target.value))
                                            }}>
                                                <option className="f-12" value={10}>10</option>
                                                <option className="f-12" value={20}>20</option>
                                                <option className="f-12" value={50}>50</option>
                                            </select>
                                        </div>
                                        <i className="fa fa-caret-square-o-left f-18" onClick={(e) => {
                                            e.preventDefault()
                                            setCurrPage(currPage > 1 ? currPage - 1 : 1)
                                        }}></i>
                                        <p className="m-0 mx-2 f-0 f-14">{currPage}/{maxPage}</p>
                                        <i className="fa fa-caret-square-o-right f-18" onClick={(e) => {
                                            e.preventDefault()
                                            setCurrPage(currPage < maxPage ? currPage + 1 : maxPage)
                                        }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
            <BottomBarPage />
            <ToastPage />
            {/* //     </div> */}
        </div >

    )
}