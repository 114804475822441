import React, { useEffect, useState } from 'react';
import '../../../assets/styles/TopBar.css'
import imgn from '../../../assets/images/default-user-profile.svg';
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import ProfileModalPage from '../ProfileModalPage';
import { configs, showLeftBar } from '../../../assets/Config';
import { Link, useHistory } from 'react-router-dom';
import { useCustomerKYCContext } from '../../../services/contexts/CustomerKYCContext';
import NotificationModalPage from '../NotificationModalPage';
import HelpPage from './HelpPage';
import { Modal } from '@material-ui/core';

export default function TopBarPage() {

    // const { getKYCSavedDetailContext, kycDetails } = useCustomerKYCContext();
    const { setProfileModal, currModule, activeList, setActiveList, defaultDisplay, setDefaultDisplay, showMobBS, setShowMobBS, showNotModal, setShowNotModal, getAllNotifications, notiPage, setNotiPage, currMod, setcurrMod, login, token, impersonating, setImpersonating, proxyEmail, setProxyEmail } = useLogin()

    const [notiRead, setNotiRead] = useState(true)

    const [tools, setTools] = useState(false)

    const history = useHistory()
    // get customer kyc saved details
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             await getKYCSavedDetailContext();
    //         } catch (error) {

    //         }
    //     })();

    // }, []);

    // check if any unread notitication is there
    useEffect(() => {
        setInterval(() => {
            if (configs.notiSeen !== notiRead) {
                let test = configs.notiSeen
                setNotiRead(test)
            }
        }, 3000)
    })

    function leftBarDisplay(show, current) {
        let response = showLeftBar(show, current, defaultDisplay, activeList, currModule)
        if (!response) {
            setcurrMod(currModule)
            setDefaultDisplay(response)
            // if ((currModule === configs.modules.shares) || (currModule === configs.modules.screenerMobFilter)) {
            //     setShowMobBS(true)
            // }
        } else {
            setcurrMod(configs.modules.mobCom);
            setShowMobBS(false)
        }
    }

    async function getNotifications() {
        try {
            let response = await getAllNotifications(1)
            setNotiPage(1)
            setShowNotModal(true)
        } catch (error) {

        }
    }

    return (
        <div id="topbar">
            <div className="row py-2 px-2 px-md-4 m-0 navbar-container align-items-center ">
                {token ? <div className='d-md-none col-2'>
                    <i className="fa fa-bars" aria-hidden="true" onClick={(e) => {
                        e.preventDefault()
                        setDefaultDisplay(true)
                        leftBarDisplay(true, configs.list.all)
                    }}></i>
                </div>
                    : null}
                <div className={`${token ? "col-10" : "col-12"} col-md-12 m-0 p-0 d-flex justify-content-between align-items-center`}>
                    <div className="navbar-company-logo-container d-flex">
                        <img className="w-100 h-100" src="https://altiusinvestech.com/photos/logo.png" alt="company logo" onClick={(e) => {
                            e.preventDefault()
                            history.push("/dashboard")
                        }} />

                    </div>
                    {token ?
                        <div className="navbar-items-container d-md-flex d-none justify-content-end align-items-center flex-row-reverse">
                            <div className="navbar-items-user" onClick={(e) => {
                                setProfileModal(true);
                            }} >
                                <img className="w-100 h-100" src={imgn} alt="user" />
                            </div>
                            <div className="navbar-items-link noti-bell-main">
                                {!configs.notiSeen ? <div className="noti-not-read-icon"></div> : null}
                                <i className="fas fa-bell curPoint" onClick={(e) => {
                                    configs.notiSeen = true
                                    e.preventDefault()
                                    getNotifications()
                                }}></i>
                            </div>
                            <div className={`${currModule === configs.modules.referral ? "text-primary font-weight-bold" : "navbar-items-link"}`}>
                                <Link to="/referral" onClick={(e) => {
                                    e.preventDefault()
                                    window.open('/referral', "_self")
                                }}>Refer &amp; Earn</Link>
                            </div>
                            <div className={`${currModule === configs.modules.howItWorks ? "text-primary font-weight-bold" : "navbar-items-link"}`}>
                                <Link to="/distDetail#howitworks" onClick={(e) => {
                                    e.preventDefault()
                                    window.open('/distDetail#howitworks', "_self")
                                }}>How It Works</Link>
                            </div>
                            <div className={`${currModule === configs.modules.commission ? "text-primary font-weight-bold" : "navbar-items-link"}`}>
                                <Link to="/commission">Commission</Link>
                            </div>
                            <div className={`${currModule === configs.modules.clients ? "text-primary font-weight-bold" : "navbar-items-link"}`}>
                                <Link to="/clients">Clients</Link>
                            </div>
                            <div className={`${currModule === configs.modules.dashboard ? "text-primary font-weight-bold" : "navbar-items-link"}`}>
                                <Link to="/dashboard">Dashboard</Link>
                            </div>
                            {/* <div className="navbar-items-link">
                        <a href="#">Companies</a>
                    </div> */}
                        </div>
                        : null}
                    {token ?
                        <div className="navbar-mobile-items-container d-md-none d-flex justify-content-end align-items-center flex-row-reverse">
                            <div className="navbar-items-user" onClick={(e) => {
                                setProfileModal(true);
                            }}>
                                <img className="w-100 h-100" src={imgn} alt="user" />
                            </div>
                            <div className="navbar-mobile-items-link noti-bell-main">
                                {!configs.notiSeen ? <div className="noti-not-read-icon"></div> : null}
                                <i className="fas fa-bell curPoint" onClick={(e) => {
                                    configs.notiSeen = true
                                    e.preventDefault()
                                    getNotifications()
                                }}></i>
                            </div>
                        </div>
                        :
                        <div className="navbar-mobile-items-container  d-flex justify-content-end align-items-center flex-row-reverse">
                            <div className='partnerUsBtn curPoint f-12' onClick={(e) => {
                                e.preventDefault()
                                window.open('/login', "_self")
                            }}>Log In</div>
                        </div>
                    }
                </div>
            </div>
            {impersonating ? <div className='impersonating-bar'>
                <p className='m-0 p-0 impersonating-bar-text'>You are operating account of - {proxyEmail}</p>
                <p className='m-0 p-0 curPoint text-danger' onClick={(e) => {
                    e.preventDefault()
                    setImpersonating(false)
                    setProxyEmail("")
                    history.push('/clients')
                }}><i className="fas fa-sign-out-alt"></i></p>
            </div>
                : null}
            <ProfileModalPage />
            <NotificationModalPage />
            {/* {token ? <HelpPage /> : null} */}
        </div>)
}