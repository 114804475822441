import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { animation, configs } from "../../../assets/Config";
import '../../../assets/styles/CustomerLogin.css';
import '../../../assets/styles/animation.css';

import { getGoogleAuthUrl } from "../../../services/apis/CustomerLoginApi";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";

import logo from '../../../assets/images/logo.png'
import headerImg from '../../../assets/images/headerImg.svg'
import googleIcon from '../../../assets/images/google.png'
import economicTime from '../../../assets/images/economicTime.svg'
import financialExpress from '../../../assets/images/financialExpress.svg'
import inc42 from '../../../assets/images/inc42.svg'
import moneyControl from '../../../assets/images/moneyControl.svg'
import kyc from '../../../assets/images/kyc.svg'
import gift from '../../../assets/images/gift.svg'
import trade from '../../../assets/images/trade.svg'
import pay from '../../../assets/images/pay.svg'
import Phone from '../../../assets/images/Phone.svg'
import Mail from '../../../assets/images/Mail.svg'
import WhatsApp from '../../../assets/images/WhatsApp.svg'
import arr1 from '../../../assets/images/arr1.svg'
import arr2 from '../../../assets/images/arr2.svg'
import partnerTop from '../../../assets/images/partnerTop.svg'
import CustomerLoginPage from "./CustomerLoginPage";
import SignUpPersonalDetailPage from "../signup/SignUpPersonalDetailPage";
import SignUpGoogleMobilePage from "../signup/SignUpGoogleMobilePage";
import VerifyOTPPage from "../../includes/VerifyOTPPage";
import CreatePasswordPage from "../../includes/CreatePassPage";
import ForgotPasswordPage from "../forgotPassword/ForgotPasswordPage";
import { getLoginCompanies } from "../../../services/apis/CustomerLoginApi";

export default function LandingPage() {

    const [compLogo, setCompLogo] = useState("")

    useEffect(() => {
        try {
            (async () => {
                let result = await getLoginCompanies()
                if (result && result.status === 200) {
                    setCompLogo(result.data)
                }
            })()
        } catch (error) {

        }
    }, [])
    setTimeout(() => {
        animation()
    }, 1000)

    // login page ui code
    return (
        <div className="mainDiv">
            {/* head logo--------------------------------------------- */}
            <div className="mt-3">
                <div style={{ height: "3.5rem" }}>
                    <img className="h-100" src={logo} alt="altius investech logo" />
                </div>
            </div>
            {/* welcome and login form----------------------------------------------- */}
            <div className="row align-items-center mt-2">
                {/* <div className="redBack"></div> */}
                <div className="col-lg-5 col-md-6 col-12 welcomeLoginForm" style={{ zIndex: 999 }}>
                    {window.location.href.includes("saveMobile") ?
                        <SignUpGoogleMobilePage />
                        : window.location.href.includes("verifyOtp") ?
                            <VerifyOTPPage />
                            : window.location.href.includes("createPassword") ?
                                <CreatePasswordPage />
                                : window.location.href.includes("forgotPassword") ?
                                    <ForgotPasswordPage />
                                    : window.location.href.toLowerCase().includes("signup") ?
                                        <SignUpPersonalDetailPage />
                                        : <CustomerLoginPage />}
                </div>
                {/* welcome and login image----------------------------------------------- */}

                <div className="col-md-5 welcomeImg col-12">
                    <div className="blueBack"></div>
                    <img style={{ objectFit: "contain", width: "100%", zIndex: 999 }} src={partnerTop} alt="altius investech login" />
                </div>
            </div>

            {/* <div className="arrowDivRight">
                <img src={arr2} />
            </div>

            {/* about platform div-----------------------------------------------------------------------------------}
            <div className="investDiv">
                <div className="redBack"></div>
                <div style={{ zIndex: 999 }}>
                    <p className="p-0 m-0 welcome-heading">About Altius</p>
                    <div className="row m-0 mt-5 p-0">
                        <div className="col-md-4 col-6 m-0 p-0">
                            <div className="dashCard"></div>
                        </div>
                        <div className="col-md-4 col-6 m-0 p-0">
                            <div className="dashCard"></div>
                        </div>
                        <div className="col-md-4 col-6 m-0 p-0">
                            <div className="dashCard"></div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="arrowDivLeft">
                <img src={arr1} />
            </div>

            {/* invest in companies div-----------------------------------------------------------------------------------*/}
            <div className="investDiv">
                <div className="blueBack"></div>
                <div style={{ zIndex: 999 }}>
                    <p className="p-0 m-0 welcome-heading">Pre-IPO Opportunities</p>
                    <div className="row" id='test'>

                        {compLogo && compLogo.length > 0 ?
                            <div className="marquee">
                                <ul className="marquee-content">
                                    {compLogo.map((element, key) => {
                                        let names = element.name.split(" ")
                                        return (
                                            <li key={`logImg` + key}>
                                                <div className="animCard">
                                                    <div className="animImgCard">
                                                        <img className="animImg" src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${element.logo}`} alt={element.name} />
                                                    </div>
                                                    <p className="m-0 p-0 f-12">{`${names[0]} ${!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(names[1]) ? names[1] : ""}`}</p>
                                                </div>
                                            </li>
                                        )
                                    })}

                                </ul>

                            </div>
                            : null}

                    </div>
                    {/* <p className="p-0 m-0 mt-4 investDetail">
                        Altius is India’s leading platform which facilitates buying and selling of Unlisted, Pre-IPO and privately held shares. We ensure liquidity for our clients by providing a two-way (buy &amp; sell) quote on all investments on our platform, fostering trust and transparency.
                    </p> */}
                </div>
            </div>

            <div className="arrowDivRight">
                <img src={arr2} />
            </div>

            {/* featued in div----------------------------------------------------------------------------------- */}
            <div className="investDiv">
                {/* <div className="blueBack"></div> */}
                <div style={{ zIndex: 999 }}>
                    <div className="my-4">
                        <p className="p-0 m-0 welcome-heading">Featured In</p>
                    </div>
                    <div className="row mt-4 align-items-center">
                        <div className="col-3">
                            <img className="featuredInImage curPoint" onClick={(e) => {
                                e.preventDefault()
                                window.open("https://altiusinvestech.com/press", "_blank")
                            }} src={economicTime} alt="altius investech economic times" />
                        </div>
                        <div className="col-3">
                            <img className="featuredInImage curPoint" onClick={(e) => {
                                e.preventDefault()
                                window.open("https://altiusinvestech.com/press", "_blank")
                            }} src={inc42} alt="altius investech inc42" />
                        </div>
                        <div className="col-3">
                            <img className="featuredInImage curPoint" onClick={(e) => {
                                e.preventDefault()
                                window.open("https://altiusinvestech.com/press", "_blank")
                            }} src={moneyControl} alt="altius investech money control" />
                        </div>
                        <div className="col-3">
                            <img className="featuredInImage curPoint" onClick={(e) => {
                                e.preventDefault()
                                window.open("https://altiusinvestech.com/press", "_blank")
                            }} src={financialExpress} alt="altius investech financial express" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="arrowDivLeft">
                <img src={arr1} />
            </div>
            {/* get in touch div-----------------------------------------------------------------------------------*/}
            <div className="investDiv">
                <div className="redBack"></div>
                <div style={{ zIndex: 999 }}>
                    <div className="my-4">
                        <p className="p-0 m-0 welcome-heading">Get in Touch</p>
                    </div>
                    <div className="row getInTouch align-items-center">
                        <div className="col-md-4 col-3 p-0 m-0 curPoint" onClick={(e) => {
                            e.preventDefault()
                            window.open("https://api.whatsapp.com/send/?phone=+919038517269&text=hey!", "_blank")
                        }}>
                            <img className="getInTouchIcon" src={WhatsApp} alt="altius investech whatsapp" />
                            <p className="m-0 font-weight-bold">WhatsApp Us</p>
                            <p className="m-0 lightColorText">+91-9038517269</p>
                        </div>
                        <div className="col-md-4 col-6 p-0 m-0 curPoint" onClick={(e) => {
                            e.preventDefault()
                            window.open("mailto:info@altiusinvestech.com", "_self")
                        }}>
                            <img className="getInTouchIcon" src={Mail} alt="altius investech mail" />
                            <p className="m-0 font-weight-bold">Email Us</p>
                            <p className="m-0 lightColorText">info@altiusinvestech.com</p>
                        </div>
                        <div className="col-md-4 col-3 p-0 m-0 curPoint" onClick={(e) => {
                            e.preventDefault()
                            window.open("tel:+91-9038517269", "_self")
                        }}>
                            <img className="getInTouchIcon" src={Phone} alt="altius investech phone" />
                            <p className="m-0 font-weight-bold">Call Us</p>
                            <p className="m-0 lightColorText">+91-9038517269</p>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr" />

            {/* footer--------------------------------------------------------------------- */}
            <div className="footer">
                <div className="row m-1 mb-5">
                    <div className="col-md-4 p-0 m-0">
                        <p className="f-12 p-0 m-0 mb-1 footerHeading">Altius Investech</p>
                        <p className="f-12 p-0 mt-3 mb-1 font-weight-bold">Social</p>
                        <p className="f-18 p-0 mb-3 font-weight-bold">
                            <a className="text-dark" target="_blank" href="https://www.facebook.com/altiusinv"><i className="fa fa-facebook-square mr-2" aria-hidden="true"></i></a>
                            <a className="text-dark" target="_blank" href="https://twitter.com/unlistedshares1"><i className="fa fa-twitter mr-2" aria-hidden="true"></i></a>
                            <a className="text-dark" target="_blank" href="https://www.linkedin.com/company/altius-investech/"><i className="fa fa-linkedin-square mr-2" aria-hidden="true"></i></a>
                            <a className="text-dark" target="_blank" href="https://www.instagram.com/altius.investech/"><i className="fab fa-instagram"></i></a>
                        </p>
                        <p className="f-12 p-0 mt-5 mb-1 font-weight-bold">Registered Office</p>
                        <p className="f-12 p-0 mb-1 lightColorText">2nd Floor, Room 201, 73A, Ganesh Chandra Ave, Chandni Chawk, Bowbazar, Kolkata, West Bengal 700013</p>

                    </div>
                    <div className="col-md-5 col-6 p-0 m-0 footerConv">
                        <p className="f-12 p-0 m-0 mb-1 footerHeading">Company</p>
                        <div className="row m-0 p-0">
                            <div className="col-md-6 col-12 pl-0 lightColorText">
                                <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/" className="lightColorText">Home</a></p>
                                <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/about" className="lightColorText">About us</a></p>
                                <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/works" className="lightColorText">How it works</a></p>
                                <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/press" className="lightColorText">Press</a></p>
                            </div>
                            <div className="col-md-6 p-0 col-12 lightColorText">
                                <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/blog" className="lightColorText">Blog</a></p>
                                <p className="f-12 p-0 m-0 mb-1"><a target="_blank" href="https://altiusinvestech.com/contact" className="lightColorText">Contact us</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 p-0 m-0 footerConv">
                        <p className="f-12 p-0 m-0 mb-1 footerHeading">Quick links</p>
                        <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/a-complete-walkthrough-of-our-trading-portal/" className="lightColorText">A Complete Walkthrough Of Our Trading Portal</a></p>
                        <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/capital-gain-on-sale-of-unlisted-shares/" className="lightColorText">Capital Gain on Sale of Unlisted Shares</a></p>
                        <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/how-to-transfer-unlisted-shares/" className="lightColorText">How to transfer unlisted shares?</a></p>
                        <p className="f-12 p-0 m-0 mb-1 lightColorText"><a target="_blank" href="https://altiusinvestech.com/blog/income-tax-on-unlisted-shares/" className="lightColorText">Income Tax on Unlisted Shares</a></p>

                    </div>
                </div>
                <p className="f-10 m-0 pb-1 text-center lightColorText">All rights reserved. Copyright © {new Date().getFullYear()} Altius Investech</p>
            </div>

        </div >
    )
}
