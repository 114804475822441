import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { downloadAllClientCSV, downloadAllOrderCSV, downloadCommissionCSV, getClientDetail, getClientLendboxOrderDetail, getClientLiquiloansOrderDetail, getClientOrderDetail, getCommissionLedgerDetail, getProData } from '../apis/ClientDataApi';
import { useLogin } from './CustomerLoginContext';

// create global login context
export const ClientDataContext = createContext();

export function ClientDataProvider({ children }) {

    const { token, logout } = useLogin()

    const [tableData, setTableData] = useState("")
    const [dupTableData, setDupTableData] = useState("")
    const [column, setColumn] = useState("")
    const [order, setOrder] = useState("")
    const [rowPerPage, setRowPerPage] = useState(10)
    const [currPage, setCurrPage] = useState(1)
    const [maxPage, setMaxPage] = useState(Math.ceil(dupTableData.length / rowPerPage))
    const [start, setStart] = useState(((currPage - 1) * rowPerPage))
    const [end, setEnd] = useState(((currPage - 1) * rowPerPage) + rowPerPage)
    const [commissionData, setCommissionData] = useState("")
    const [activeDetail, setActiveDetail] = useState("All Clients")

    useEffect(() => {
        try {
            setCurrPage(dupTableData.length > 0 ? 1 : 0)
            setRowPerPage(10)
        } catch (error) {
            console.error(error);
        }
    }, [dupTableData])

    useEffect(() => {
        try {
            setMaxPage(dupTableData.length > 0 ? Math.ceil(dupTableData.length / rowPerPage) : 0);
            setCurrPage(currPage >= maxPage ? maxPage : currPage)
            setStart(((currPage - 1) * rowPerPage))
            setEnd(parseInt(((currPage - 1) * rowPerPage)) + rowPerPage)
        } catch (error) {
            console.error(error);
        }
    }, [currPage, rowPerPage, maxPage])

    // login customer with credentials shared
    async function clientData() {
        try {
            let response = await getClientDetail(token);
            if (response && response.status === 200) {
                setTableData(response.data);
                setDupTableData(response.data);
                setMaxPage(Math.ceil(response.data.length / rowPerPage))
            } else if (response && response.status === 401) {
                logout();
            } else { }
        } catch (error) {
            console.error(error)
        }
    }

    // login customer with credentials shared
    async function clientOrderData() {
        try {
            let data = [];
            let response = await getClientLendboxOrderDetail(token);
            if (response && response.status === 200) {
                data = data.concat(response.data.map(element => ({ ...element, ...{ asset_class: "Lendbox" } })));
            } else if (response && response.status === 401) {
                logout();
            }
            response = await getClientLiquiloansOrderDetail(token);
            if (response && response.status === 200) {
                data = data.concat(response.data.map(element => ({ ...element, ...{ asset_class: "Liquiloan" } })));
            } else if (response && response.status === 401) {
                logout();
            }
            response = await getClientOrderDetail(token);
            if (response && response.status === 200) {
                let temp = []
                data = data.concat(response.data)
                data.forEach((element, key) => {
                    if ((configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase()) || (configs.isWealthManager.toLowerCase() !== configs.distributorTypes.wealth_manager.toLowerCase() && element && element.order_status_message && element.order_status_message.toLowerCase().includes("complete"))) {
                        temp.push(element)
                    }
                })
                setTableData(temp);
                setDupTableData(temp);
                setMaxPage(Math.ceil(temp.length / rowPerPage))
            } else if (response && response.status === 401) {
                logout();
            }

        } catch (error) {
            console.error(error)
        }

    }

    // get customer pro data
    async function getCustomerProData() {
        try {
            let response = await getProData(token);
            if (response && response.status === 200) {
                setTableData(response.data);
                setDupTableData(response.data);
                setMaxPage(Math.ceil(response.data.length / rowPerPage))
            } else if (response && response.status === 401) {
                logout();
            } else { }
        } catch (error) {
            console.error(error)
        }
    }

    // login customer with credentials shared
    async function commissionLedgerData() {
        try {
            let response = await getCommissionLedgerDetail(token);
            if (response && response.status === 200) {
                setCommissionData(response.data)
                setTableData(response.data.data);
                setDupTableData(response.data.data);
                setMaxPage(Math.ceil(response.data.data.length / rowPerPage))
            } else if (response && response.status === 401) {
                logout();
            }

        } catch (error) {
            console.error(error)
        }

    }

    // download csv from server
    async function downloadCSV(name) {
        try {
            document.getElementById("downloadCSVbtn").style.backgroundColor = "#ebebeb"
            document.getElementById("downloadCSVbtn").classList.remove("curPoint")
            let response = name && name.toLowerCase().includes("clients") ? await downloadAllClientCSV(token) :
                name && name.toLowerCase().includes("order") ? await downloadAllOrderCSV(token) :
                    name && name.toLowerCase().includes("commission") ? await downloadCommissionCSV(token) : null
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                if (name.toLowerCase().includes("commission")) {
                    a.download = response.data ? `${name}` : null; //File name Here
                    a.mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                } else {
                    a.download = response.data ? `${name}.csv` : null; //File name Here
                }
                a.click();
            } else if (response && response.status === 401) {
                logout();
            }
            else {
            }
            document.getElementById("downloadCSVbtn").style.backgroundColor = "#009FE3"
            document.getElementById("downloadCSVbtn").classList.add("curPoint")

        } catch (error) {

        }
    }


    // export global context
    return (
        <ClientDataContext.Provider
            value={{
                tableData,
                setTableData,
                dupTableData,
                setDupTableData,
                commissionData,
                setCommissionData,
                clientData,
                clientOrderData,
                getCustomerProData,
                commissionLedgerData,
                column,
                setColumn,
                order,
                setOrder,
                rowPerPage,
                setRowPerPage,
                currPage,
                setCurrPage,
                maxPage,
                setMaxPage,
                start,
                setStart,
                end,
                setEnd,
                downloadCSV,
                activeDetail,
                setActiveDetail
            }}
        >
            {children}
        </ClientDataContext.Provider>
    )

}

export function useClientDataContext() {
    return useContext(ClientDataContext)
}
