import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { animation, configs, formatCurrency, formatNumber } from "../../../assets/Config";
import '../../../assets/styles/Analytics.css';
import { useClientDataContext } from "../../../services/contexts/ClientDataContext";


export default function ProOrderPage() {

    const { tableData, dupTableData, setDupTableData, getCustomerProData, column, setColumn, order, setOrder, start, end } = useClientDataContext();

    useEffect(() => {
        try {
            (async () => {
                await getCustomerProData()
            })()
        } catch (error) {
            console.error(error)
        }
    }, [])

    // sort data
    useEffect(() => {
        try {
            let data = dupTableData
            if (order === "desc") {
                data = typeof dupTableData[0][column] === "string" ? [...dupTableData].sort((a, b) => a[column] > b[column] ? -1 : 1) : [...dupTableData].sort((a, b) => b[column] - a[column]);
            } else if (order === "asc") {
                data = typeof dupTableData[0][column] === "string" ? [...dupTableData].sort((a, b) => a[column] > b[column] ? 1 : -1) : [...dupTableData].sort((a, b) => a[column] - b[column]);
            }
            setDupTableData(data)
        } catch (error) {
            console.error(error)
        }

    }, [order, column])

    return (
        <div id="analytics">
            {dupTableData && dupTableData.length > 0 ?
                <div>
                    <div className="d-md-block d-none">
                        <div className="table-scroll">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Name</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "user_name" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("user_name")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "user_name" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("user_name")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Email</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "user_email" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("user_email")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "user_email" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("user_email")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Mobile</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "user_mobile" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("user_mobile")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "user_mobile" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("user_mobile")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Account Type</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "account_type" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("account_type")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "account_type" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("account_type")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Amount Paid</div>
                                                <div className="d-flex flex-column mx-2">
                                                    <i className={`fa fa-caret-up curPoint ${(column === "amount_paid_by_user" && order === "asc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("asc")
                                                        setColumn("amount_paid_by_user")
                                                    }}></i>
                                                    <i className={`fa fa-caret-down curPoint ${(column === "amount_paid_by_user" && order === "desc") ? "sort-select-arr" : "sort-arr"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setOrder("desc")
                                                        setColumn("amount_paid_by_user")
                                                    }}></i>
                                                </div>
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {dupTableData.map((element, key) => {
                                        if (key >= start && key < end) {
                                            return (
                                                <tr key={`clientOrderDetail` + key}>
                                                    <td className="rightBorder">{element.user_name}</td>
                                                    <td className="rightBorder">{element.user_email}</td>
                                                    <td className="rightBorder">{element.user_mobile}</td>
                                                    <td className="rightBorder">{element.account_type === configs.userTypes[1] ? "Pro Plus" : element.account_type === configs.userTypes[2] ? "Pro Lite" : null}</td>
                                                    <td className="rightBorder">{element.amount_paid_by_user}</td>
                                                </tr>
                                            )

                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-md-none">
                        {dupTableData.map((element, key) => {
                            if (key >= start && key < end) {
                                return (
                                    <div className="orderDetailCard" key={`clientOrderDetail` + key}>
                                        <div className="row m-0 mb-2 p-0">
                                            <div className="col-12 m-0 p-0 pr-3">
                                                <p className="m-0 p-0 f-12 font-weight-bold" ><b>{element.user_name}</b></p>
                                                <p className="m-0 p-0 f-12" >{element.user_email}</p>
                                                <p className="m-0 p-0 f-12" >+{element.user_mobile}</p>
                                            </div>
                                        </div>
                                        <div className="row m-0 mb-2 p-0">
                                            <div className="col-12 m-0 p-0 pr-3">
                                                <p className="m-0 p-0 f-12" ><b>Account Type: </b>{element.account_type === configs.userTypes[1] ? "Pro Plus" : element.account_type === configs.userTypes[2] ? "Pro Lite" : null}</p>
                                                <p className="m-0 p-0 f-12" ><b>Amount Paid: </b>{element.amount_paid_by_user}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                : <p className="m-0 mt-2 p-0">No data found</p>}
        </div>
    )
}