import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs, getCookie } from '../../assets/Config';
import { savePersonalDetail, signUpCustomerGoogleCredSaveMobile, signUpCustomerWithGoogleCred } from '../apis/CustomerSignUpApi';
import { useLogin } from './CustomerLoginContext';

// create global signup context
export const CustomerSignUpContext = createContext();

export function CustomerSignUpProvider({ children }) {

    const { saveLoginDetails, googleSignUpToken, setGoogleSignUpToken } = useLogin()
    const [signUpError, setSignUpError] = useState("");
    const [signUpToken, setSignUpToken] = useState("");

    // submit customer personal detail
    async function submitCustomerPersonalDetail(name, email, maobile, country_code, token, referralCode) {
        try {
            let response = await savePersonalDetail(name, email, maobile, country_code, token, referralCode, configs.referrer);
            if (response && response.status === 200) {
                setSignUpToken(response.data.token);
                setSignUpError("")
                return configs.errorMessages.success
            } else if (response && response.status === 401) {
            } else {
                setSignUpError(response.data.error)
                return response.data.error
            }
        } catch (error) {

        }
    }



    // customer oAuth save mobile
    async function registerUserGoogleCredentialsSaveMobile(mobile, country_code, referralCode) {
        try {
            let token = googleSignUpToken && googleSignUpToken.token ? googleSignUpToken.token : getCookie("USER_REGISTRATION_TOKEN")
            let response = await signUpCustomerGoogleCredSaveMobile(token, mobile, country_code, referralCode, configs.referrer);
            if (response && response.status === 200) {
                saveLoginDetails(response.data);
                return {
                    success: configs.errorMessages.success
                }
            } else if (response && response.status === 401) {
                return response.data.error
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <CustomerSignUpContext.Provider
            value={{
                signUpError,
                setSignUpError,
                submitCustomerPersonalDetail,
                registerUserGoogleCredentialsSaveMobile,
                signUpToken,
                setSignUpToken,
                googleSignUpToken,
                setGoogleSignUpToken
            }}
        >
            {children}
        </CustomerSignUpContext.Provider>
    );
}


export function useCustomerSignUpContext() {
    return useContext(CustomerSignUpContext)
}