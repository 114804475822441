import React, { useEffect, useState } from "react";
import BottomBarPage from "../../includes/dashboard/BottomBarPage";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import { useCustomerKYCContext } from "../../../services/contexts/CustomerKYCContext";
import { Link, useHistory } from "react-router-dom";
import { computeNet, configs, filterData, formatCurrency, formatNumber } from "../../../assets/Config";
import { useLogin } from "../../../services/contexts/CustomerLoginContext";
import '../../../assets/styles/dashboard.css';
import ToastPage from '../../includes/ToastPage'
import ClientOrderPage from "../clientData/ClientOrderPage";
import AllClientsPage from "./AllClientsPage";
import { useClientDataContext } from "../../../services/contexts/ClientDataContext";
import ProOrderPage from "./ProOrderPage";
import MailNew from '../../../assets/images/MailNew.svg';
import Twitter from '../../../assets/images/Twitter.svg';
import WhatsApp from '../../../assets/images/WhatsApp.svg';
import { useAURegister_KYCContext } from "../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext";
import AURegisterPage from "../assistedUser/registration/AURegisterPage";

export default function ClientsMainPage() {

    const { setShowMobBS, setCurrModule, setcurrMod, setShowSuccess, getDashboardData, setDashboardData, dashboardData, setProxyEmail, setImpersonating } = useLogin()

    const { tableData, dupTableData, setDupTableData, column, setColumn, order, setOrder, rowPerPage, setRowPerPage, currPage, setCurrPage, maxPage, setMaxPage, start, setStart, end, setEnd, downloadCSV, activeDetail, setActiveDetail } = useClientDataContext();

    const { setShowRegisterModal } = useAURegister_KYCContext();

    const [filterCol, setFilterCol] = useState(["name", "email"])

    const history = useHistory()

    useEffect(() => {
        setShowMobBS(false)
        setCurrModule(configs.modules.clients)
        setcurrMod(configs.modules.clients)
        setProxyEmail("")
        setImpersonating("")
    }, [])

    useEffect(() => {
        try {
            setColumn("")
            setOrder("")
        } catch (error) {
            console.error(error)
        }
    }, [activeDetail, filterCol])

    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                <div id='clientContainer' className="dash-main" >
                    <div className="row dashTitle align-items-center">
                        <div className="col-6">
                            {activeDetail}
                        </div>
                        {configs.isWealthManager.toLowerCase() === configs.distributorTypes.wealth_manager.toLowerCase() ?
                            <div className="col-6 m-0 p-0 d-flex justify-content-end">
                                <div id="downloadCSVbtn" className={`csvDownBtn curPoint f-12 mr-2`} onClick={(e) => {
                                    e.preventDefault();
                                    setShowRegisterModal(true)
                                }}>
                                    Add Client
                                </div>
                                <div id="downloadCSVbtn" className={`csvDownBtn curPoint f-12`} onClick={(e) => {
                                    e.preventDefault();
                                    downloadCSV(activeDetail)
                                }}>
                                    Export {activeDetail}
                                </div>
                            </div>
                            : null}
                    </div>
                    {activeDetail === "Pro Orders" ?
                        <div className="row m-0 p-0">
                            <div className="col-md-6 col-12 m-0 p-0">
                                <div className="dashCard highLightCard">
                                    <p className="m-0 p-0 font-weight-bold">Benefits of PRO PLAN for your client:</p>
                                    <p className="m-0 p-0">
                                        <ol className="mb-0">
                                            <li>Discount upto 2% on purchases made by your client</li>
                                            <li>Client gets to add family accounts – Increasing your overall AUM</li>
                                            <li>Dedicated support on Onboarding and Portfolio Review by ALTIUS team for your client</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 m-0 p-0 pt-md-0 pt-3">
                                <div className="dashCard highLightCard">
                                    <p className="m-0 p-0 font-weight-bold">Get your clients to sign up for ALTIUS PRO and earn 20% of the total fees paid by your client</p>
                                    <div className="mt-4">
                                        <p className="m-0 p-0 font-weight-bold">Recommend Pro Plan to your clients</p>
                                        <div className='row m-0 mt-1 p-0 align-items-center'>
                                            <div className='col-4 text-center m-0 p-0 curPoint' onClick={(e) => {
                                                e.preventDefault();
                                                window.open(`mailto:?subject=Pre-IPO investment invite&body=${configs.distributorProMessage}`, "_blank");
                                            }}>
                                                <img className='leftBar-icon' src={MailNew} alt="altius investech mail" />
                                            </div>
                                            <div className='col-4 text-center m-0 p-0 curPoint' onClick={(e) => {
                                                e.preventDefault();
                                                window.open(`https://twitter.com/intent/tweet?text=${configs.distributorProMessage}`, "_blank");
                                            }}>
                                                <img className='leftBar-icon' src={Twitter} alt="altius investech twitter" />
                                            </div>
                                            <div className='col-4 text-center m-0 p-0 curPoint' onClick={(e) => {
                                                e.preventDefault();
                                                window.open(`https://wa.me?text=${configs.distributorProMessage}`, "_blank");
                                            }}>
                                                <img className='leftBar-icon' src={WhatsApp} alt="altius investech WhatsApp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {/* dashboard table selector--------------------------------------*/}

                    {/* dashboard table--------------------------------------*/}
                    <div className="row mx-2 my-4">
                        <div className="col-12 m-0 p-0">
                            <div className="dashCard">
                                <div className="row m-0 align-items-center">
                                    <div className="row col-md-9 col-12 m-0 p-0">
                                        <div className={`curPoint ${activeDetail === "All Clients" ? "analyitcsSelector-active" : "analyitcsSelector"}`} onClick={(e) => {
                                            e.preventDefault()
                                            setFilterCol(["name", "email"])
                                            setActiveDetail("All Clients")
                                        }}>
                                            <p className="m-0 p-0">All Clients</p>
                                        </div>
                                        <div className={`curPoint ${activeDetail === "Client Orders" ? "analyitcsSelector-active" : "analyitcsSelector"}`} onClick={(e) => {
                                            e.preventDefault()
                                            setFilterCol(["user_name", "user_email"])
                                            setActiveDetail("Client Orders")
                                        }}>
                                            <p className="m-0 p-0">Client Orders</p>
                                        </div>
                                        <div className={`curPoint ${activeDetail === "Pro Orders" ? "analyitcsSelector-active" : "analyitcsSelector"}`} onClick={(e) => {
                                            e.preventDefault()
                                            setFilterCol(["user_name", "user_email"])
                                            setActiveDetail("Pro Orders")
                                        }}>
                                            <p className="m-0 p-0">Pro Orders</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 m-0 p-0 txt-right">
                                        <input type="search" className="form-control f-12" name="search" placeholder="Search by Name, Email" onKeyUp={(e) => {
                                            setColumn("")
                                            setOrder("")
                                            let data = filterData(e.target.value, tableData, filterCol)
                                            setDupTableData(data)
                                        }} />
                                    </div>
                                </div>
                                {activeDetail === "Client Orders" ? <ClientOrderPage />
                                    : activeDetail === "Pro Orders" ? <ProOrderPage /> : <AllClientsPage />}
                            </div>
                        </div>
                        <div className="col-12 m-0 p-0">
                            <div className="row m-3 p-0">
                                <div className="col-2  d-flex m-0 p-0 align-items-center justify-content-start">
                                    <p className="m-0 f-0 f-12">{start >= 0 && dupTableData.length > 0 ? start + 1 : 0} - {end > dupTableData.length ? dupTableData.length : end} of {dupTableData.length}</p>
                                </div>
                                <div className="col-10 d-flex m-0 p-0 align-items-center justify-content-end">
                                    <div className="mr-3 d-flex align-items-center">
                                        <p className="m-0 pr-1 f-0 f-12">Rows per page:</p>
                                        <select className="f-12 noBorder" value={rowPerPage} onChange={(e) => {
                                            setRowPerPage(parseInt(e.target.value))
                                        }}>
                                            <option className="f-12" value={10}>10</option>
                                            <option className="f-12" value={20}>20</option>
                                            <option className="f-12" value={50}>50</option>
                                        </select>
                                    </div>
                                    <i className="fa fa-caret-square-o-left f-18" onClick={(e) => {
                                        e.preventDefault()
                                        setCurrPage(currPage > 1 ? currPage - 1 : 1)
                                    }}></i>
                                    <p className="m-0 mx-2 f-0 f-14">{currPage}/{maxPage}</p>
                                    <i className="fa fa-caret-square-o-right f-18" onClick={(e) => {
                                        e.preventDefault()
                                        setCurrPage(currPage < maxPage ? currPage + 1 : maxPage)
                                    }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomBarPage />
            <ToastPage />
            <AURegisterPage />
            {/* //     </div> */}
        </div >

    )
}