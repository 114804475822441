import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/KYC.css';
import { saveCustomerKYCDematDetail } from '../../../services/apis/CustomerKYCApi';
import { useCustomerKYCContext } from '../../../services/contexts/CustomerKYCContext';
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import KYCMobileProgressBarPage from './KYCMobileProgressBarPage';

export default function KYCDematDetailPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, nextPage, kycDetails, setKycDetails, editPage, getKYCSavedDetailContext } = useCustomerKYCContext()
    const { token, logout, setLoginError } = useLogin();

    const [dp_id, setDp_id] = useState(kycDetails.demat_details.dp_id);
    const [client_id, setClient_id] = useState(kycDetails.demat_details.client_id);
    const [error, setError] = useState("");

    useEffect(() => {
        editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
    }, [])

    // validate dp id
    function validateDpId(dpId) {
        try {
            const re = /\b[a-zA-Z0-9]{8}\b/;
            let res = re.test(dpId);
            if (res !== true) setError("DP Id must be 8 digit Alpha Numeric.");
            return res;
        } catch (error) {

        }
    }

    // validate client id
    function validateClientId(clientId) {
        try {
            const re = /^\d{8}$/;
            let res = re.test(clientId);
            if (res !== true) setError("Client Id must be 8 digit Numeric.");
            return res;
        } catch (error) {

        }
    }

    // save customer demat details
    async function saveKYCDematDetail(e) {
        try {
            e.preventDefault();
            if (dp_id !== kycDetails.demat_details.dp_id || client_id !== kycDetails.demat_details.dp_id) {
                const isClientIdValid = validateClientId(client_id);
                const isDpIdValid = validateDpId(dp_id);
                if (isClientIdValid && isDpIdValid) {
                    let response = await saveCustomerKYCDematDetail(token, dp_id, client_id)
                    if (response && response.status === 200) {
                        kycDetails["demat_details"]["dp_id"] = dp_id
                        kycDetails["demat_details"]["client_id"] = client_id
                        getKYCSavedDetailContext()
                        setKycDetails(kycDetails)
                        editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
                    } else if (response && response.status === 401) {
                        logout();
                        //setLoginError(configs.unauthorizedErrorMessage);
                    } else {
                        setError(response.data.error)
                    }
                }
            } else {
                editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
            }
        } catch (error) {

        }
    }

    // customer kyc demat detail ui code
    return (<div id="kycDematDetailContainer" className="main-kycPanel">
        <KYCMobileProgressBarPage />
        <p className="h5 text-theme mt-2">Demat Details</p>
        <p className="text-custom-grey mt-4 mb-1">Your trading account information</p>
        <form action="">
            <div className="form-group">
                <label className="f-12" htmlFor="user_DP_Id">DP Id</label>
                <input type="text" name="user_DP_Id" id="user_DP_Id" className="form-control" maxLength={8} pattern="[A-Z0-9]{8}" required defaultValue={kycDetails.demat_details.dp_id} onChange={(e) => {
                    setDp_id(e.target.value)
                }} />
                <p className="f-9">The Depository ID is the identification number of the depository participant member i.e. the entity where your Demat account is maintained.</p>
            </div>
            <div className="form-group">
                <label className="f-12" htmlFor="user_client_Id">Client Id</label>
                <input type="text" name="user_client_Id" id="user_client_Id" className="form-control" maxLength={8} pattern="[A-Z0-9]{8}" required defaultValue={kycDetails.demat_details.client_id} onChange={(e) => {
                    setClient_id(e.target.value)
                }} />
                <p className="f-9">A client ID is a unique eight-digit number generated by the depository participants to easily identify their clients. This number is generated by using the in-house formula of your chosen brokerage house.</p>
            </div>
            <div className="form-check p-0 f-12">
                <p>Need Help? <a href="https://altiusinvestech.com/blog/where-to-find-your-demat-account-number/" target="_blank" className="custom-link">Click Here</a></p>
            </div>
            <div className="form-check p-0">
                <p className="text-danger" id="loginFormError">{error}</p>
            </div>
            <button className="btn btn-primary rounded px-4" onClick={(e) => {
                saveKYCDematDetail(e)
            }}>{editPage ? "Update" : "Next"}</button>
        </form>
    </div>)
}