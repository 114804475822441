import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { configs, countries } from '../../../assets/Config';
import '../../../assets/styles/KYC.css';
import { saveCustomerKYCBasicDetail, saveCustomerMobile } from '../../../services/apis/CustomerKYCApi';
import { useCustomerKYCContext } from '../../../services/contexts/CustomerKYCContext';
import { useLogin } from '../../../services/contexts/CustomerLoginContext';
import VerifyOTPMobPage from '../../includes/VerifyOTPMobPage';
import KYCMobileProgressBarPage from './KYCMobileProgressBarPage';
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'libphonenumber-js';

export default function KYCBasicDetailPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, nextPage, kycDetails, setKycDetails, editPage, otpModal, setOtpModal, getKYCSavedDetailContext, mobOtpToken, setMobOtpToken } = useCustomerKYCContext();
    const { token, logout, setLoginError } = useLogin();

    const [name, setName] = useState(kycDetails.basic_details.name);
    const [address, setAddress] = useState(kycDetails.basic_details.address);
    const [nationality, setNationality] = useState(kycDetails.basic_details.nationality);
    const [pan, setPan] = useState(kycDetails.basic_details.pan_number);
    const [country, setCountry] = useState(kycDetails.basic_details.mobile ? kycDetails.basic_details.mobile.substring(0, (kycDetails.basic_details.mobile.length - 10)) : "")
    const [mobile, setMobile] = useState(kycDetails.basic_details.mobile ? kycDetails.basic_details.mobile.substring(kycDetails.basic_details.mobile.length - 10) : "")
    const [error, setError] = useState("");
    const [mobError, setMobError] = useState("");
    const [nameChange, setNameChange] = useState(false);

    // validate name
    function validateName(name) {
        if (name && name.trim() !== "") return true;
        else {
            setError("Please enter a valid name.");
            return false;
        }
    }

    // validate address
    function validateAddress(address) {
        if (address && address.trim() !== "") return true;
        else {
            setError("Please enter a valid address.");
            return false;
        }
    }

    // validate pan
    function validatePan(pan) {
        try {
            const re = /\b[A-Z0-9]{10}\b/;
            let res = re.test(pan);
            if (res !== true) setError("Pan Number must be 10 digit alpha-numeric.");

            return res;
        } catch (error) {

        }
    }

    // vlaidate mobile
    function validateMobile(mobile) {
        try {
            // const re = /[0-9]{10}/g;
            // let res = re.test(mobile);
            // if (res !== true) setError("Mobile number must be 10 digit numeric.");

            let res = isValidPhoneNumber(`+${country}${mobile}`, `+${country}`)
            if (res !== true) setMobError(configs.errorMessages.signUp.mobile);
            return res;
        } catch (error) {

        }
    }

    // get countryCode
    const getCountryCode = (e, value) => {

        if (value && value.phone) {
            setCountry(value.phone)
        }
    }

    // verify mobile number
    async function verifyMobile() {
        if (!country) {
            setError(configs.errorMessages.signUp.country)
        }
        else if (validateMobile(mobile)) {
            setError("")
            let response = await saveCustomerMobile(token, mobile, country)
            if (response && response.status === 200) {
                setOtpModal(true)
                setMobOtpToken(response.data.token)
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            } else {
                setMobError(response.data.error)
            }

        }
    }

    // save customer basic details
    async function saveKYCBasicDetail(e) {
        try {
            e.preventDefault();
            document.getElementById("basicBtn").classList.toggle("d-none")
            document.getElementById("basicBtnLoader").classList.toggle("d-none")

            if (!name.trim()) {
                setError(configs.errorMessages.kycBasic.name)
            } else if (address && !address.trim()) {
                setError(configs.errorMessages.kycBasic.address)
            } else if (pan && !pan.trim() || !configs.panRegex.test(pan)) {
                setError(configs.errorMessages.kycBasic.pan)
            }
            else if (name !== kycDetails.basic_details.name || address !== kycDetails.basic_details.address || pan !== kycDetails.basic_details.pan_number || nationality !== kycDetails.basic_details.nationality) {
                const isAddressValid = validateAddress(address);
                const isPanValid = validatePan(pan);
                const isNameValid = validateName(name);
                if (isPanValid && isNameValid && isAddressValid && nationality) {
                    let response = await saveCustomerKYCBasicDetail(token, name, address, pan, nationality)
                    if (response && response.status === 200) {
                        if (response && response.data.data.pan_verification_status.toLowerCase() === "verified" && response.data.data.updated_name != name) {
                            kycDetails["basic_details"]["name"] = response.data.data.updated_name
                            setName(response.data.data.updated_name)
                            setNameChange(true);
                        } else {
                            kycDetails["basic_details"]["name"] = name
                        }
                        kycDetails["basic_details"]["address"] = address
                        kycDetails["basic_details"]["pan_number"] = pan
                        setKycDetails(kycDetails)
                        await getKYCSavedDetailContext(true)
                        setTimeout(() => {
                            document.getElementById("basicBtn").classList.toggle("d-none")
                            document.getElementById("basicBtnLoader").classList.toggle("d-none")

                            editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
                        }, response.data.data.pan_verification_status.toLowerCase() === "verified" ? 3000 : 0)
                    } else if (response && response.status === 401) {
                        logout();
                        //setLoginError(configs.unauthorizedErrorMessage);
                    } else {
                        setError(response.data.error)
                    }
                }
            } else {
                editPage ? custKYCSwitchPage(5) : custKYCSwitchPage(nextPage)
            }
        } catch (error) {
            console.error(error)
        }

    }

    // get country to display
    function getCountry() {
        let number;
        countries.map(((element, key) => {
            if (element.phone == country) {
                number = element
            }
        }))

        return number
    }

    // customer kyc basic detail ui code
    return (<div id="kycBasicDetailContainer" className="main-kycPanel">
        <KYCMobileProgressBarPage />
        <p className="h5 text-theme mt-2">Your Basic Profile</p>
        <p className="text-custom-grey mt-4 mb-1">Contact Information</p>
        <form action="">
            <div className="form-group">
                <label className="f-12" htmlFor="user_email">Email ID</label>
                <input type="email" name="user_email" id="user_email" className="form-control" required disabled={true} defaultValue={kycDetails.basic_details.email} />
                <p className="text-success mt-1 f-12"><i className="far fa-check-circle mr-1"></i>Verified</p>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-lg-4 col-6">
                        <label className="f-12" htmlFor="user_mobile">Country Code</label>
                        <Autocomplete
                            id="country-select-demo"
                            options={countries}
                            className="f-10"
                            placeholder="Choose a country"
                            autoHighlight
                            onChange={getCountryCode}
                            value={country ? getCountry() : countries[103]}
                            getOptionLabel={(option) => `(${option.code}) +${option.phone}`}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${props.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${props.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    &nbsp;({props.code}) +{props.phone}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Choose a country"
                                    {...params}
                                    className="f-12"
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-8 col-6">
                        <label className="f-12" htmlFor="user_mobile">Mobile Number</label>
                        <input type="text" name="user_mobile" id="user_mobile" className="form-control mb-1" required value={mobile ? mobile : ""} onChange={(e) => {
                            setMobile(e.target.value)
                        }} />
                    </div>
                </div>
                {(kycDetails.basic_details.mobile_verified === 0 || (kycDetails.basic_details.mobile && kycDetails.basic_details.mobile.substring(kycDetails.basic_details.mobile.length - 10) !== mobile) || (kycDetails.basic_details.mobile && kycDetails.basic_details.mobile.substring(0, (kycDetails.basic_details.mobile.length - 10)) !== country)) ?
                    <span className="text-theme"><a href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            verifyMobile()
                        }} className="custom-link f-12">Verify with OTP</a></span>
                    :
                    <p className="text-success mt-1 f-12"><i className="far fa-check-circle mr-1"></i>Verified</p>
                }
                <div className="form-check p-0">
                    <p className="text-danger" id="mobError">{mobError}</p>
                </div>
            </div>
        </form>
        <p className="text-custom-grey mt-4 mb-1">Basic Profile</p>
        <form action="" className="mb-4">
            <div className="form-group">
                <label className="f-12" htmlFor="user_name">Full Name (As on Pan Card)</label>
                <input type="text" name="user_name" id="user_name" className="form-control" required defaultValue={kycDetails.basic_details.name} onChange={(e) => {
                    setName(e.target.value)
                }} />
                {nameChange ? <p className="text-success mt-1 f-12">Your name has been updated as captured from PAN Card.</p> : null}
            </div>
            <div className="form-group">
                <label className="f-12" htmlFor="user_PAN">PAN Number</label>
                <input type="text" name="user_PAN" id="user_PAN" className="form-control" maxLength={10} pattern="[A-Z0-9]{8}" required defaultValue={kycDetails.basic_details.pan_number} onKeyUp={(e) => {
                    e.preventDefault()
                    e.target.value = e.target.value.toUpperCase()
                }} onChange={(e) => {
                    setPan(e.target.value.toUpperCase())
                }} />
                {(kycDetails.basic_details.pan_status && kycDetails.basic_details.pan_status.toLowerCase() === "verified") && (kycDetails.basic_details.pan_number && pan && kycDetails.basic_details.pan_number === pan) ?
                    <p className="text-success mt-1 f-12"><i className="far fa-check-circle mr-1"></i>Verified</p> : null}
            </div>
            <div className="form-group">
                <label className="f-12" htmlFor="user_address">Address</label>
                <textarea name="user_address" id="user_address" className="w-100 form-control address-textarea" rows="5" required defaultValue={kycDetails.basic_details.address} onChange={(e) => {
                    setAddress(e.target.value)
                }} />
            </div>
            <div className="form-group">
                <label className="f-12" htmlFor="user_address">Nationality</label>
                <select name="user_nationality" id=" user_nationality" className="form-control" rows="5" defaultValue={kycDetails.basic_details.nationality ? kycDetails.basic_details.nationality : "default"} required onChange={(e) => {
                    setNationality(e.target.value)
                }}>
                    <option disabled value="default"> Select an option </option>
                    {configs.nationalityOption.map((element) => {
                        return <option key={element} value={element}>{element}</option>
                    })}</select>
            </div>
            <div className="form-check p-0">
                <p className="text-danger" id="loginFormError">{error}</p>
            </div>
            <button id="basicBtn" className="btn btn-primary rounded px-4" onClick={(e) => { saveKYCBasicDetail(e) }}>{editPage ? "Update" : "Next"}</button>
            <div id="basicBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
        </form>
        <VerifyOTPMobPage />
    </div>)
}