import React from 'react';
import '../../assets/styles/PHWrapper.css';

export default function PlaceHolderWrapper() {
    return (

        <div id="phWrapper" className="wrapper d-none">
            <div className="wrapper-cell">
                <div className="image"></div>
                <div className="text">
                    <div className="text-line"> </div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                </div>
            </div>
            <div className="wrapper-cell">
                <div className="image"></div>
                <div className="text">
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line">   </div>
                </div>
            </div>
            <div className="wrapper-cell">
                <div className="image"></div>
                <div className="text">
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                </div>
            </div>
            <div className="wrapper-cell">
                <div className="image"></div>
                <div className="text">
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                </div>
            </div>
            <div className="wrapper-cell">
                <div className="image"></div>
                <div className="text">
                    <div className="text-line"> </div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                </div>
            </div>
            <div className="wrapper-cell">
                <div className="image"></div>
                <div className="text">
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line"></div>
                    <div className="text-line">   </div>
                </div>
            </div>
        </div>
    )
}
