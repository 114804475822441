import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';

export default function AUVerifyOrderPopupPage(cancelFlag) {

    const { verifyOrderPopup, setVerifyOrderPopup, assetOrderDetail, orderType, cancelBuyOrder, setShowSuccess } = useAUAssetMgmtContext()
    const { proxyEmail } = useLogin()

    const history = useHistory()
    const [error, setError] = useState("");


    // close last slab modal
    const handleClose = () => {
        setVerifyOrderPopup(false)
        // if (sharesCompanyDetailNew && sharesCompanyDetailNew.basic_data && sharesCompanyDetailNew.basic_data.disabletrading !== 1) {
        //     history.push('/orderBook')
        // }
    }

    // cancel order
    async function cancelOrder() {
        try {
            let result = await cancelBuyOrder(assetOrderDetail.order_id)
            if (result && result.success) {
                setError("")
                handleClose()
            } else {
                setError(result)
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Modal open={verifyOrderPopup} onClose={handleClose}>
            <div className="modal-fade modalPosition" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title font-weight-bold subheading2 m-0 p-0" id="exampleModalLabel"> {!cancelFlag.flag ? "Request Received" : "Cancel Order"}</p>
                            <button type="button" className="close" onClick={(e) => {
                                e.preventDefault()
                                handleClose()
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {assetOrderDetail.order_id && !cancelFlag.flag ?
                            <div className="modal-body">
                                <div className="alert alert-success text-center" role="alert">
                                    <div className="subheading2 font-weight-bold">
                                        <i className="far fa-check-circle mr-2"></i>Success
                                    </div>
                                    Your request to {orderType} asset of <b>{assetOrderDetail.company_name}</b> has been recorded under Order ID <b>{assetOrderDetail ? assetOrderDetail.order_id : null}.</b>
                                </div>
                                Our team will approve/modify the same within 12hours. For any queries please reach out at : <a href="tel:8240614850">8240614850</a> or write us at&nbsp;<a href="mailto:support@altiusinvestech.com">support@altiusinvestech.com.</a>
                            </div>
                            : !cancelFlag.flag ?
                                <div className="modal-body">
                                    Your query has been recorded. Our team will be in touch shortly. You can contact <a href="tel:+918240614850">+918240614850</a> for any urgencies.
                                </div>
                                :
                                <div className="modal-body">
                                    Are you sure you want to cancel order {assetOrderDetail.order_id} of {assetOrderDetail.company_name}?
                                    <p className='m-0 mt-3 p-0 text-danger'>{error}</p>
                                </div>
                        }
                        <div className="modal-footer">
                            <button type="button" className={`btn ${!cancelFlag.flag ? "btn-primary" : "btn-danger"}`} onClick={(e) => {
                                e.preventDefault()
                                if (cancelFlag.flag) {
                                    cancelOrder()
                                } else {
                                    handleClose()
                                }
                            }}>{!cancelFlag.flag ? "Done" : "Cancel Order"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}